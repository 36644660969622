import { jwtDecode } from 'jwt-decode'
import { EconomyData } from 'pages/Home/models/EconomyData'
import { EconomyApi } from 'pages/Home/services/EconomyApi'
import { PaymentApi } from 'pages/Home/services/PaymentApi'
import { TOKEN_KEY } from 'pages/SignIn/services/LoginApi'
import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Grid } from 'shared/designSystem/components/Grid'
import { Loading } from 'shared/designSystem/components/Loading'
import useFilters from 'shared/hook/useFilters'
import { Header } from 'shared/layout/Header'
import { Sidebar } from 'shared/layout/Sidebar'
import { theme } from 'shared/theme'
import { useConsumitionStore } from '../../shared/store/consumition'
import ConsumeGraph from './components/ConsumeGraph'
import EconomyGraph from './components/EconomyGraph'
import { ErrorCard } from './components/Error/ErrorCard'
import { HomeError } from './components/Error/HomeError'
import { Footer } from './components/Footer'
import PaymentOverview from './components/PaymentOverview'
import { TutorialOverview } from './components/TutorialOverview'
import WeekHighlights from './components/WeekHighlights'
import { ConsumeApi } from './services/ConsumeApi'
import { ContentContainer, ContentWrapper, MainContainer } from './styles'
import { useReportStore } from 'shared/store/reports'

const Home = () => {
  const { filters } = useFilters('filtesAll')
  const {
    actions: { setFilteredConsumes, setFilteredEconomy, setFilteredPayment }
  } = useConsumitionStore()

  const {
    actions: { setFilterDefault }
  } = useReportStore()

  const [loading, setLoading] = useState(true)
  const [errorPage, setErrorPage] = useState({
    page: false,
    consumesAll: false,
    globalError: false,
    economy: false,
    payments: false
  })
  const [economy, setEconomy] = useState<any>()
  const [payments, setPayments] = useState<any>()

  const handleComponentError = () => {
    setErrorPage({ ...errorPage, page: true })
  }

  const handleRetryButterCMS = async () => {
    setErrorPage({ ...errorPage, page: false })
  }

  const fetchDataConsumes = async () => {
    try {
      const { id } = jwtDecode(sessionStorage.getItem(TOKEN_KEY)!) as { id: string }
      const groupsFromApi = await ConsumeApi.getConsumes({ ...filters, userId: id })
      setFilteredConsumes(Object.keys(groupsFromApi).length === 0 ? [] : groupsFromApi.result)
      setErrorPage({ ...errorPage, consumesAll: false, globalError: false })
    } catch (error: any) {
      setErrorPage({ ...errorPage, consumesAll: true, globalError: error?.response?.status !== 400 })
    }
  }

  const fetchDataEconomy = async () => {
    try {
      const { id } = jwtDecode(sessionStorage.getItem(TOKEN_KEY)!) as { id: string }
      const data: EconomyData = await EconomyApi.getData({ ...filters, userId: id })
      setEconomy(data)
      setFilteredEconomy(data)
      setErrorPage({ ...errorPage, economy: false })
    } catch (error) {
      setErrorPage({ ...errorPage, economy: true })
    }
  }

  const fetchDataPayments = async () => {
    try {
      const { id } = jwtDecode(sessionStorage.getItem(TOKEN_KEY)!) as { id: string }
      const data: any = await PaymentApi.getData({ ...filters, userId: id })
      setPayments(data)
      setFilteredPayment(data)
      setErrorPage({ ...errorPage, payments: false })
    } catch (error: any) {
      setErrorPage({ ...errorPage, payments: error.response?.status !== 400 })
      setPayments([])
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      await Promise.all([fetchDataConsumes(), fetchDataEconomy(), fetchDataPayments()])
    } catch (error) {
      console.log('Erro ao obter parâmetros iniciais', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setFilterDefault(false)
    fetchData()
  }, [])

  return (
    <MainContainer bgcolor={theme.designSystem.base[100]}>
      <Sidebar data-testid="sidebar" />
      <ContentWrapper>
        <Header data-testid="header" fetchData={fetchData} />
        <ContentContainer mt={0.3} data-testid="content-container">
          {loading && (
            <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
              <Grid size={12} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading />
                </Box>
              </Grid>
            </Grid>
          )}

          {errorPage.globalError && (
            <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
              <Grid size={12} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <HomeError
                    message="Um problema aconteceu e não foi possível carregar dados da página inicial. Por favor, tente novamente."
                    onRetry={fetchData}
                  />
                </Box>
              </Grid>
            </Grid>
          )}

          {!loading && !errorPage.globalError && (
            <>
              <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
                <Grid size={{ xs: 12, md: 'grow' }} display="flex" flexDirection="column">
                  <Grid container spacing={0.5}>
                    <Grid size={{ xs: 12, sm: 'grow' }} minHeight={384} sx={{ flexGrow: 1 }}>
                      <ConsumeGraph
                        fetchDataConsumes={fetchDataConsumes}
                        error={errorPage.consumesAll}
                      />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 'grow' }} sx={{ flexGrow: 1 }}>
                      <EconomyGraph
                        fetchDataEconomy={fetchDataEconomy}
                        economy={economy}
                        error={errorPage.economy}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0.5} mt={0.5}>
                    <Grid size="grow">
                      <PaymentOverview
                        fetchDataPayments={fetchDataPayments}
                        dataResult={payments}
                        error={errorPage.payments}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid size={{ xs: 12, md: 4 }} display="flex" flexDirection="column">
                  {errorPage.page ? (
                    <Box
                      sx={{
                        bgcolor: 'white',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <ErrorCard
                        message="de conteúdo e ajuda"
                        height={2}
                        onRetry={handleRetryButterCMS}
                      />
                    </Box>
                  ) : (
                    <>
                      <Grid container spacing={0.5}>
                        <Grid size="grow">
                          <WeekHighlights onError={handleComponentError} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={0.5} pt={0.5}>
                        <Grid size="grow">
                          <TutorialOverview onError={handleComponentError} />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid width={'100vw'} mt={0.5}>
                <Footer />
              </Grid>
            </>
          )}
        </ContentContainer>
      </ContentWrapper>
    </MainContainer>
  )
}

export default Home
