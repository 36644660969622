import { UserApi } from 'pages/FirstAccess/services/User'
import { useEffect, useRef, useState } from 'react'
import { ErrorHandler } from 'shared/utils/ErrorHandler'
import {
  CountDownContainer,
  Description,
  ErrorMessage,
  FormboxContainer,
  InputBase,
  InputContainer,
  MainBox,
  StyledButton,
  StyledCountDown,
  Title,
  TypographyBox
} from './styles'

interface Props {
  role?: string
  email: string
  onSuccess: (isValid: boolean) => void
}
export const TokenBox = ({ role, email, onSuccess }: Props) => {
  const [token, setToken] = useState<string[]>(Array(6).fill(''))
  const [error, setError] = useState<string | null>(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [countdown, setCountdown] = useState(60)
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const handleInputChange = (value: string, index: number) => {
    if (value.length > 1) return

    const newToken = [...token]
    newToken[index] = value
    setToken(newToken)
    setError(null)

    if (value !== '' && index < token.length - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    const target = e.target as HTMLInputElement

    if (e.key === 'Backspace' && target.value === '' && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  const handleFailToken = (error: string) => {
    error == 'Expired Token.'
      ? setError('Token expirado, por favor entre em contato com nosso atendimento')
      : setError('Token inválido, por favor tente novamente')

    if (!isButtonDisabled) {
      setIsButtonDisabled(true)
      setCountdown(60)
    }
  }

  const validateToken = async () => {
    const code = token.join('')

    if (code.length < 6) {
      setError('O código informado não é válido')
      return
    }

    try {
      await UserApi.validateSecurityCode({ email, code })
      onSuccess(true)
    } catch (error) {
      const errorMessage = ErrorHandler.getErrorMessage(error)
      handleFailToken(errorMessage)
    }
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined

    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1)
      }, 1000)
    }
    if (countdown === 0) setIsButtonDisabled(false)

    return () => {
      if (timer) clearInterval(timer)
    }
  }, [isButtonDisabled, countdown])

  return (
    <MainBox role={role}>
      <FormboxContainer role="token-box">
        <TypographyBox>
          <Title role="title">Olá, seja bem vindo!</Title>
          <Description role="message">
            Para iniciar a configuração da sua conta no Genial Energia, insira o código de segurança que
            foi enviado para o e-mail de cadastro.
          </Description>
          <InputContainer role="inputs-container">
            {token.map((value, index) => (
              <InputBase
                role="input"
                key={index}
                value={value}
                onChange={(e) => handleInputChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                inputRef={(ref) => (inputRefs.current[index] = ref)}
                error={!!error}
              />
            ))}
          </InputContainer>
          {error && <ErrorMessage role="error-message">{error}</ErrorMessage>}
        </TypographyBox>
        <CountDownContainer>
          <StyledButton role="button" onClick={validateToken} disabled={isButtonDisabled}>
            {isButtonDisabled ? `Reenviar código` : 'Enviar código'}
          </StyledButton>
          {isButtonDisabled && <StyledCountDown role="countdown">{countdown}</StyledCountDown>}
        </CountDownContainer>
      </FormboxContainer>
    </MainBox>
  )
}
