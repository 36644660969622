import { Box } from 'shared/designSystem/components/Box'
import MultiLevelSelectProduct from './components/MultiLevelSelectProduct'
import MultiLevelSelectYear from './components/MultiLevelSelectYear'
import type { TypePropsPageHeader } from '../../interface'

export const ContainerFilters = ({ fetchData }: TypePropsPageHeader) => {
  return (
    <Box display="flex" alignItems="center" sx={{ gap: 2 }}>
      <MultiLevelSelectProduct fetchData={fetchData} />
      {location.pathname === '/reports' && <MultiLevelSelectYear fetchData={fetchData} />}
    </Box>
  )
}
