import { memo, useRef, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Header } from './Header'
import { dataReports } from 'shared/mocks/downloadPdf.json'

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphProps } from 'pages/Reports/types/GraphProps'
import { Bar } from 'react-chartjs-2'
import { theme } from 'shared/theme'
import { getDesaturatedColor } from 'shared/utils/getDesaturatedColor'
import { getMonthAbbreviations, getMonthFirstLetter } from 'shared/utils/getMonths'
import { Legend } from '../Legend'
import { CustomTooltip } from '../Tooltip'
import { EmptyData } from './EmptyData'

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip)

const ConsumeGraph = ({ variant }: GraphProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
  }>({ x: 0, y: 0, visible: false })

  const isModalVariant = variant === 'modal'

  const barraA = dataReports.dataConsumer.dataGraphic.consumedVolume
  const barraB = dataReports.dataConsumer.dataGraphic.contractedVolume

  const hasData = barraA.length > 0 && barraB.length > 0

  const data = {
    labels: isModalVariant ? getMonthAbbreviations() : getMonthFirstLetter(),
    datasets: [
      {
        label: 'Bar Dataset',
        data: barraA,
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? '#ccedfd'
            : getDesaturatedColor('#ccedfd'),
        barPercentage: 1,
        barThickness: 22,
        categoryPercentage: 1
      },
      {
        label: 'Bar Dataset',
        data: barraB,
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? '#37a9fb'
            : getDesaturatedColor('#37a9fb'),
        barPercentage: 1,
        barThickness: 22,
        categoryPercentage: 1
      }
    ]
  }

  const prevTooltipData = useRef(tooltipData)

  const options: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        enabled: false,
        external: (context) => {
          const { chart, tooltip } = context

          if (tooltip.opacity === 0 && tooltipData.visible) {
            setTooltipData((prev) => ({ ...prev, visible: false }))
          }

          const { offsetLeft, offsetTop } = chart.canvas

          if (
            prevTooltipData.current.x !== offsetLeft + tooltip.caretX ||
            prevTooltipData.current.y !== offsetTop + tooltip.caretY ||
            !tooltipData.visible
          ) {
            const newTooltipData = {
              x: offsetLeft + tooltip.caretX,
              y: offsetTop + tooltip.caretY,
              visible: true
            }
            prevTooltipData.current = newTooltipData
            setTooltipData(newTooltipData)
          }
        }
      }
    },
    onHover: (_, chartElement) => {
      const newIndex = chartElement.length ? chartElement[0].index : null
      newIndex !== hoveredIndex && setHoveredIndex(newIndex)
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      }
    }
  }

  return (
    <GraphBox isModalVariant={isModalVariant}>
      <Header
        averageConsumption={dataReports.dataConsumer.dataGraphic.averageConsumptionPerYear}
        averageContractedVolume={dataReports.dataConsumer.dataGraphic.averageAnnualContractedVolume}
      />

      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant} onMouseLeave={() => setHoveredIndex(null)}>
            <Bar data={data} options={options} />
            {tooltipData.visible && (
              <CustomTooltip
                x={tooltipData!.x}
                y={tooltipData!.y}
                visible={tooltipData.visible}
                datas={[
                  {
                    label: 'Volume contratado',
                    value: barraA[hoveredIndex!]
                  },
                  {
                    label: 'Volume consumido',
                    value: barraB[hoveredIndex!]
                  }
                ]}
              />
            )}
          </GraphContainer>

          <Box display={'flex'} flexWrap={'wrap'} gap={1} width={'90%'}>
            <Legend color={theme.designSystem.primary[100]} text="Volume contratado" variant="square" />
            <Legend color={theme.designSystem.primary[500]} text="Volume consumido" variant="square" />
            <Legend
              color={theme.designSystem.green[500]}
              secondaryColor={theme.designSystem.deepOrange[500]}
              text="Curto prazo"
              variant="square"
            />
            <Legend
              color={theme.designSystem.attention[500]}
              text="Flexibilidade mínima"
              variant="line-thin"
            />
            <Legend
              color={theme.designSystem.deepOrange[500]}
              text="Flexibilidade máxima"
              variant="line-thin"
            />
            <Legend
              color={theme.designSystem.primary[100]}
              secondaryColor={theme.designSystem.primary[200]}
              text="Aguardando medição"
              variant="line-thick"
            />
          </Box>
        </>
      ) : (
        <>
          <EmptyData />
          <Box sx={{ mt: 3 }}>
            <Legend color={theme.designSystem.base[200]} text="Não disponível" variant="square" />
          </Box>
        </>
      )}
    </GraphBox>
  )
}

export default memo(ConsumeGraph)
