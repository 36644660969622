export const IconCaretLeft = () => {
  return (
    <svg width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1L1 8L9 15"
        stroke="#050922"
        strokeOpacity={0.38}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
