import { Box, Modal, Slide } from '@mui/material'
import { useState } from 'react'
import { SecurityValidation } from './components/SecurityValidation'


type Props = {
  open: boolean
  onClose: () => void
  cpf: string
  password: string
}

export const LoginFlow = ({ open, onClose, cpf, password }: Props) => {
  const [showContent, setShowContent] = useState(true)
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('left')

  const handleCloseModal = () => {
    setShowContent(false)
    setTimeout(() => {
      onClose()
      setShowContent(true)
      setSlideDirection('left')
    }, 300)
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          zIndex: 1300
        }}
      >
        {
          (
            <Slide in={showContent} direction={slideDirection} timeout={300}>
              <Box>
                <SecurityValidation
                  handleClose={handleCloseModal}
                  cpf={cpf}
                  password={password}
                />
              </Box>
            </Slide>
          )}
      </Box>
    </Modal>
  )
}
