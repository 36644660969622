import { BoxProps, Box as MuiBox } from '@mui/material'
import { forwardRef } from 'react'

export const Box = forwardRef<HTMLDivElement, BoxProps>(({ children, ...rest }, ref) => {
  return (
    <MuiBox ref={ref} {...rest}>
      {children}
    </MuiBox>
  )
})
