type Props = {
  role?: string
}

export const IconPdf = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width={14}
      height={16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.33268V1.99935C1 1.26335 1.59733 0.666016 2.33333 0.666016H9L13 4.66602V5.33268M2.33333 11.9993V7.99935H3.66667C4.03467 7.99935 4.33333 8.29735 4.33333 8.66602V9.99935C4.33333 10.368 4.03467 10.666 3.66667 10.666H2.33333M10.3333 11.9993V7.99935H12.3333M10.3333 9.99935H11.6667M12.8216 14.666C12.5909 15.0647 12.1603 15.3327 11.6669 15.3327H2.3336C1.8396 15.3327 1.40893 15.0647 1.17827 14.666M6.33333 11.9993V7.99935H7.66667C8.03467 7.99935 8.33333 8.29735 8.33333 8.66602V11.3327C8.33333 11.7013 8.03467 11.9993 7.66667 11.9993H6.33333Z"
        stroke="#050922"
        strokeOpacity={0.6}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
