import { useTheme } from 'shared/hook/useTheme'

type Props = {
  role?: string
  color?: string
  width?: number
  height?: number
}

export const IconClose = ({ role, color, width = 12, height = 12 }: Props) => {
  const theme = useTheme()
  const defaultColor = color || theme.designSystem.base[900]

  return (
    <svg
      role={role}
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L11 11M1 11L11 1"
        stroke={defaultColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
