import React from 'react'

interface Props {
  href?: string
  onClick?: () => void
}

export const IconFacebook = ({ href, onClick }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick()
    }
    if (!href) {
      event.preventDefault()
    }
  }

  return (
    <a
      href={href ?? '#'}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'inline-block' }}
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#050922" fillOpacity="0.01" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#050922" strokeOpacity="0.08" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 16.0489C24 11.6036 20.4183 8 16 8C11.5817 8 8 11.6036 8 16.0489C8 20.0663 10.9255 23.3961 14.75 24V18.3755H12.7187V16.0489H14.75V14.2756C14.75 12.2584 15.9444 11.1441 17.7717 11.1441C18.647 11.1441 19.5625 11.3013 19.5625 11.3013V13.2821H18.5537C17.5599 13.2821 17.25 13.9025 17.25 14.539V16.0489H19.4687L19.114 18.3755H17.25V24C21.0745 23.3961 24 20.0663 24 16.0489Z"
          fill="#050922"
          fillOpacity="0.16"
        />
      </svg>
    </a>
  )
}
