import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CostCenterReport, DataConsumer, DataEconomy, EconomyAlertReport, SustainabilityReport } from '../interface';
import { TableContainer } from "./styles";

interface Props {
  data: DataConsumer[] | DataEconomy[] | CostCenterReport[] | EconomyAlertReport[] | SustainabilityReport[]
  columns: GridColDef[]
}

export const Table = ({ data, columns }: Props) => {

  return (
    <TableContainer>
      <DataGrid
        rows={data}
        columns={columns}
        disableColumnSorting
        disableColumnFilter
        hideFooterSelectedRowCount
        hideFooterPagination
        disableRowSelectionOnClick
        hideFooter
      />
    </TableContainer>
  )
}
