import { Box as BoxMui } from 'shared/designSystem/components/Box'
import { Button as ButtonMui } from 'shared/designSystem/components/Button'
import { Typography as TypographyMui } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainBox = styledSystem(BoxMui)({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'fixed'
})

export const FormboxContainer = styledSystem(BoxMui)(({ theme }) => ({
  height: theme.designSystem.size[550],
  width: theme.designSystem.size[484],
  borderWidth: theme.designSystem.size[5],
  borderStyle: 'solid',
  borderColor: theme.designSystem.base[100],
  marginBottom: theme.designSystem.size[80]
}))

export const StyledImage = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.designSystem.size[120]
}))

export const TypographyBox = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: theme.designSystem.size[44],
  paddingLeft: theme.designSystem.size[44],
  paddingRight: theme.designSystem.size[44],
  paddingBottom: theme.designSystem.size[120]
}))

export const Title = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.h3,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[800]
}))

export const Description = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body2,
  color: theme.designSystem.base[500],
  marginTop: theme.designSystem.size[16]
}))

export const Button = styledSystem(ButtonMui)(({ theme }) => ({
  variant: 'contained',
  width: theme.designSystem.size[396],
  height: theme.designSystem.size[48],
  backgroundColor: theme.designSystem.base[900],
  borderRadius: theme.designSystem.size[24],
  color: theme.designSystem.base[50],
  fontSize: theme.designSystem.size[14],
  display: 'flex',
  marginLeft: theme.designSystem.size[44]
}))
