import { useEffect, useState } from 'react'
import { StyledNavigationButton } from 'pages/Home/styles'
import { Box } from 'shared/designSystem/components/Box'
import IconArrowLeft from 'shared/designSystem/Icons/IconArrowLeft'
import IconArrowRight from 'shared/designSystem/Icons/IconArrowRight'
import { PaymentInfoCard } from './PaymentInfoCard'
import { PaymentTitle } from './PaymentTitle'
import { InvoiceDto, PaymentOverviewProps } from 'pages/Home/models/Invoice'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'
import { ButtonsContainerBox, CardBox, CardContainerBox, NoDataBox, PaymentContainerBox } from './styles'
import { ErrorCard } from '../Error/ErrorCard'
import { formatDate } from 'shared/utils/formatDate'

export const PaymentOverview = ({ fetchDataPayments, dataResult, error }: PaymentOverviewProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)
  const [cardData, setCardData] = useState<InvoiceDto[]>([])
  const [paymentSummary, setPaymentSummary] = useState({ currentMonthTotal: 0, lastMonthDifference: 0 })

  const handlePrevious = () => {
    if (!isAnimating) {
      setIsAnimating(true)
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? cardData.length - 1 : prevIndex - 1))
    }
  }

  const handleNext = () => {
    if (!isAnimating) {
      setIsAnimating(true)
      setCurrentIndex((prevIndex) => (prevIndex === cardData.length - 1 ? 0 : prevIndex + 1))
    }
  }

  const handleTransitionEnd = () => {
    setIsAnimating(false)
  }

  const fetchPayments = async () => {
    try {
      if (dataResult?.data?.result) {
        const payments = dataResult.data.result.map((item: any) => {
          return {
            ucName: item.fullName,
            name: `Fatura - ${item.referenceMonth}/${item.referenceYear}`,
            status: item.invoiceStatus || 'Não disponível',
            description: `Consumo: ${item.consumption} kWh`,
            date: formatDate(item),
            value: item.totalInvoiceAmount
          }
        })

        setCardData(payments)
        setPaymentSummary({
          currentMonthTotal: dataResult.data.totalAmountDueThisMonth || 0,
          lastMonthDifference: dataResult.data.percentageChangeFromLastMonth || 0
        })
      }
    } catch (error: any) {
      setCardData([])
      setPaymentSummary({ currentMonthTotal: 0, lastMonthDifference: 0 })
    }
  }

  useEffect(() => {
    fetchPayments()
  }, [dataResult])

  return (
    <PaymentContainerBox>
      {error ? (
        <Box height={518} display={'flex'} justifyContent={'center'}>
          <ErrorCard message="de fatura" onRetry={fetchDataPayments} />
        </Box>
      ) : (
        <>
          <PaymentTitle
            totalValue={paymentSummary.currentMonthTotal}
            comparisonValue={paymentSummary.lastMonthDifference}
          />

          {cardData.length === 0 ? (
            <NoDataBox>
              <Box width={207}>
                <Typography
                  fontSize={theme.designSystem.typography.fontSize.button}
                  fontWeight={theme.designSystem.typography.fontWeight.regular}
                  color={theme.designSystem.base[300]}
                >
                  Nenhum lançamento cadastrado. Não se preocupe, você será avisado em caso de novidades.
                </Typography>
              </Box>
            </NoDataBox>
          ) : (
            <CardContainerBox>
              <CardBox
                cardDataLength={cardData.length}
                currentIndex={currentIndex}
                cardWidth={256}
                onTransitionEnd={handleTransitionEnd}
              >
                {cardData.map((card, index) => (
                  <Box
                    key={index}
                    sx={{
                      flexShrink: 0,
                      width: 256
                    }}
                  >
                    <PaymentInfoCard {...card} />
                  </Box>
                ))}
              </CardBox>
            </CardContainerBox>
          )}

          <ButtonsContainerBox>
            <StyledNavigationButton onClick={handlePrevious} disabled={cardData.length === 0}>
              <IconArrowLeft
                color={
                  cardData.length === 0 ? theme.designSystem.base[400] : theme.designSystem.base[900]
                }
              />
            </StyledNavigationButton>

            <StyledNavigationButton onClick={handleNext} disabled={cardData.length === 0}>
              <IconArrowRight
                color={
                  cardData.length === 0 ? theme.designSystem.base[400] : theme.designSystem.base[900]
                }
              />
            </StyledNavigationButton>
          </ButtonsContainerBox>
        </>
      )}
    </PaymentContainerBox>
  )
}

export default PaymentOverview
