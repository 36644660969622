import { useLocation } from 'react-router-dom'

import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { useTheme } from 'shared/hook/useTheme'
import { useConsumitionStore } from 'shared/store/consumition'
import { useReportStore } from 'shared/store/reports'
import { HeaderContainer } from './HeaderContainer'
import { ContainerFilters } from './HeaderContainer/ContainerFilters'
import { HomeOptions } from './HeaderContainer/HomeOptions'
import { ProductBadge } from './HeaderContainer/ProductBadge'
import { ReportOptions } from './HeaderContainer/ReportOptions'
import type { TypePropsPageHeader } from './interface'
import { ButtonActivation, HeaderMiddleContent } from './styles'

import { downloadAllPdf } from 'shared/utils/downloadAllPdf'
import { pdfElements } from 'shared/mocks/pdfElements'

export const Header = ({ fetchData }: TypePropsPageHeader) => {
  const theme = useTheme()
  const {
    state: { error }
  } = useConsumitionStore()
  const {
    state: { isOpen, graphicName, pdfRef, filterDefault },
    actions: { closeModal }
  } = useReportStore()
  const location = useLocation()

  return (
    <HeaderContainer>
      {isOpen && <div onClick={closeModal}>X</div>}
      {!filterDefault && <ContainerFilters fetchData={fetchData} />}

      <HeaderMiddleContent sx={{ marginInline: `${filterDefault && 'auto'}` }}>
        <Typography
          fontWeight={theme.designSystem.typography.fontWeight.bold}
          fontSize={{
            xs: theme.designSystem.typography.fontSize.body2,
            sm: theme.designSystem.typography.fontSize.body1
          }}
          color={theme.designSystem.base[900]}
        >
          {location.pathname === '/home' && 'Visão Geral'}
          {location.pathname === '/reports' && !isOpen && 'Relatórios'}
          {isOpen && `Relatório de ${graphicName}`}
        </Typography>
        {error && location.pathname !== '/reports' && (
          <ButtonActivation role="activation">
            <Typography
              fontSize={{
                xs: theme.designSystem.typography.fontSize.caption,
                sm: theme.designSystem.typography.fontSize.button
              }}
              fontWeight={theme.designSystem.typography.fontWeight.medium}
            >
              Ver Ativação
            </Typography>
          </ButtonActivation>
        )}
      </HeaderMiddleContent>

      <Box display="flex" alignItems="center"></Box>

      <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
        {location.pathname === '/home' && <HomeOptions />}
        {location.pathname === '/reports' && !filterDefault && (
          <>
            <ProductBadge name="Energy" />
            <ReportOptions
              handlePrint={() => downloadAllPdf(pdfRef!, pdfElements, 'print')}
              handleDownload={() => downloadAllPdf(pdfRef!, pdfElements, 'download')}
            />
          </>
        )}
      </Box>
    </HeaderContainer>
  )
}
