type Props = {
  role?: string
}

export const IconExit = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3H5C3.896 3 3 3.896 3 5V19C3 20.104 3.896 21 5 21H12M17 16L21 12M21 12L17 8M21 12H9"
        stroke="#FF7043"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
