import { Box } from '@mui/material'

const NotFound = () => {
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <img src="/notfound.png" alt="image-not-found" />
    </Box>
  )
}
export default NotFound
