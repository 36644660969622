import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconCheck } from 'shared/designSystem/Icons/IconCheck'
import { useTheme } from 'shared/hook/useTheme'
import { DividerContainer } from '../../styles'
import { PasswordRecoveryButton } from '../PasswordRecoveryButton'
import { RecoveryTitle } from '../PasswordRecoveryTitle'
import { BoxContainer, BoxContent, BoxIcon } from './styles'

type Props = {
  handleClose: () => void
}

export const SuccessChangePassword = ({ handleClose }: Props) => {
  const theme = useTheme()
  return (
    <Box height="100vh" mt={20}>
      <BoxContainer>
        <Box>
          <RecoveryTitle text="Recuperação de senha" handleClose={handleClose} />
          <DividerContainer />
        </Box>

        <BoxContent>
          <BoxIcon>
            <IconCheck width={24} height={24} role="check-icon" />
          </BoxIcon>

          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            fontSize={theme.designSystem.typography.fontSize.body1}
            color={theme.designSystem.base[900]}
          >
            Senha alterada!
          </Typography>
          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            fontSize={theme.designSystem.typography.fontSize.body2}
            color={theme.designSystem.base[500]}
            sx={{
              textAlign: 'center'
            }}
          >
            A partir de agora você pode acessar nossa plataforma utilizando sua nova senha.
          </Typography>
        </BoxContent>

        <PasswordRecoveryButton onClick={handleClose}>FECHAR</PasswordRecoveryButton>
      </BoxContainer>
    </Box>
  )
}
