import { GridColDef } from '@mui/x-data-grid'
import { RefObject } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export const downloadAllPdf = async (
  pdfRef: RefObject<HTMLDivElement>,
  pdfElements: { Graph: any; data: any[]; columns: GridColDef[]; id: number }[],
  operation: 'download' | 'print'
) => {
  if (!pdfRef.current) {
    console.error('Elemento não encontrado para gerar o PDF')
    return
  }

  try {
    const pdf = new jsPDF('p', 'mm', 'a4')

    for (let i = 0; i < pdfElements.length; i++) {
      const element = pdfRef.current.children[i]
      if (!element) continue

      const canvas = await html2canvas(element as HTMLElement)
      const imgData = canvas.toDataURL('image/png')
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgHeight = (canvas.height * pdfWidth) / canvas.width

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, Math.min(imgHeight, pdfHeight))

      if (i < pdfElements.length - 1) {
        pdf.addPage()
      }
    }
    const pdfBlob = pdf.output('blob')
    const pdfUrl = URL.createObjectURL(pdfBlob)

    {
      operation === 'download' ? pdf.save('relatorios.pdf') : window.open(pdfUrl, '_blank')
    }
  } catch (error) {
    console.error('Erro ao gerar PDF:', error)
  }
}
