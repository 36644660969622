import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const ConsumeGraphBox = styledSystem(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  padding: theme.designSystem.size[32],
  backgroundColor: theme.designSystem.white,
  display: 'flex',
  flexDirection: 'column'
}))
