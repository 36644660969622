import { Tutorial } from 'pages/Home/models/Tutorial'
import { ButterApi } from 'pages/Home/services/ButterApi'
import {
  HomeCardStyled,
  StyledButton,
  StyledNavigationButton,
  StyledTypography
} from 'pages/Home/styles'
import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import IconArrowLeft from 'shared/designSystem/Icons/IconArrowLeft'
import IconArrowRight from 'shared/designSystem/Icons/IconArrowRight'
import { TutorialCard } from './TutorialCard'

interface Props {
  onError: () => void
}

export const TutorialOverview = ({ onError }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [tutorials, setTutorials] = useState<Tutorial[]>([])
  const [isAnimating, setIsAnimating] = useState(false)

  const handleNext = () => {
    if (!isAnimating) {
      setIsAnimating(true)
      setCurrentIndex((prevIndex) => (prevIndex + 1) % tutorials.length)
    }
  }

  const handlePrev = () => {
    if (!isAnimating) {
      setIsAnimating(true)
      setCurrentIndex((prevIndex) => (prevIndex - 1 + tutorials.length) % tutorials.length)
    }
  }

  const handleTransitionEnd = () => {
    setIsAnimating(false)
  }

  useEffect(() => {
    const fetchTutorials = async () => {
      try {
        const fetchedTutorials = await ButterApi.getTutorials()
        if (fetchedTutorials.length === 0) return onError()

        setTutorials(fetchedTutorials)
      } catch (error) {
        onError()
      }
    }

    fetchTutorials()
  }, [setTutorials, onError])

  return (
    <HomeCardStyled sx={{ height: 503, justifyContent: 'center' }}>
      {tutorials.length > 0 ? (
        <>
          <Box display="flex" justifyContent="space-between">
            <StyledTypography>Tutoriais para aprender</StyledTypography>
            <Box>
              <StyledButton variant="outlined" size="small">
                VER TODOS
              </StyledButton>
            </Box>
          </Box>

          <Box
            display="flex"
            onTransitionEnd={handleTransitionEnd}
            sx={{
              width: `${tutorials.length * 250}px`,
              transform: `translateX(-${currentIndex * 250}px)`,
              transition: 'transform 0.5s ease-in-out',
              overflow: 'hidden',
              gap: 3
            }}
          >
            {tutorials.map((tutorial) => (
              <TutorialCard
                key={tutorial.title}
                title={tutorial.title}
                description={tutorial.description}
                image={tutorial.image}
              />
            ))}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
            <StyledNavigationButton onClick={handlePrev} aria-label="Previous tutorial">
              <IconArrowLeft />
            </StyledNavigationButton>
            <StyledNavigationButton onClick={handleNext} aria-label="Next tutorial">
              <IconArrowRight />
            </StyledNavigationButton>
          </Box>
        </>
      ) : null}
    </HomeCardStyled>
  )
}
