import { NoDataBox } from 'pages/Reports/styles'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'

export const EmptyData = () => {
  return (
    <NoDataBox>
      <Box>
        <Typography
          fontSize={theme.designSystem.typography.fontSize.button}
          fontWeight={theme.designSystem.typography.fontWeight.regular}
          color={theme.designSystem.base[300]}
        >
          Não há dados disponíveis para a visualização do gráfico.
        </Typography>
      </Box>
    </NoDataBox>
  )
}
