import { useState } from 'react'
import { TypographyBox } from '../TypographyBox'
import {
  Button,
  ButtonContainer,
  CheckBox,
  CheckboxContainer,
  Description,
  Error,
  FormboxContainer,
  MainBox,
  StyledContentBox
} from './styles'

interface FormBoxProps {
  role?: string
  onAccept: (accepted: boolean) => void
}

export const FormBox = ({ role, onAccept }: FormBoxProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev)
    setError(null)
  }

  const handleSubmit = async () => {
    if (!isChecked) {
      setError('Você deve aceitar os termos e políticas.')
      return
    }
    onAccept(true)
  }

  return (
    <MainBox role={role}>
      <FormboxContainer role="form-box">
        <StyledContentBox role="styled-box">
          <TypographyBox
            title="Termos e políticas"
            description="Ao selecionar “Eu concordo”, você declara que leu, revisou e concordou com os Termos de Uso
              e Política de Privacidade."
            typographyBoxRole="typography-box"
          />
        </StyledContentBox>
        <CheckboxContainer>
          <CheckBox role="accept-checkbox" checked={isChecked} onChange={handleCheckboxChange} />
          <Description role="accept-text">Eu concordo</Description>
        </CheckboxContainer>
        <ButtonContainer>
          {error && <Error role={'error'}>{error}</Error>}
          <Button role="button" onClick={handleSubmit}>
            Continuar
          </Button>
        </ButtonContainer>
      </FormboxContainer>
    </MainBox>
  )
}
