type Props = {
  role?: string
  strokeColor?: string
}

export const IconPageAlt = ({ role, strokeColor }: Props) => {
  return (
    <svg
      role={role}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6673 7.72735H10.7414V1.66675M16.6673 7.72735L10.7414 1.66675M16.6673 7.72735V16.8183C16.6673 17.6546 16.0036 18.3334 15.1858 18.3334H4.81547C3.99769 18.3334 3.33398 17.6546 3.33398 16.8183V3.1819C3.33398 2.34554 3.99769 1.66675 4.81547 1.66675H10.7414"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
