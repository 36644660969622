import StyledRiveLoading from '@rive-app/react-canvas'
import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const LoadingCustom = styledSystem(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const RiveLoading = styledSystem(StyledRiveLoading)(() => ({
  width: '32.14vw',
  height: '31.02vh'
}))
