interface Props {
  width?: number
  height?: number
  color?: string
}

export default function IconArrowLeft({ width = 14, height = 12, color = `#050922` }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33333 1.33325L1 5.99992M1 5.99992L6.33333 10.6666M1 5.99992H13"
        stroke={color}
        strokeOpacity={0.38}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
