import { SnackbarProps } from '@mui/material'
import { createPortal } from 'react-dom'
import { IconCheck } from 'shared/designSystem/Icons/IconCheck'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { useTheme } from 'shared/hook/useTheme'
import { Box } from '../Box'
import { Snackbar } from '../Snackbar'
import { Typography } from '../Typography'

interface Props extends SnackbarProps {
  title: string
  message: string
  open: boolean
  color?: string
  onClose: () => void
  type: string
}

export const AlertSnackbar = ({ title, message, open, onClose, type, ...rest }: Props) => {
  const theme = useTheme()

  if (!open) return null

  return createPortal(
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      {...rest}
      sx={{
        width: '320px',
        backgroundColor: type === 'success' ? '#73A144' : '#D04B24',
        position: 'fixed',
        right: '20px',
        top: '24px',
        left: 'auto',
        zIndex: 1300
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        width={theme.designSystem.size[320]}
        height={theme.designSystem.size[81]}
        padding="16px 24px"
      >
        <Box
          sx={{
            backgroundColor: type === 'error' ? theme.designSystem.deepOrange[500] : '#8BC34A',
            borderRadius: '50%',
            width: theme.designSystem.size[24],
            height: theme.designSystem.size[24],
            marginRight: theme.spacing(3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {type === 'error' ? (
            <IconClose height={8} width={8} color={theme.designSystem.base[50]} />
          ) : (
            <IconCheck />
          )}
        </Box>

        <Box>
          <Typography
            sx={{
              color: theme.designSystem.base[50],
              fontWeight: theme.designSystem.typography.fontWeight.bold,
              fontSize: theme.designSystem.typography.fontSize.body2
            }}
          >
            {title}
          </Typography>

          <Typography
            sx={{
              color: theme.designSystem.base[50],
              fontWeight: theme.designSystem.typography.fontWeight.regular,
              fontSize: theme.designSystem.typography.fontSize.button
            }}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    </Snackbar>,
    document.body
  )
}
