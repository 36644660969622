import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const BoxParent = styledSystem(Box)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: theme.shadows[3],
  padding: theme.spacing(3),
  width: '95%',
  maxWidth: 456,
  minHeight: 460,
  marginInline: 'auto'
}))

export const BoxChildren = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  marginTop: theme.spacing(5),
}))

export const BoxContainer = styledSystem(Box)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: theme.shadows[3],
  padding: theme.spacing(3),
  width: '95%',
  maxWidth: 456,
  minHeight: 500,
  marginInline: 'auto'
}))

export const BoxIconError = styledSystem(Box)(({ theme }) => ({
  height: theme.designSystem.size[66],
  width: theme.designSystem.size[66],
  background: theme.designSystem.deepOrange[500],
  padding: theme.spacing(2.6),
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const BoxResendCode = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4)
}))

export const BoxCircleTimer = styledSystem(Box)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.designSystem.size[40],
  height: theme.designSystem.size[40],
  borderRadius: '100%',
  backgroundColor: theme.designSystem.base[100],
}))
