import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { Unity } from 'shared/models/Unity'
import { formatDate } from 'shared/utils/formatDate'
import {
  CustomBox,
  CustomImg,
  GridContainer,
  GridUnity,
  GridUnityDescription,
  GridUnityTitle
} from './styles'

interface Props {
  role?: string
  infoData: Unity
}

export const Info = ({ role, infoData }: Props) => {
  const renderStatusDescription = (status: string) => {
    switch (status) {
      case 'EmPreparacao':
        return <GridUnityDescription>Em Preparação</GridUnityDescription>
      case 'EmMigracao':
        return <GridUnityDescription>Em migração</GridUnityDescription>
      default:
        return <GridUnityDescription>Migração concluída</GridUnityDescription>
    }
  }

  return (
    <Box role={role}>
      <CustomBox role="custom-box">
        {infoData.clientLogo == '' ? (
          <CustomImg role="example-img" src="/public/logo_example.svg" alt={infoData.clientFullName} />
        ) : (
          <CustomImg role="img" src={infoData.clientLogo} alt={infoData.clientFullName} />
        )}
        <Typography role="card-enterprise-name" sx={{ fontWeight: 'bold', color: '#000000' }}>
          {infoData.clientFullName}
        </Typography>

        <GridContainer role="grid-container">
          <GridUnity role="grid-unity-situation">
            <GridUnityTitle>Situação</GridUnityTitle>
            {renderStatusDescription(infoData.status)}
          </GridUnity>
          <GridUnity role="grid-unity-cod-uc">
            <GridUnityTitle>Código da UC</GridUnityTitle>
            <GridUnityDescription>{infoData.consumerUnitInstallationNumber}</GridUnityDescription>
          </GridUnity>
          <GridUnity role="grid-unity-distributor">
            <GridUnityTitle>Distribuidora</GridUnityTitle>
            <GridUnityDescription>{infoData.distributorName}</GridUnityDescription>
          </GridUnity>
          {infoData.distributorTypeName && infoData.distributorName !== '' && (
            <GridUnity role="grid-unity-category">
              <GridUnityTitle>Categoria</GridUnityTitle>
              <GridUnityDescription>{infoData.distributorTypeName}</GridUnityDescription>
            </GridUnity>
          )}
          <GridUnity role="grid-unity-date">
            <GridUnityTitle>Data estimada</GridUnityTitle>
            <GridUnityDescription>{formatDate(infoData.migrationDate)}</GridUnityDescription>
          </GridUnity>
          <GridUnity role="grid-unity-progress">
            <GridUnityTitle>Progresso</GridUnityTitle>
            <GridUnityDescription>{infoData.progress}%</GridUnityDescription>
          </GridUnity>
        </GridContainer>
      </CustomBox>
    </Box>
  )
}

export default Info
