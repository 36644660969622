type Props = {
  role?: string
  strokeColor?: string
}

export const IconChartPie = ({ role, strokeColor }: Props) => {
  return (
    <svg
      role={role}
      width="68"
      height="56"
      viewBox="0 0 68 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.4997 20.6674C27.6963 21.4399 24.833 24.8016 24.833 28.8332C24.833 33.4357 28.5638 37.1666 33.1663 37.1666C37.198 37.1666 40.5605 34.3041 41.3322 30.4999M43.1663 27.1668C43.1663 22.5643 39.4347 18.8335 34.833 18.8335V27.1668H43.1663Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
