import { Box as BoxMui } from 'shared/designSystem/components/Box'
import { Button as ButtonMui } from 'shared/designSystem/components/Button'
import { Checkbox as CheckboxMui } from 'shared/designSystem/components/Checkbox'
import { Typography as TypographyMui } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainBox = styledSystem(BoxMui)({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'fixed'
})

export const FormboxContainer = styledSystem(BoxMui)(({ theme }) => ({
  height: theme.designSystem.size[550],
  width: theme.designSystem.size[484],
  borderWidth: theme.designSystem.size[5],
  borderStyle: 'solid',
  borderColor: theme.designSystem.base[100],
  marginBottom: theme.designSystem.size[80]
}))

export const StyledContentBox = styledSystem(BoxMui)(({ theme }) => ({
  height: theme.designSystem.size[350],
  width: theme.designSystem.size[480],
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderBottomWidth: theme.designSystem.size[5],
  borderStyle: 'solid',
  borderColor: theme.designSystem.base[100]
}))

export const CheckboxContainer = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.designSystem.size[32]
}))

export const Description = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body2,
  color: theme.designSystem.base[500],
  marginTop: theme.designSystem.size[16]
}))

export const CheckBox = styledSystem(CheckboxMui)(({ theme }) => ({
  marginTop: theme.designSystem.size[16]
}))

export const ButtonContainer = styledSystem(BoxMui)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column'
}))

export const Button = styledSystem(ButtonMui)(({ theme }) => ({
  variant: 'contained',
  display: 'flex',
  alignSelf: 'center',
  width: theme.designSystem.size[396],
  height: theme.designSystem.size[48],
  backgroundColor: theme.designSystem.base[900],
  borderRadius: theme.designSystem.size[24],
  color: theme.designSystem.base[50],
  fontSize: theme.designSystem.size[14],
  marginTop: theme.designSystem.size[50]
}))

export const Error = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  color: theme.designSystem.deepOrange[500],
  position: 'absolute',
  float: 'left',
  marginLeft: theme.designSystem.size[44]
}))
