import React from 'react'

interface Props {
  href?: string
  onClick?: () => void
}

export const IconLinkedIn = ({ href, onClick }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick()
    }
    if (!href) {
      event.preventDefault()
    }
  }

  return (
    <a
      href={href ?? '#'}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'inline-block' }}
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#050922" fillOpacity="0.01" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#050922" strokeOpacity="0.08" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24ZM13.913 12.1128C13.913 12.6108 13.5338 13.0139 12.9408 13.0139C12.3709 13.0139 11.9916 12.6108 12.0034 12.1128C11.9916 11.5905 12.3708 11.1992 12.9522 11.1992C13.5338 11.1992 13.9016 11.5905 13.913 12.1128ZM12.0511 19.5265V13.7257H13.854V19.5265H12.0511ZM15.2516 13.7271C15.2754 14.2372 15.2992 14.8541 15.2992 15.5776L15.3 19.5282H17.1029V16.1828C17.1029 16.0163 17.1264 15.8505 17.174 15.7317C17.2925 15.3998 17.5888 15.0558 18.0872 15.0558C18.7397 15.0558 19.0004 15.5656 19.0004 16.3133V19.5282H20.8033V16.0874C20.8033 14.3799 19.9136 13.5969 18.7276 13.5969C17.7667 13.5969 17.1737 14.154 16.9364 14.5218H16.9008L16.8176 13.7271H15.2516Z"
          fill="#050922"
          fillOpacity="0.16"
        />
      </svg>
    </a>
  )
}
