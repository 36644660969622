type Props = {
  role?: string
}

export const IconVisibilityOff = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0015 12.1085C18.7415 11.1685 19.1665 10.3552 19.1665 9.9985C19.1665 8.88767 15.0623 3.33184 9.99983 3.33184C9.75483 3.33184 9.514 3.356 9.27316 3.38017M1.99725 7.88975C1.25808 8.82892 0.833913 9.64308 0.833913 9.99892C0.833913 11.1098 4.93808 16.6656 10.0006 16.6656C10.2439 16.6656 10.4847 16.6414 10.7247 16.6173M0.833496 0.833252L19.1668 19.1666"
        stroke="#050922"
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  )
}
