import { useRef } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { columnsEconomyAlertGraph } from 'shared/mocks/columnsPdf'
import { downloadPdf } from 'shared/utils/downloadPdf'
import { dataReports } from '../../../../shared/mocks/downloadPdf.json'
import { Header } from '../DetailsModal/Header'
import { ModalContainer } from '../DetailsModal/styles'
import EconomyAlertGraph from '../EconomyAlertGraph'
import { PDFDocument } from '../PDFDocument'

export const EconomyAlertModalDetail = () => {
  const pdfRef = useRef(null)
  const pdfElements = [
    {
      graph: <EconomyAlertGraph variant="modal" />,
      data: dataReports.dataEconomyAlert.dataTable,
      columns: columnsEconomyAlertGraph
    }
  ]

  const fetchData = () => {}
  return (
    <>
      <ModalContainer sx={{ overflow: 'scroll', display: 'flex', flexDirection: 'column' }}>
        <Header
          fetchData={fetchData}
          handlePrint={() => downloadPdf(pdfRef, pdfElements, 'print')}
          handleDownload={() => downloadPdf(pdfRef, pdfElements, 'download')}
        />
        <Box sx={{ flexGrow: 1 }}>
          <EconomyAlertGraph variant="modal" />
        </Box>
      </ModalContainer>
      <Box
        sx={{
          position: 'absolute',
          top: '-9999px',
          width: '70vw',
          height: '70vh'
        }}
        ref={pdfRef}
      >
        {pdfElements.map((pdf, i) => (
          <PDFDocument key={i} data={pdf.data} columns={pdf.columns}>
            {pdf.graph}
          </PDFDocument>
        ))}
      </Box>
    </>
  )
}
