import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Fade, Modal, Typography } from '@mui/material'
import { useState } from 'react'
import { BoxContainer, DividerContainer, TextFieldInput } from 'pages/SignIn/styles'
import { RecoveryTitle } from 'pages/SignIn/components/PasswordRecoveryTitle'
import { Grid } from 'shared/designSystem/components/Grid'
import { ShareButton } from './ShareButton'
import { DotsLoader } from 'shared/designSystem/components/DotsLoader'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { ContainerMessage } from 'shared/designSystem/ContainerMessage'

type Props = {
  open: boolean
  onClose: () => void
  handeSendEmail: (arg: any) => void
}

export const EmailShare = ({ open, onClose, handeSendEmail }: Props) => {
  const [showContent, setShowContent] = useState(true)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({
    type: 'error',
    open: false,
    message: '',
    title: ''
  })

  const validationSchema = Yup.object({
    email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório')
  })

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        handeSendEmail(values)
        setSuccess(true)
        formik.resetForm()
      } catch (error) {
        setAlert({
          type: 'error',
          open: true,
          title: 'Falha ao compartilhar',
          message: 'Tente novamente'
        })
      } finally {
        setLoading(false)
      }
    }
  })

  const handleCloseModal = () => {
    setShowContent(false)
    setSuccess(false)
    setTimeout(() => {
      onClose()
      setShowContent(true)
    }, 300)
  }
  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box>
        <Fade in={showContent} timeout={300}>
          <Box height="100vh" mt={20}>
            <BoxContainer>
              <RecoveryTitle text="Compartilhar" handleClose={handleCloseModal} />
              <DividerContainer />
              <Grid container spacing={2} mt={5}>
                {!success ? (
                  <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                    <Typography sx={{ marginBottom: 5 }}>
                      Para compartilhar, digite o e-mail do destinatário:
                    </Typography>
                    <TextFieldInput
                      sx={{ marginBottom: 15 }}
                      variant="standard"
                      label="E-mail"
                      fullWidth
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />

                    <ShareButton type="submit" disabled={loading || !formik.isValid}>
                      {loading ? <DotsLoader /> : 'Compartilhar'}
                    </ShareButton>
                  </form>
                ) : (
                  <>
                    <ContainerMessage
                      margin="20px auto 50px auto"
                      type="success"
                      title="Relatórios enviados!"
                      message="Os relatórios foram enviados com sucesso para o destinatário."
                    />
                    <ShareButton type="button" onClick={handleCloseModal}>
                      Fechar
                    </ShareButton>
                  </>
                )}
              </Grid>
            </BoxContainer>

            <AlertSnackbar
              type={alert.type}
              open={alert.open}
              onClose={() => setAlert({ ...alert, open: false })}
              title={alert.title}
              message={alert.message}
            />
          </Box>
        </Fade>
      </Box>
    </Modal>
  )
}
