import {
  Bar,
  BarContainer,
  EmptyBarsComponent,
  Icon,
  InfoContainer,
  InfoItem,
  LabelText,
  MonthsContainer,
  MonthText
} from './styles'

export const EmptyBars = () => {
  return (
    <EmptyBarsComponent>
      <BarContainer>
        {Array.from({ length: 12 }).map((_, index) => (
          <Bar key={index} />
        ))}
      </BarContainer>

      <MonthsContainer>
        {['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'].map((month, index) => (
          <MonthText key={index}>{month}</MonthText>
        ))}
      </MonthsContainer>

      <InfoContainer>
        <InfoItem>
          <Icon />
          <LabelText>Consumo confirmado</LabelText>
        </InfoItem>

        <InfoItem>
          <LabelText>Aguardando medição</LabelText>
          <Icon />
        </InfoItem>
      </InfoContainer>
    </EmptyBarsComponent>
  )
}

export default EmptyBars
