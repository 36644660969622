import { ChangeEventHandler } from 'react'
import { Input, InputTitle, StyledInputBox } from './styles'

interface InputBoxProps {
  role?: string
  title: string
  value: string | number
  onChange: ChangeEventHandler
  label: string
  error: boolean
  helperText: string
}

export const InputBox = ({ role, title, value, onChange, label, error, helperText }: InputBoxProps) => {
  return (
    <StyledInputBox role={role}>
      <InputTitle role="title">{title}</InputTitle>
      <Input
        role="input"
        variant="standard"
        value={value}
        onChange={onChange}
        label={label}
        error={error}
        helperText={helperText}
      />
    </StyledInputBox>
  )
}
