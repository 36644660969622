import { UserApi } from 'pages/FirstAccess/services/User'
import React, { useState } from 'react'
import { ErrorHandler } from 'shared/utils/ErrorHandler'
import { AdornmentInput } from '../../AdornmentInputBox'
import { Button, Description, FormboxContainer, MainBox, Title, TypographyBox } from './styles'

interface Props {
  role?: string
  email: string
  onPhoneChange: (phone: string) => void
}

export const PhoneBox = ({ role, email, onPhoneChange }: Props) => {
  const [phone, setPhone] = useState('')
  const [error, setError] = useState('')

  const validatePhone = (phoneValue: string) => {
    const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/

    if (!phoneValue) {
      return 'O campo de telefone celular é obrigatório.'
    }

    if (!phoneRegex.test(phoneValue)) {
      return 'Por favor, insira um número de telefone válido no formato (00) 00000-0000.'
    }

    return ''
  }

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = event.target.value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')

    setPhone(formattedPhone)
    setError('')
  }



  const handleNext  = () => {
    const validationError = validatePhone(phone)
    if (validationError) {
      setError(validationError)
      return
    }
    setError('')
    const phoneReplaced = phone.replace(/[&\/\\#,+()$~%.'":*?<>{}_-]/g, '')
    submitPhoneNumber(phoneReplaced)
  }

  const submitPhoneNumber = async (phone: string) => {
    setPhone(phone)
    try {
      await UserApi.sendVerificationCode({ email: email, phoneNumber: phone })
      onPhoneChange(phone)
    } catch (error) {
      ErrorHandler.getErrorMessage(error)
    }
  }

  return (
    <MainBox role={role}>
      <FormboxContainer role="form-box">
        <TypographyBox role={'typography-box'}>
          <Title>Cadastro de celular</Title>
          <Description>
            Verificamos que o seu perfil não possui um celular cadastrado. Essa informação é importante
            para aumentar a segurança da sua conta.
          </Description>
          <AdornmentInput
            role={'adornment-input'}
            flag="./public/flag.png"
            inputTitle={'Celular'}
            value={phone}
            label={'(00) 00000-0000'}
            error={!!error}
            onChange={handlePhoneChange}
            helperText={error}
          />
        </TypographyBox>
        <Button role="button" onClick={handleNext }>
          Continuar
        </Button>
      </FormboxContainer>
    </MainBox>
  )
}
