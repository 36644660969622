import { Box } from 'shared/designSystem/components/Box'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { useTheme } from 'shared/hook/useTheme'

type Props = {
  handleClose: () => void
  text: string
}

export const RecoveryTitle = ({ handleClose, text }: Props) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: '-4px',
        mb: 2,
        gap: 2
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          marginLeft: '-7px'
        }}
      >
        <IconClose role="close-icon" />
      </IconButton>

      <Typography
        fontWeight={theme.designSystem.typography.fontWeight.medium}
        fontSize={theme.designSystem.typography.fontSize.body1}
      >
        {text}
      </Typography>
    </Box>
  )
}
