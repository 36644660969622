import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { useTheme } from 'shared/hook/useTheme'
import { PasswordRecoveryButton } from '../PasswordRecoveryButton'
import { BoxIconError } from '../styles'
import { BoxContainer, BoxContent } from './styles'

type Props = {
  handleClose: () => void
}

export const AccessFailureModal = ({ handleClose }: Props) => {
  const theme = useTheme()
  return (
    <Box display="flex" justifyContent="center" alignItems="flex-start">
      <BoxContainer>
        <BoxContent>
          <BoxIconError>
            <IconClose color="white" width={20} height={20} />
          </BoxIconError>

          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            fontSize={theme.designSystem.typography.fontSize.body1}
            color={theme.designSystem.base[900]}
          >
            Falha ao entrar
          </Typography>
          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            fontSize={theme.designSystem.typography.fontSize.body2}
            color={theme.designSystem.base[500]}
            sx={{
              textAlign: 'center'
            }}
          >
            O acesso foi bloqueado devido a existência de inconsistências no cadastro. Entre em contato
            com o nosso atendimento:
          </Typography>

          <Typography
            fontSize={theme.designSystem.typography.fontSize.body2}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
          >
            nao-definido@genial.com.vc
          </Typography>
        </BoxContent>

        <PasswordRecoveryButton onClick={handleClose}>enviar e-mail</PasswordRecoveryButton>
      </BoxContainer>
    </Box>
  )
}
