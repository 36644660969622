import { api } from 'shared/services/Api'
import { EconomyData } from '../models/EconomyData'
import { createQueryString } from 'shared/utils/createQueryString'

export type filters = {
  userId: string, group: string, unit: string, product: number
}

export class EconomyApi {
  static async getData(filters: filters): Promise<EconomyData> {
    const currentYear = new Date().getFullYear()
    const response = await api.get(
      `/economy/get-economy-by-year-and-user-id?Year=${currentYear}&${createQueryString(filters)}`
    )
    return response.data.data
  }
}
