export const getMonthFirstLetter = (): string[] => {
  return Array.from({ length: 12 }, (_, i) => {
    const date = new Date(0, i); 
    return new Intl.DateTimeFormat("pt-BR", { month: "short" })
      .format(date)
      .charAt(0)
      .toUpperCase()
  });
};

export const getMonthAbbreviations = (): string[] => {
  return Array.from({ length: 12 }, (_, i) => {
    const date = new Date(0, i) 
    return new Intl.DateTimeFormat("pt-BR", { month: "short" })
      .format(date)
      .replace(".", "")
      .toUpperCase()
  });
};

export const getMonths = () => {
  return Array.from({ length: 12 }, (_, i) => {
    const month = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(new Date(2024, i))
    return month.charAt(0).toUpperCase() + month.slice(1)
  })
}

