type Props = {
  role?: string
  color?: string
}

export const IconArrowDown = ({ role, color }: Props) => {
  return (
    <svg
      role={role}
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.2487 12.4173H12.4154M12.4154 12.4173V3.25065M12.4154 12.4173L1.58203 1.58398"
        stroke={color || '#FAFBFF'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
