import { Highlight } from 'pages/Home/models/WeekHighlight'
import { ButterApi } from 'pages/Home/services/ButterApi'
import {
  HomeCardStyled,
  StyledButton,
  StyledNavigationButton,
  StyledTypography
} from 'pages/Home/styles'
import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { CardActions } from 'shared/designSystem/components/CardActions'
import { CardContent } from 'shared/designSystem/components/CardContent'
import { Typography } from 'shared/designSystem/components/Typography'
import IconArrowLeft from 'shared/designSystem/Icons/IconArrowLeft'
import IconArrowRight from 'shared/designSystem/Icons/IconArrowRight'
import { useTheme } from 'shared/hook/useTheme'

interface Props {
  onError: () => void
}

const WeekHighlights = ({ onError }: Props) => {
  const theme = useTheme()

  const [highlights, setHighlights] = useState<Highlight[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? highlights.length - 1 : prevIndex - 1))
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === highlights.length - 1 ? 0 : prevIndex + 1))
  }

  const fetchWeekHighlights = async () => {
    try {
      const fetchedHighlights = await ButterApi.getWeekHighlights()
      if (fetchedHighlights.length === 0) return onError()

      setHighlights(fetchedHighlights)
    } catch (error) {
      onError()
    }
  }

  useEffect(() => {
    fetchWeekHighlights()
  }, [onError])

  return (
    <HomeCardStyled sx={{ minHeight: 483, justifyContent: 'center' }}>
      {highlights.length > 0 && (
        <>
          <Box display={'flex'} justifyContent={'space-between'}>
            <StyledTypography>Destaques da semana</StyledTypography>

            <Box>
              <StyledButton variant="outlined" size="small">
                VER TODAS
              </StyledButton>
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <Box
              sx={{
                height: 191,
                overflow: 'hidden'
              }}
            >
              <img
                src={highlights[currentIndex].image}
                alt={highlights[currentIndex].title}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </Box>

            <CardContent sx={{ p: 0, height: 80 }}>
              <Typography
                fontSize={theme.designSystem.typography.fontSize.h3}
                fontWeight={theme.designSystem.typography.fontWeight.medium}
                sx={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2
                }}
              >
                {highlights[currentIndex].title}
              </Typography>
            </CardContent>

            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <StyledNavigationButton onClick={handlePrevious} aria-label="previous highlight">
                <IconArrowLeft />
              </StyledNavigationButton>

              <StyledNavigationButton onClick={handleNext} aria-label="next highlight">
                <IconArrowRight />
              </StyledNavigationButton>
            </CardActions>
          </Box>
        </>
      )}
    </HomeCardStyled>
  )
}

export default WeekHighlights
