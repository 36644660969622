import React, { useState } from 'react'
import { InputBox } from '../../InputBox'
import { TypographyBox } from '../../TypographyBox'
import { Button, FormboxContainer, MainBox } from './styles'

interface CpfBoxProps {
  role?: string
  onCpfChange: (cpf: string) => void
}

export const CpfBox = ({ role, onCpfChange }: CpfBoxProps) => {
  const [cpf, setCpf] = useState('')
  const [error, setError] = useState('')

  const validateCpf = (cpfValue: string) => {
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/

    if (!cpfValue) {
      return 'O campo CPF é obrigatório.'
    }

    if (!cpfRegex.test(cpfValue)) {
      return 'Por favor, insira um CPF válido no formato 000.000.000-00.'
    }

    return ''
  }

  const handleCpfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    const formattedCpf = inputValue
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')

    setCpf(formattedCpf)
    setError('')
  }

  const handleNext = () => {
    const validationError = validateCpf(cpf)
    if (validationError) {
      setError(validationError)
      return
    }

    setError('')
    onCpfChange(cpf)
  }

  return (
    <MainBox role={role}>
      <FormboxContainer role="form-box">
        <TypographyBox
          typographyBoxRole="typography-box"
          title="Cadastro de CPF"
          description="Por questões de segurança, precisamos do seu CPF. Ele será utilizado para validar sua
            identidade sempre que necessário."
        />
        <InputBox
          role="input-box"
          title={'Cadastro de CPF'}
          value={cpf}
          onChange={handleCpfChange}
          label={'000.000.000-00'}
          error={!!error}
          helperText={error}
        />
        <Button role="button" onClick={handleNext}>
          Continuar
        </Button>
      </FormboxContainer>
    </MainBox>
  )
}
