import { getInitials } from 'pages/Home/utils/getInitials'
import { Avatar } from 'shared/designSystem/components/Avatar'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { IconChat } from 'shared/designSystem/Icons/IconChat'
export const HomeOptions = () => {
  const userName = 'João Silva'
  const avatarUrl = ''
  return (
    <>
      <IconButton>
        <IconChat role="chat-icon" />
      </IconButton>
      <IconButton>
        <Avatar src={avatarUrl} alt="User avatar">
          {!avatarUrl && getInitials(userName)}
        </Avatar>
      </IconButton>
    </>
  )
}
