import { api } from 'shared/services/Api'
import { CodeVerify } from '../models/CodeVerify'
import { SendCode } from '../models/SendCode'

export const TOKEN_KEY = '@genial-energia'
export class LoginApi {

  static async sendCode(payload: SendCode) {
    return api.post(`/login`, payload)
  }

  static async codeVerify(payload: CodeVerify) {
    return api.post(`/login/token`, payload)
  }
}
