import { create } from 'zustand'
import { StoreProps } from './types'
import { RefObject } from 'react'

export const useReportStore = create<StoreProps>((set) => ({
  state: {
    isOpen: false,
    graphic: 0,
    graphicName: '',
    pdfRef: null,
    filterDefault: false
  },
  actions: {
    openModal: (graphic: number, graphicName: string, isOpen: boolean) => {
      set((store) => ({
        state: { ...store.state, graphic, graphicName, isOpen }
      }))
    },
    closeModal: () => {
      set((store) => ({
        state: { ...store.state, graphic: 0, isOpen: false }
      }))
    },
    setPdfRef: (pdfRef: RefObject<HTMLDivElement>) => {
      set((store) => ({
        state: { ...store.state, pdfRef }
      }))
    },
    setFilterDefault: (isVisible: boolean) => {
      set((store) => ({
        state: { ...store.state, filterDefault: isVisible }
      }))
    }
  }
}))
