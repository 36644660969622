import { StyledButton, StyledTypography } from 'pages/Home/styles'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import IconArrowRight from 'shared/designSystem/Icons/IconArrowRight'
import IconArrowUp from 'shared/designSystem/Icons/IconArrowUp'
import { useTheme } from 'shared/hook/useTheme'

interface Props {
  totalValue: number
  comparisonValue: number
}

export const PaymentTitle = ({ totalValue, comparisonValue }: Props) => {
  const theme = useTheme()

  const formatCurrency = (value: number) => {
    return `R$ ${value.toFixed(2).replace('.', ',')}`
  }

  const noData = totalValue === 0 && comparisonValue === 0

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      paddingRight={3}
      paddingLeft={3}
    >
      <Box>
        <StyledTypography>Para pagar este mês</StyledTypography>

        <Box display="flex" alignItems="center">
          <Typography
            fontSize={theme.designSystem.typography.fontSize.h3}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {formatCurrency(totalValue)}
          </Typography>
        </Box>

        <Box display={'flex'} gap={{ xs: 0.3, sm: 2 }} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box mr={0.5}>{noData ? <IconArrowRight width={10} height={11} /> : <IconArrowUp />}</Box>
            <Typography
              fontSize={theme.designSystem.typography.fontSize.caption}
              fontWeight={theme.designSystem.typography.fontWeight.medium}
              color={noData ? theme.designSystem.base[400] : theme.designSystem.deepOrange[500]}
            >
              {formatCurrency(comparisonValue)}
            </Typography>
          </Box>

          <Typography
            fontSize={theme.designSystem.typography.fontSize.caption}
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            color={theme.designSystem.base[400]}
          >
            comparado ao mês passado
          </Typography>
        </Box>
      </Box>

      <StyledButton
        variant="outlined"
        size="small"
        sx={{
          padding: { xs: `${theme.designSystem.size[3]}px ${theme.designSystem.size[13]}px` },
          textTransform: 'uppercase'
        }}
      >
        Ver mais
      </StyledButton>
    </Box>
  )
}
