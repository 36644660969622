type Props = {
  role?: string
}
export const IconLogoLarge = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width={118}
      height={192}
      viewBox="0 0 118 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={117.041} height={192} fill="white" fillOpacity={0.01} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.3307 0C76.4155 0 72.4219 3.99361 72.4219 8.98562V10.2144C72.4219 15.1296 76.4155 19.2 81.3307 19.2H97.9962V35.9424C97.9962 40.8576 101.99 44.928 106.905 44.928H108.134C113.049 44.928 117.043 40.9344 117.043 35.9424V19.2V9.0624C117.043 4.22399 113.203 0.307182 108.441 0.0767813C108.364 0.0767813 108.287 0.0767813 108.211 0.0767813C108.134 0.0767813 108.134 0.0767813 108.057 0.0767813H97.9195H81.3307V0Z"
        fill="#050922"
        fillOpacity={0.05}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.0385 133.402C71.8081 133.171 71.424 133.095 71.1168 133.248C64.9728 135.936 58.1376 137.396 50.9952 137.396C22.8096 137.396 0 114.586 0 86.477C0 58.3682 22.8096 35.5586 50.9952 35.5586C79.1809 35.5586 101.99 58.3682 101.99 86.477C101.99 98.9954 97.4593 110.439 90.0097 119.271C89.7025 119.655 89.7025 120.192 90.0865 120.499C97.5361 128.103 102.067 137.856 102.067 148.455C102.067 172.34 79.3344 192 51.072 192C22.8096 192 0.0768282 172.416 0.0768282 148.455C0.0768282 147.917 0.537638 147.533 0.998438 147.533H21.4272C21.888 147.533 22.3488 147.917 22.3488 148.455C22.3488 154.676 25.6513 160.282 30.8737 164.276C36.0961 168.269 43.2384 170.727 51.072 170.727C58.9056 170.727 66.1249 168.269 71.2705 164.276C76.4929 160.282 79.7953 154.752 79.7953 148.455C79.6417 142.618 76.7233 137.319 72.0385 133.402ZM50.9185 116.967C67.8145 116.967 81.4848 103.296 81.4848 86.4002C81.4848 69.5042 67.8145 55.8338 50.9185 55.8338C34.0225 55.8338 20.352 69.5042 20.352 86.4002C20.352 103.296 34.0225 116.967 50.9185 116.967Z"
        fill="#050922"
        fillOpacity={0.05}
      />
    </svg>
  )
}
