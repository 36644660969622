import { ActivationStatus } from 'pages/MigrationSteps/models/ActivationStatus'
import { Dialog } from 'shared/designSystem/components/Dialog'
import { Divider } from 'shared/designSystem/components/Divider'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { Unity } from 'shared/models/Unity'
import { theme } from 'shared/theme'
import Info from '../Info'
import {
  CardButton,
  CostumProgress,
  CustomBox,
  CustomDialogContent,
  CustomHeader,
  TitleTypography
} from './styles'
import { InfoList } from '../InfoList'

interface Props {
  role?: string
  open: boolean
  handleClose: () => void
  sideCardData: Unity
  listData: ActivationStatus[]
}

export const SideCard = ({ role, open, handleClose, sideCardData, listData }: Props) => {
  return (
    <CustomBox role={role}>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            position: 'fixed',
            right: 0,
            margin: 1
          }
        }}
      >
        <CustomHeader role="header">
          <IconButton role="close-button" onClick={handleClose}>
            <IconClose
              height={theme.designSystem.size[20]}
              width={theme.designSystem.size[20]}
              color={theme.designSystem.base[900]}
            />
          </IconButton>
          <TitleTypography role="title">{sideCardData.consumerUnitName}</TitleTypography>
          <CardButton role="button" variant="contained">
            {sideCardData.unitTypeName}
          </CardButton>
        </CustomHeader>
        <Divider role="divider" />
        <CustomDialogContent role="dialog-content">
          <Info role="info-component" infoData={sideCardData} />
          <CostumProgress role="progress" value={sideCardData.progress} />
          <InfoList role="infoList-component" infoListData={listData} />
        </CustomDialogContent>
      </Dialog>
    </CustomBox>
  )
}
