import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { getMonthAbbreviations, getMonthFirstLetter, getMonths } from 'shared/utils/getMonths'
import { Box } from 'shared/designSystem/components/Box'
import { theme } from 'shared/theme'
import { Legend } from '../Legend'
import { Header } from './Header'
import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphProps } from 'pages/Reports/types/GraphProps'
import { memo, useRef, useState } from 'react'
import { EmptyData } from '../ConsumeGraph/EmptyData'
import { dataReports } from 'shared/mocks/downloadPdf.json'
import { CustomTooltip } from '../Tooltip'

ChartJS.register(Filler, CategoryScale, LinearScale, LineElement, PointElement, Tooltip)

const CO2Graph = ({ variant }: GraphProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [onHover, setOnHover] = useState(true)
  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
  }>({ x: 0, y: 0, visible: false })

  const hasData = true

  const isModalVariant = variant === 'modal'
  const months = getMonths()

  const treePlanting = dataReports.dataCO2.dataGraphic.treePlanting
  const estimatedTreePlanting = dataReports.dataCO2.dataGraphic.estimatedTreePlanting
  const data = {
    labels: isModalVariant ? getMonthAbbreviations() : getMonthFirstLetter(),
    datasets: [
      {
        label: 'Line Dataset',
        data: treePlanting,
        borderColor: '#90c2e0',
        borderWidth: 2,
        pointBackgroundColor: '#cdf9fd',
        pointBorderColor: '#90c2e0',
        backgroundColor: '#cfeeff',
        fill: onHover
      },
      {
        label: 'Line Dataset',
        data: estimatedTreePlanting,
        borderColor: theme.designSystem.base[200],
        borderWidth: 2,
        pointBackgroundColor: theme.designSystem.base[50],
        pointBorderColor: theme.designSystem.base[200]
      }
    ]
  }

  const prevTooltipData = useRef(tooltipData)

  const options: ChartOptions<'line'> = {
    responsive: true,
    animation: false,
    onHover: (_, chartElement) => {
      const newIndex = chartElement.length ? chartElement[0].index : null
      newIndex !== hoveredIndex && setHoveredIndex(newIndex)
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      },
      x: {
        type: 'category',
        grid: { display: false },
        border: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          const { chart, tooltip } = context

          if (tooltip.opacity === 0) {
            if (tooltipData.visible) {
              setTooltipData((prev) => ({ ...prev, visible: false }))
            }
            return
          }

          const { offsetLeft, offsetTop } = chart.canvas

          if (
            prevTooltipData.current.x !== offsetLeft + tooltip.caretX ||
            prevTooltipData.current.y !== offsetTop + tooltip.caretY ||
            !tooltipData.visible
          ) {
            const newTooltipData = {
              x: offsetLeft + tooltip.caretX,
              y: offsetTop + tooltip.caretY,
              visible: true
            }
            prevTooltipData.current = newTooltipData
            setTooltipData(newTooltipData)
          }
        }
      },
      datalabels: {
        display: false
      }
    }
  }

  const verticalHoverFill = {
    id: 'verticalHoverFill ',
    beforeDatasetDraw(chart: any) {
      const {
        ctx,
        chartArea: { top, bottom, left },
        scales: { x, y }
      } = chart
      ctx.save()

      const activePoint = chart.getDatasetMeta(0).data.find((dataPoint: any) => dataPoint.active)

      if (activePoint) {
        const hoverX = activePoint.x
        ctx.beginPath()
        ctx.moveTo(left, bottom)

        chart.data.datasets[0].data.forEach((value: number, index: number) => {
          const xPosition = x.getPixelForValue(index)
          const yPosition = y.getPixelForValue(value)

          if (xPosition <= hoverX) {
            ctx.lineTo(xPosition, yPosition)
          }
        })
        ctx.lineTo(hoverX, bottom)
        ctx.closePath()
        ctx.fillStyle = theme.designSystem.primary[100]
        ctx.fill()
      }

      chart.getDatasetMeta(0).data.forEach((dataPoint: any) => {
        if (dataPoint.active === true) {
          const x = dataPoint.x
          ctx.beginPath()
          ctx.strokeStyle = theme.designSystem.primary[500]
          ctx.moveTo(x, top)
          ctx.lineTo(x, bottom)
          ctx.stroke()
        }
      })
      ctx.restore()
    }
  }

  return (
    <GraphBox isModalVariant={isModalVariant}>
      <Header hasData={hasData} />

      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant}>
            <Line
              data={data}
              options={options}
              plugins={[verticalHoverFill]}
              onMouseOver={() => setOnHover(false)}
              onMouseLeave={() => setOnHover(true)}
            />
            {tooltipData.visible && (
              <CustomTooltip
                x={tooltipData!.x}
                y={tooltipData!.y}
                visible={tooltipData.visible}
                datas={[
                  {
                    label: `CO₂ poupado em ${months[hoveredIndex!]}`,
                    value: '170 kg'
                  },
                  {
                    label: `Plantio de árvores em ${months[hoveredIndex!]}`,
                    value: treePlanting[hoveredIndex!]
                  },
                  {
                    label: `Plantio de árvores até ${months[hoveredIndex!]}`,
                    value: estimatedTreePlanting[hoveredIndex!]
                  }
                ]}
              />
            )}
          </GraphContainer>

          <Box display={'flex'} flexWrap={'wrap'} gap={1} width={'90%'} sx={{ mt: 2 }}>
            <Legend color={theme.designSystem.primary[500]} text="Plantio de árvores" variant="square" />
            <Legend
              color={theme.designSystem.base[200]}
              text="Plantio estimado de árvores"
              variant="square"
            />
          </Box>
        </>
      ) : (
        <>
          <EmptyData />
          <Box sx={{ mt: 3 }}>
            <Legend color={theme.designSystem.base[200]} text="Não disponível" variant="square" />
          </Box>
        </>
      )}
    </GraphBox>
  )
}

export default memo(CO2Graph)
