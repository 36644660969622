import { jwtDecode } from 'jwt-decode'
import { useEffect, useRef, useState } from 'react'

import { HomeError } from 'pages/Home/components/Error/HomeError'
import { Footer } from 'pages/Home/components/Footer'
import { ConsumeApi } from 'pages/Home/services/ConsumeApi'
import { ContentContainer, ContentWrapper, MainContainer } from 'pages/Home/styles'
import { TOKEN_KEY } from 'pages/SignIn/services/LoginApi'
import { Box } from 'shared/designSystem/components/Box'
import { Grid } from 'shared/designSystem/components/Grid'
import { Loading } from 'shared/designSystem/components/Loading'
import { Header } from 'shared/layout/Header'
import { Sidebar } from 'shared/layout/Sidebar'
import { useReportStore } from 'shared/store/reports'
import { theme } from 'shared/theme'
import { FilterDefault } from './components/FilterDefault'
import { Flag } from './components/Flag'
import { TotalModalDetail } from './components/TotalModalDetail'
import useFilters from 'shared/hook/useFilters'
import { CO2ModalDetail } from './components/CO2ModalDetail'
import { ConsumeModalDetail } from './components/ConsumeModalDetail'
import { EconomyModalDetail } from './components/EconomyModalDetail'
import { EconomyAlertModalDetail } from './components/EconomyAlertModalDetail'
import { pdfElements } from 'shared/mocks/pdfElements'
import { PDFDocument } from './components/PDFDocument'
import { BackgroundLogo } from './components/BackgroundLogo'

export const Reports = () => {
  const {
    state: { graphic, filterDefault },
    actions: { setPdfRef, setFilterDefault }
  } = useReportStore()
  const { filters } = useFilters('filtesAll')
  const [loading, setLoading] = useState(true)
  const [globalError, setGlobalError] = useState(false)

  const reportPDFRef = useRef<any>(null)

  const fetchData = async () => {
    setLoading(true)
    setGlobalError(false)
    try {
      const { id }: any = jwtDecode(sessionStorage.getItem(TOKEN_KEY)!) as { id: string }
      await ConsumeApi.getConsumes(id)
      setLoading(false)
      setGlobalError(false)
    } catch (error: any) {
      //TODO: REMOVER ESSE COMENTARIO QUANDO API ESTIVER FUNCIONANDO
      // setGlobalError(error.response?.status !== 400)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    setPdfRef(reportPDFRef)
    filters !== null ? setFilterDefault(false) : setFilterDefault(true)
    setPdfRef(reportPDFRef)
  }, [])

  return (
    <MainContainer
      bgcolor={theme.designSystem.base[100]}
      sx={{
        position: 'relative'
      }}
    >
      <Box id="hide-in-pdf">
        <Sidebar data-testid="sidebar" />
      </Box>

      <ContentWrapper>
        <Header data-testid="header" fetchData={fetchData} />

        <ContentContainer mt={0.3} data-testid="content-container">
          {loading && (
            <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
              <Grid size={12} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading />
                </Box>
              </Grid>
            </Grid>
          )}

          {filterDefault && <FilterDefault />}

          {globalError && (
            <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
              <Grid size={12} sx={{ height: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <HomeError
                    message="Um erro aconteceu e não foi possível carregar dados dos relatórios. Tente novamente."
                    onRetry={fetchData}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {!loading && !filterDefault && !globalError && (
            <>
              <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
                <Grid size={{ xs: 12, md: 'grow' }} display="flex" flexDirection="column">
                  <Grid container spacing={0.5}>
                    {pdfElements.map(({ Graph, id }: any) => (
                      <Grid
                        key={id}
                        size={{ xs: 12, sm: 12, md: 6 }}
                        minHeight={560}
                        sx={{ flexGrow: 1 }}
                      >
                        <Graph variant="grid" />
                      </Grid>
                    ))}
                    <Grid size={{ xs: 12, sm: 12, md: 6 }} minHeight={560} sx={{ flexGrow: 1 }}>
                      <BackgroundLogo />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid width={'100vw'} mt={0.5}>
                <Footer />
              </Grid>
            </>
          )}
        </ContentContainer>
      </ContentWrapper>

      {graphic === 1 && <ConsumeModalDetail />}
      {graphic === 2 && <EconomyModalDetail />}
      {graphic === 3 && <TotalModalDetail />}
      {graphic === 4 && <EconomyAlertModalDetail />}
      {graphic === 5 && <CO2ModalDetail />}
      <Flag type="green" />

      <Box
        sx={{
          position: 'absolute',
          top: '-9999px'
        }}
        ref={reportPDFRef}
      >
        {pdfElements.map(({ Graph, columns, data, id }: any) => (
          <PDFDocument
            key={id}
            data={data}
            page={id}
            columns={columns}
            numberOfPages={pdfElements.length}
          >
            <Graph variant="modal" />
          </PDFDocument>
        ))}
      </Box>
    </MainContainer>
  )
}
