interface Props {
  height: number
  width: number
  color: string
}

export const IconDone = ({ height, width, color }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 16.2L4.8 12l-1.4 1.4 6 6 12-12-1.4-1.4L9 16.2z" fill={color} />
    </svg>
  )
}
