import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const NoDataBox = styledSystem(Box)(({ theme }) => ({
  margin: 'auto',
  width: '95%',
  height: theme.designSystem.size[309],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  borderTop: `1px solid ${theme.designSystem.base[200]}`,
  borderBottom: `1px solid ${theme.designSystem.base[200]}`,
  padding: theme.designSystem.size[16]
}))

export const StyledButton = styledSystem(Button)(({ theme }) => ({
  width: theme.designSystem.size[32],
  height: theme.designSystem.size[32],
  borderRadius: '50%',
  minWidth: theme.designSystem.size[32],
  minHeight: theme.designSystem.size[32],
  borderColor: theme.palette.divider
}))

export const LegendContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.designSystem.size[12],
  paddingTop: theme.designSystem.size[4],
  paddingRight: theme.designSystem.size[16],
  paddingLeft: theme.designSystem.size[16],
  paddingBottom: theme.designSystem.size[4],
  border: `1px solid ${theme.designSystem.base[200]}`,
  borderRadius: theme.designSystem.size[28],
  width: 'auto',
  maxWidth: 'fit-content'
}))

export const GraphContainer = styledSystem(Box, {
  shouldForwardProp: (prop) => prop !== 'isModalVariant'
})<{ isModalVariant: boolean }>(({ isModalVariant }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: `${isModalVariant ? 1 : 0}`,
  width: '100%',
  height: `${isModalVariant ? '70%' : '300px'}`,
  marginBottom: `${isModalVariant ? '40px' : 0}`,
  marginInline: `${isModalVariant && 'auto'}`,
  position: 'relative'
}))

export const GraphBox = styledSystem(Box, {
  shouldForwardProp: (prop) => prop !== 'isModalVariant'
})<{ isModalVariant: boolean }>(({ theme, isModalVariant }) => ({
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  padding: theme.designSystem.size[32],
  backgroundColor: theme.designSystem.white,
  display: `${!isModalVariant && 'flex'}`,
  flexDirection: 'column'
}))
