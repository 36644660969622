import { ButtonProps, Button as MuiButton } from '@mui/material'

interface Props extends ButtonProps {
  label?: string
  loading?: boolean
}

export const Button = ({ label, loading, children, ...rest }: Props) => {
  return (
    <MuiButton {...rest}>
      {loading ? '...' : label} {children}
    </MuiButton>
  )
}
