import { AppBar } from 'shared/designSystem/components/AppBar'
import { DropDown } from 'shared/designSystem/components/DropDown'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const AppBarCustom = styledSystem(AppBar)(({ theme }) => ({
  height: theme.designSystem.size[80],
  position: 'relative',
  top: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 ${theme.designSystem.size[16]}px`,
  backgroundColor: theme.designSystem.base[50]
}))

export const CustomIconButton = styledSystem(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: theme.designSystem.size[8],
  height: theme.designSystem.size[80],
  width: theme.designSystem.size[80],
  paddingTop: theme.designSystem.size[10],
  backgroundColor: theme.designSystem.base[50]
}))

export const CustomTypography = styledSystem(Typography)(({ theme }) => ({
  color: theme.designSystem.base[900],
  fontSize: theme.designSystem.typography.fontSize.body1,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  marginTop: theme.designSystem.size[28],
  marginRight: theme.designSystem.size[40]
}))

export const CustomDropDown = styledSystem(DropDown)(({ theme }) => ({
  height: theme.designSystem.size[40],
  width: theme.designSystem.size[200],
  marginTop: theme.designSystem.size[32],
  position: 'absolute',
  right: theme.designSystem.size[16],
  transform: 'translateY(-50%)',
  '.MuiInputLabel-root': {
    fontSize: theme.designSystem.typography.fontSize.body2,
    fontWeight: theme.designSystem.typography.fontWeight.bold,
    color: theme.designSystem.base[900]
  },
  '.MuiSelect-root': {
    height: '100%',
    fontSize: theme.designSystem.typography.fontSize.body2
  },
  '.MuiMenuItem-root': {
    fontSize: theme.designSystem.typography.fontSize.body2
  }
}))
