export const formatDate = (item: any) => {
  return new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })
    .format(new Date(item.dueDate))
    .replace(/de /g, '')
    .replace('.', '')
}
