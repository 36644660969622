import { styledSystem } from "shared/designSystem/styles/StyledSystem";
import { Tooltip } from "../Tooltip";

export const StyledTooltip = styledSystem(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    width: theme.designSystem.size[128],
    height: 'auto',
    display: 'flex',
    padding: `${theme.designSystem.size[12]} ${theme.designSystem.size[16]}`,
    flexDirection: 'column',
    gap: theme.designSystem.size[2],
    alignSelf: 'stretch',
    backgroundColor: theme.designSystem.base[50],
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)'
  },
  '& .MuiTooltip-arrow': {
    color: theme.designSystem.base[50],
    '&::before': {
      backgroundColor: theme.designSystem.base[50],
    }
  }
}))
