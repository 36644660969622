import { Box } from "shared/designSystem/components/Box";
import { Button } from "shared/designSystem/components/Button";
import { Divider } from "shared/designSystem/components/Divider";
import { TextField } from "shared/designSystem/components/TextField";
import { styledSystem } from "shared/designSystem/styles/StyledSystem";

export const DividerContainer = styledSystem(Divider)(({ theme }) => ({
  width: 'calc(100% + 48px)',
  marginLeft: '-24px',
  backgroundColor: `${theme.designSystem.blueGray[900]}0D`,
  height: '4px',
}))

export const TextFieldInput = styledSystem(TextField)(({ theme, error }) => ({
  width: '409.2px',
  '& .MuiInputLabel-root': {
    fontWeight: theme.designSystem.typography.fontWeight.medium,
    fontSize: theme.designSystem.size[16],
    color: error ? theme.designSystem.deepOrange[500] : theme.designSystem.base[900],
  },
  '& .MuiInputBase-input': {
    fontSize: theme.designSystem.size[14],
    Width: 3,
    fontWeight: theme.designSystem.typography.fontWeight.regular,
    paddingRight: theme.designSystem.size[58],
  },
  '& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled': {
    color: error ? theme.designSystem.deepOrange[500] : theme.designSystem.base[900],
  },
  '& .MuiFormHelperText-root': {
    color: error ? `${theme.designSystem.deepOrange[500]} !important` : theme.palette.text.primary,
    fontSize: theme.designSystem.size[12],
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid',
    borderColor: error ? theme.designSystem.deepOrange[500] : 'transparent',
    borderImage: error
      ? 'none'
      : `linear-gradient(to right, ${theme.designSystem.base[900]} 0%, ${theme.designSystem.secondary[500]} 100%) 1`,
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid',
    borderColor: error ? theme.designSystem.deepOrange[500] : 'transparent',
    borderImage: error
      ? 'none'
      : `linear-gradient(to right, ${theme.designSystem.base[900]} 0%, ${theme.designSystem.secondary[500]} 100%) 1`,
  },
}))

export const SignInContainer = styledSystem(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: 'calc(100vw - 16px)',
  height: 'calc(100vh - 16px)',
  boxSizing: 'border-box'
})

export const LeftPane = styledSystem(Box)({
  width: '60vw',
  backgroundImage: 'url(/imageLogin.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
})

export const RightPane = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '40vw',
  backgroundColor: theme.designSystem.white,
  padding: '32px',
  boxSizing: 'border-box'
}))

export const TypographyContainer = styledSystem(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  marginTop: '134px',
  marginBottom: '40px'
})

export const FormContainer = styledSystem(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-around'
})

export const TextContainer = styledSystem(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const ButtonContainer = styledSystem(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  position: 'relative',
  marginTop: '40px',
  alignItems: 'center'
})

export const InputCpfAndPassword = styledSystem(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 3,
  marginBottom: '40px'
})

export const SocialIconsContainer = styledSystem(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'start',
  marginTop: '134px',
  gap: '8px'
})

export const ButtonLogin = styledSystem(Button)(({ theme }) => ({
  backgroundColor: theme.designSystem.base[900],
  color: theme.designSystem.white,
  borderRadius: '24px',
  height: '48px',
  width: '113px',
  padding: '0 32px',
  gap: '16px',
  '&:hover': {
    backgroundColor: theme.designSystem.base[800],
    color: theme.designSystem.white
  }
}))

export const InputStyle = styledSystem(TextField)(({ theme, error }) => ({
  '& .MuiInputLabel-root': {
    fontWeight: theme.designSystem.typography.fontWeight.medium,
    fontSize: theme.designSystem.size[16],
    color: error ? theme.designSystem.deepOrange[500] : theme.designSystem.base[900],
  },
  '& .MuiInputBase-input': {
    Width: 3,
    fontWeight: theme.designSystem.typography.fontWeight.regular,
    paddingRight: '35px',
  },
  '& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled': {
    color: error ? theme.designSystem.deepOrange[500] : theme.designSystem.base[900],
  },
  '& .MuiFormHelperText-root': {
    color: error ? `${theme.designSystem.deepOrange[500]} !important` : theme.palette.text.primary,
    fontSize: theme.designSystem.size[12],
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid',
    borderColor: error ? theme.designSystem.deepOrange[500] : 'transparent',
    borderImage: error
      ? 'none'
      : `linear-gradient(to right, ${theme.designSystem.base[900]} 0%, ${theme.designSystem.secondary[500]} 100%) 1`,
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid',
    borderColor: error ? theme.designSystem.deepOrange[500] : 'transparent',
    borderImage: error
      ? 'none'
      : `linear-gradient(to right, ${theme.designSystem.base[900]} 0%, ${theme.designSystem.secondary[500]} 100%) 1`,
  },
}))

export const BoxContainer = styledSystem(Box)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: theme.shadows[3],
  padding: theme.spacing(3),
  width: '95%',
  maxWidth: 456,
  minHeight: 500,
  marginInline: 'auto'
}))

export const ButtonUnderline = styledSystem(Button)(({ theme }) => ({
  color: theme.designSystem.base[900],
  fontSize: theme.designSystem.size[16],
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  textDecoration: 'underline',
  padding: 0,
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));