import { useRef } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { columnsEconomyGraph } from 'shared/mocks/columnsPdf'
import { downloadPdf } from 'shared/utils/downloadPdf'
import { dataReports } from '../../../../shared/mocks/downloadPdf.json'
import { Header } from '../DetailsModal/Header'
import { ModalContainer } from '../DetailsModal/styles'
import EconomyGraph from '../EconomyGraph'
import { PDFDocument } from '../PDFDocument'

export const EconomyModalDetail = () => {
  const pdfRef = useRef(null)
  const pdfElements = [
    {
      graph: <EconomyGraph variant="modal" />,
      data: dataReports.dataEconomy.dataTable,
      columns: columnsEconomyGraph
    }
  ]

  const fetchData = () => {}
  return (
    <>
      <ModalContainer sx={{ overflow: 'hidden' }}>
        <Header
          fetchData={fetchData}
          handlePrint={() => downloadPdf(pdfRef, pdfElements, 'print')}
          handleDownload={() => downloadPdf(pdfRef, pdfElements, 'download')}
        />
        <Box sx={{ display: 'flex', height: '100%' }}>
          <EconomyGraph variant="modal" />
        </Box>
      </ModalContainer>
      <Box
        sx={{
          position: 'absolute',
          top: '-9999px',
          width: '70vw',
          height: '70vh'
        }}
        ref={pdfRef}
      >
        {pdfElements.map((pdf, i) => (
          <PDFDocument key={i} data={pdf.data} columns={pdf.columns}>
            {pdf.graph}
          </PDFDocument>
        ))}
      </Box>
    </>
  )
}
