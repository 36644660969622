import { Box } from 'shared/designSystem/components/Box'
import { CardContent } from 'shared/designSystem/components/CardContent'
import { Typography } from 'shared/designSystem/components/Typography'
import { useTheme } from 'shared/hook/useTheme'
import { CardContentBox, ChipBox } from './styles'

interface Props {
  ucName: string
  name: string
  status: 'Pago' | 'Pendente' | 'Não disponível'
  description: string
  date: string
  value: number
}

export const PaymentInfoCard = ({ ucName, name, status, description, date, value }: Props) => {
  const theme = useTheme()

  const formatCurrency = (value: number) => {
    return `R$ ${value.toFixed(2).replace('.', ',')}`
  }

  return (
    <CardContentBox>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              fontSize={theme.designSystem.typography.fontSize.caption}
              fontWeight={theme.designSystem.typography.fontWeight.regular}
              color={theme.designSystem.base[500]}
            >
              {ucName}
            </Typography>

            <ChipBox status={status} label={status} />
          </Box>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.button}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {name}
          </Typography>
        </Box>

        <Typography
          fontSize={theme.designSystem.typography.fontSize.caption}
          fontWeight={theme.designSystem.typography.fontWeight.regular}
          color={theme.designSystem.base[400]}
        >
          {description}
        </Typography>

        <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.caption}
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            color={theme.designSystem.base[400]}
          >
            {date}
          </Typography>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.button}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {formatCurrency(value)}
          </Typography>
        </Box>
      </CardContent>
    </CardContentBox>
  )
}
