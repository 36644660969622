type Props = {
  role?: string
}

export const IconGenial = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width={10}
      height={14}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={8.53423}
        height={14}
        transform="translate(0.734375)"
        fill="white"
        fillOpacity={0.01}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66522 0C6.30682 0 6.01562 0.291201 6.01562 0.655201V0.7448C6.01562 1.1032 6.30682 1.4 6.66522 1.4L7.88042 1.4V2.6208C7.88042 2.9792 8.17163 3.276 8.53003 3.276H8.61963C8.97803 3.276 9.26923 2.9848 9.26923 2.6208V1.4V0.6608C9.26923 0.308 8.98923 0.0223987 8.64203 0.00559864C8.63643 0.00559864 8.63083 0.00559864 8.62523 0.00559864C8.61963 0.00559864 8.61963 0.00559864 8.61403 0.00559864L7.87482 0.00559864L6.66522 0.00559864V0Z"
        fill="#4AA9FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98718 9.72815C5.97038 9.71135 5.94238 9.70576 5.91998 9.71696C5.47198 9.91296 4.97358 10.0194 4.45278 10.0194C2.39758 10.0194 0.734375 8.35615 0.734375 6.30655C0.734375 4.25695 2.39758 2.59375 4.45278 2.59375C6.50798 2.59375 8.17118 4.25695 8.17118 6.30655C8.17118 7.21935 7.84078 8.05376 7.29758 8.69776C7.27518 8.72576 7.27518 8.76496 7.30318 8.78736C7.84638 9.34176 8.17678 10.053 8.17678 10.8258C8.17678 12.5674 6.51918 14.001 4.45838 14.001C2.39758 14.001 0.739977 12.573 0.739977 10.8258C0.739977 10.7866 0.773578 10.7586 0.807178 10.7586H2.29678C2.33038 10.7586 2.36397 10.7866 2.36397 10.8258C2.36397 11.2794 2.60478 11.6882 2.98558 11.9794C3.36638 12.2706 3.88718 12.4498 4.45838 12.4498C5.02958 12.4498 5.55598 12.2706 5.93118 11.9794C6.31198 11.6882 6.55278 11.285 6.55278 10.8258C6.54158 10.4002 6.32878 10.0138 5.98718 9.72815ZM4.44718 8.52976C5.67918 8.52976 6.67598 7.53295 6.67598 6.30095C6.67598 5.06895 5.67918 4.07215 4.44718 4.07215C3.21518 4.07215 2.21838 5.06895 2.21838 6.30095C2.21838 7.53295 3.21518 8.52976 4.44718 8.52976Z"
        fill="white"
      />
    </svg>
  )
}
