import { api } from 'shared/services/Api'
import { createQueryString } from 'shared/utils/createQueryString'
import { Filters } from '../models/Filters'
import { PaymentResponse } from '../models/PaymentResponse'

export class PaymentApi {
  static async getData(filters: Filters): Promise<PaymentResponse> {
    const response = await api.get(`/invoice/get-invoices-by-user-id?${createQueryString(filters)}`)
    return response.data
  }
}
