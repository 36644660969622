export const IconAlert = () => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={32} height={32} rx={16} fill="#FFC107" />
      <path
        d="M18.7695 20.6659C18.9795 19.4045 19.5101 18.2119 20.2841 17.1725C20.9441 16.2865 21.3348 15.1892 21.3348 13.9992C21.3348 10.8532 18.6108 8.34319 15.3921 8.69986C13.0475 8.95919 11.0968 10.8139 10.7341 13.1445C10.5028 14.6379 10.8921 16.0365 11.6821 17.1239C12.4561 18.1879 13.0088 19.3899 13.2281 20.6659M18.7695 20.6659C18.7041 21.0592 18.6678 21.4592 18.6678 21.8639V21.9992C18.6678 22.7352 18.0711 23.3325 17.3345 23.3325H14.6678C13.9318 23.3325 13.3345 22.7352 13.3345 21.9992V21.8665C13.3345 21.4599 13.2961 21.0599 13.2281 20.6659M18.7695 20.6659H13.2281"
        stroke="#FAFBFF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
