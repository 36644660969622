import { Box as BoxMui } from 'shared/designSystem/components/Box'
import { Typography as TypographyMui } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const StyledTypographyBox = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  paddingTop: theme.designSystem.size[52],
  paddingLeft: theme.designSystem.size[44],
  paddingRight: theme.designSystem.size[44],
  paddingBottom: theme.designSystem.size[20]
}))

export const Title = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.h3,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[800]
}))

export const Description = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body2,
  color: theme.designSystem.base[500],
  marginTop: theme.designSystem.size[16]
}))
