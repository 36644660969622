import { ActivationStatus } from 'pages/MigrationSteps/models/ActivationStatus'
import { Box } from 'shared/designSystem/components/Box'
import { IconDone } from 'shared/designSystem/Icons/IconDone'
import { theme } from 'shared/theme'
import { formatDate } from 'shared/utils/formatDate'
import {
  CustomBox,
  CustomDate,
  CustomEmptyCircle,
  CustomEmptyTitle,
  CustomIconCircle,
  CustomTitle,
  UnityBox
} from './styles'

interface Props {
  role?: string
  infoListData: ActivationStatus[]
}

export const InfoList = ({ role, infoListData }: Props) => {
  return (
    <CustomBox role={role}>
      {infoListData.map((item, index) => (
        <Box role="unity-box" key={index}>
          {item.updatedAt == null && item.createdAt == null ? (
            <UnityBox role="unity-box-empty">
              <CustomEmptyCircle role="empty-circle" />
              <CustomEmptyTitle role="unity-box-empty-title">{item.name}</CustomEmptyTitle>
            </UnityBox>
          ) : (
            <UnityBox role="unity-box-filled">
              <CustomIconCircle role="filled-circle">
                <IconDone
                  height={theme.designSystem.size[16]}
                  width={theme.designSystem.size[16]}
                  color={theme.designSystem.base[50]}
                />
              </CustomIconCircle>
              <CustomTitle role="unity-box-filled-title">{item.name}</CustomTitle>
              <CustomDate role="unity-box-filled-date">
                {item.updatedAt == null ? formatDate(item.createdAt) : formatDate(item.updatedAt)}
              </CustomDate>
            </UnityBox>
          )}
        </Box>
      ))}
    </CustomBox>
  )
}
