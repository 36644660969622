import { Box, Box as BoxMui } from 'shared/designSystem/components/Box'
import { Button as ButtonMui } from 'shared/designSystem/components/Button'
import { TextField } from 'shared/designSystem/components/TextField'
import { Typography as TypographyMui } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainBox = styledSystem(BoxMui)({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'fixed'
})

export const FormboxContainer = styledSystem(BoxMui)(({ theme }) => ({
  height: theme.designSystem.size[550],
  width: theme.designSystem.size[484],
  borderWidth: theme.designSystem.size[5],
  borderStyle: 'solid',
  borderColor: theme.designSystem.base[100],
  marginBottom: theme.designSystem.size[80]
}))

export const TypographyBox = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  paddingTop: theme.designSystem.size[52],
  paddingLeft: theme.designSystem.size[44],
  paddingRight: theme.designSystem.size[44],
  paddingBottom: theme.designSystem.size[44]
}))

export const Title = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.h3,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[800]
}))

export const Description = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body2,
  color: theme.designSystem.base[500],
  marginTop: theme.designSystem.size[16]
}))

export const InputTitle = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[900],
  marginTop: theme.designSystem.size[40]
}))

export const Input = styledSystem(TextField)(({ theme }) => ({
  width: theme.designSystem.size[432],
  '& .MuiInput-underline:before': {
    content: '""',
    backgroundImage: `linear-gradient(to right, ${theme.designSystem.base[900]}, ${theme.designSystem.secondary[500]})`,
    height: theme.designSystem.size[2]
  },
  '& .MuiInput-underline:after': {
    backgroundImage: `linear-gradient(to right, ${theme.designSystem.base[900]}, ${theme.designSystem.secondary[500]})`,
    height: theme.designSystem.size[2]
  }
}))

export const StyledButton = styledSystem(ButtonMui)(({ theme, disabled }) => ({
  variant: 'contained',
  marginLeft: theme.designSystem.size[28],
  backgroundColor: 'transparent',
  color: disabled ? theme.designSystem.base[300] : theme.designSystem.base[900],
  textDecoration: 'underline',
  boxShadow: 'none',
  cursor: disabled ? 'not-allowed' : 'pointer',
  transition: 'none',

  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.designSystem.base[900],
    textDecoration: 'underline',
    boxShadow: 'none'
  },

  '&:focus': {
    outline: 'none',
    boxShadow: 'none'
  },

  '&:disabled': {
    backgroundColor: 'transparent',
    color: theme.designSystem.base[300],
    cursor: 'not-allowed',
    opacity: 1,
    pointerEvents: 'none'
  }
}))

export const StyledCountDown = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[32],
  height: theme.designSystem.size[32],
  paddingTop: theme.designSystem.size[3],
  backgroundColor: theme.designSystem.base[200],
  color: theme.designSystem.base[400],
  borderRadius: '100%',
  textAlign: 'center'
}))

export const CountDownContainer = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.designSystem.size[44],
  marginLeft: theme.designSystem.size[8]
}))

export const Button = styledSystem(ButtonMui)(({ theme }) => ({
  variant: 'contained',
  width: theme.designSystem.size[396],
  height: theme.designSystem.size[48],
  backgroundColor: theme.designSystem.base[900],
  borderRadius: theme.designSystem.size[24],
  color: theme.designSystem.base[50],
  fontSize: theme.designSystem.size[14],
  display: 'flex',
  marginLeft: theme.designSystem.size[44],
  marginTop: theme.designSystem.size[50]
}))
