import { useRef, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconCaretLeft } from 'shared/designSystem/Icons/IconCaretLeft'
import { IconCaretRight } from 'shared/designSystem/Icons/IconCaretRight'
import { columnsTotalGraph } from 'shared/mocks/columnsPdf'
import { theme } from 'shared/theme'
import { downloadPdf } from 'shared/utils/downloadPdf'
import { dataReports } from '../../../../shared/mocks/downloadPdf.json'
import { Header } from '../DetailsModal/Header'
import {
  ButtonExpand,
  DetailsContainer,
  InformationsContainer,
  ModalContainer
} from '../DetailsModal/styles'
import { PDFDocument } from '../PDFDocument'
import TotalGraph from '../TotalGraph'
import { TableWithExpand } from '../DetailsModal/Table'

export const TotalModalDetail = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const pdfRef = useRef(null)

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const pdfElements = [
    {
      graph: <TotalGraph variant="modal" />,
      data: dataReports.dataTotal.dataTable,
      columns: columnsTotalGraph
    }
  ]

  const fetchData = () => {}

  return (
    <>
      <ModalContainer sx={{ overflow: 'hidden' }}>
        <Header
          fetchData={fetchData}
          handlePrint={() => downloadPdf(pdfRef, pdfElements, 'print')}
          handleDownload={() => downloadPdf(pdfRef, pdfElements, 'download')}
        />
        <Box sx={{ display: 'flex', height: '100%' }}>
          <TotalGraph variant="modal" />
          <DetailsContainer sx={{ width: `${isExpanded ? '40%' : '5%'}` }}>
            <ButtonExpand onClick={handleToggleExpand}>
              {isExpanded ? <IconCaretRight /> : <IconCaretLeft />}
            </ButtonExpand>
            {isExpanded &&
              dataReports.dataTotal.dataTableDetais.map((item) => (
                <InformationsContainer>
                  <Typography
                    fontSize={theme.designSystem.typography.fontSize.h3}
                    fontWeight={theme.designSystem.typography.fontWeight.medium}
                    color={theme.designSystem.base[900]}
                    sx={{ mb: theme.designSystem.size[2] }}
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    fontSize={theme.designSystem.typography.fontSize.p}
                    fontWeight={theme.designSystem.typography.fontWeight.normal}
                    color={theme.designSystem.base[400]}
                  >
                    {item.description}
                  </Typography>
                  <Box
                    sx={{
                      border: `1px solid ${theme.designSystem.base[200]}`,
                      mt: theme.designSystem.size[5]
                    }}
                  >
                    {item.data.map(({ label, value, dataExpand }, i) => (
                      <Box key={i}>
                        <TableWithExpand label={label} value={value} dataExpand={dataExpand} />
                      </Box>
                    ))}
                  </Box>
                </InformationsContainer>
              ))}
          </DetailsContainer>
        </Box>
      </ModalContainer>
      <Box
        sx={{
          position: 'absolute',
          top: '-9999px',
          width: '70vw',
          height: '70vh'
        }}
        ref={pdfRef}
      >
        {pdfElements.map((pdf, i) => (
          <PDFDocument key={i} data={pdf.data} columns={pdf.columns}>
            {pdf.graph}
          </PDFDocument>
        ))}
      </Box>
    </>
  )
}
