import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const BoxContainer = styledSystem(Box)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: theme.shadows[3],
  padding: theme.spacing(3),
  width: '95%',
  maxWidth: 456,
  minHeight: 500,
  marginInline: 'auto'
}))