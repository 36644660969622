import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Idata, clearEmptyData, getParamsJson } from './utils';

const useFilters = (storageKey: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<any>(null);
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  useEffect(() => {
    const savedFilters = localStorage.getItem(storageKey);
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      setSearchParams(parsedFilters);
    }
    setFiltersLoaded(true);
  }, []);

  useEffect(() => {
    if (!filtersLoaded) return;
    const filtersFromUrl: any = getParamsJson(searchParams);
    setFilters(filtersFromUrl);
  }, [searchParams, filtersLoaded]);

  const setFilterFunc = (data: object) => {
    const updatedFilters = clearEmptyData({
      ...getParamsJson(searchParams),
      ...data,
    });

    setSearchParams(updatedFilters);
    localStorage.setItem(storageKey, JSON.stringify(updatedFilters));
  };

  const setFilterData = (name: string, value: Idata) => {
    if (!filtersLoaded) return;
    setFilterFunc({ [name]: value });
  };

  const setBulkFilterData = (data: object) => {
    if (!filtersLoaded) return;
    setFilterFunc(data);
  };

  return {
    filters,
    setFilterData,
    setBulkFilterData
  };
};

export default useFilters;