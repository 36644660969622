import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

interface Props {
  color?: string
}

export const HeaderContainerStyled = styledSystem(Box)(({ theme }) => ({
  width: '100%',
  height: theme.designSystem.size[72],
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  backgroundColor: theme.designSystem.white,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1)
  }
}))

export const ReportOptionButton = styledSystem(Button)(({ theme }) => ({
  width: 40,
  height: 40,
  minWidth: 0,
  border: `1px solid ${theme.designSystem.base[100]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 0
}))

export const ProductBadgeContainer = styledSystem(Box)<Props>(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.designSystem.size[12],
  paddingTop: theme.designSystem.size[4],
  paddingRight: theme.designSystem.size[16],
  paddingLeft: theme.designSystem.size[16],
  paddingBottom: theme.designSystem.size[4],
  border: `1px solid ${color || theme.designSystem.base[200]}`,
  borderRadius: theme.designSystem.size[28],
  width: 'auto',
  height: theme.designSystem.size[32],
  maxWidth: 'fit-content',
  textTransform: 'uppercase',
  color: color || '#05092261',
  fontSize: theme.designSystem.typography.fontSize[14],
  fontWeight: theme.designSystem.typography.fontWeight.medium
}))
