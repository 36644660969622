import { Box } from 'shared/designSystem/components/Box'
import { Progress } from 'shared/designSystem/components/Progress'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainContainer = styledSystem(Box)(() => ({
  display: 'flex',
  height: '97.8vh',
  width: 'auto',
  boxSizing: 'border-box'
}))

export const CustomBox = styledSystem(Box)(() => ({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  bottom: 0
}))

export const FooterCustom = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  height: 'auto',
  width: '98.5vw',
  bottom: 0
}))

export const CustomProgress = styledSystem(Progress)(({ theme }) => ({
  height: theme.designSystem.size[10],
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  marginTop: theme.designSystem.size[8],
  marginLeft: theme.designSystem.size[8],
  marginRight: theme.designSystem.size[8],
  backgroundColor: theme.designSystem.base[50],
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.designSystem.primary[500]
  }
}))
