import { Fade } from '@mui/material'
import { BoxContainer } from 'pages/SignIn/styles'
import { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Grid } from 'shared/designSystem/components/Grid'
import { Modal } from 'shared/designSystem/components/Modal'
import { ContainerMessage } from 'shared/designSystem/ContainerMessage'
import { ShareButton } from 'shared/layout/Header/HeaderContainer/modalEmail/ShareButton'

interface Props {
  open: boolean
  onClose: () => void
}

export const RequestAnalysisModal = ({ open, onClose }: Props) => {
  const [showContent, setShowContent] = useState(true)

  const handleCloseModal = () => {
    setShowContent(false)
    setTimeout(() => {
      onClose()
      setShowContent(true)
    }, 300)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Fade in={showContent} timeout={300}>
          <Box height="100vh" mt={20}>
            <BoxContainer>
              <Grid container spacing={2} mt={5}>
                <ContainerMessage
                  margin="45px auto 80px auto"
                  type="success"
                  title="Estudo solicitado!"
                  message="Sua solicitação foi recebida pelo nosso time de especialistas. Aguarde e em breve retornaremos."
                />
                <ShareButton type="button" onClick={handleCloseModal}>
                  Fechar
                </ShareButton>
              </Grid>
            </BoxContainer>
          </Box>
        </Fade>
      </Box>
    </Modal>
  )
}
