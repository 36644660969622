import { useLocation } from 'react-router-dom'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { useTheme } from 'shared/hook/useTheme'
import { HeaderContainer } from 'shared/layout/Header/HeaderContainer'
import { ContainerFilters } from 'shared/layout/Header/HeaderContainer/ContainerFilters'
import { ProductBadge } from 'shared/layout/Header/HeaderContainer/ProductBadge'
import { ReportOptions } from 'shared/layout/Header/HeaderContainer/ReportOptions'
import { TypePropsPageHeader } from 'shared/layout/Header/interface'
import { HeaderMiddleContent } from 'shared/layout/Header/styles'
import { useReportStore } from 'shared/store/reports'
import { ButtonClose } from '../styles'

interface Props extends TypePropsPageHeader {
  handlePrint: () => void
  handleDownload: () => void
}

export const Header = ({ fetchData, handlePrint, handleDownload }: Props) => {
  const theme = useTheme()
  const {
    state: { isOpen, graphicName },
    actions: { closeModal }
  } = useReportStore()
  const location = useLocation()

  return (
    <HeaderContainer>
      <ButtonClose onClick={closeModal}>
        <IconClose />
      </ButtonClose>
      <ContainerFilters fetchData={fetchData} />
      <HeaderMiddleContent>
        <Typography
          fontWeight={theme.designSystem.typography.fontWeight.bold}
          fontSize={{
            xs: theme.designSystem.typography.fontSize.body2,
            sm: theme.designSystem.typography.fontSize.body1
          }}
          color={theme.designSystem.base[900]}
        >
          {isOpen && `Relatório de ${graphicName}`}
        </Typography>
      </HeaderMiddleContent>

      <Box display="flex" alignItems="center"></Box>

      <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
        {location.pathname === '/reports' && <ProductBadge name="Energy" />}
        {location.pathname === '/reports' && (
          <ReportOptions handlePrint={handlePrint} handleDownload={handleDownload} />
        )}
      </Box>
    </HeaderContainer>
  )
}
