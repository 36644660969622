import { api } from 'shared/services/Api'
import { ResetPassword } from '../models/ResetPassword'

export class ResetPasswordApi {

  static async sendSecurityCode(email: string) {
    const payload = { email }
    const response = await api.post(`/SecurityCode/send-with-email`, payload)
    return response.data
  }

  static async resetPassword(payload: ResetPassword): Promise<void> {
    const response = await api.put(`/user/password-recovery`, payload)
    return response.data
  }
}
