import { styledSystem } from "shared/designSystem/styles/StyledSystem"
import { Tooltip } from "../Tooltip"

export const DarkTooltip = styledSystem(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.designSystem.base[800],
    color: theme.designSystem.base[50],
    padding: theme.designSystem.size[8],
    fontSize: theme.designSystem.size[12],
    borderRadius: theme.designSystem.size[8],
    width: theme.designSystem.size[238],
    textAlign: 'center',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    fontWeight: theme.designSystem.typography.fontWeight.regular,
  },
  '& .MuiTooltip-arrow': {
    color: theme.designSystem.base[800],
    '&::before': {
      backgroundColor: theme.designSystem.base[800],
    }
  }
}))