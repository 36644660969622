import FirstAccess from 'pages/FirstAccess'
import MigrationSteps from 'pages/MigrationSteps'
import { Reports } from 'pages/Reports'
import { SignIn } from 'pages/SignIn'
import { ReactElement } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { useAuthStore } from 'shared/store/auth'
import { default as Home } from '../pages/Home'
import NotFound from '../pages/NotFound'

const AppRoutes = () => {
  const {
    state: { isAuthenticated, token }
  } = useAuthStore()

  const PrivateRoute = ({ isLogged }: { isLogged: boolean }): ReactElement => {
    return isLogged ? <Outlet /> : <Navigate to="/" />
  }

  const hasToken = () => token

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />

        <Route element={<PrivateRoute isLogged={isAuthenticated || !!hasToken()} />}>
          <Route path="home" element={<Home />} />
          <Route path="reports" element={<Reports />} />
        </Route>

        <Route path="first-access" element={<FirstAccess />} />
        <Route path="migration-steps" element={<MigrationSteps />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
