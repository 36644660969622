import { Box } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { DialogContent } from 'shared/designSystem/components/Dialog/DialogContent'
import { DialogTitle } from 'shared/designSystem/components/Dialog/DialogTitle'
import { Progress } from 'shared/designSystem/components/Progress'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const CustomBox = styledSystem(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const CustomHeader = styledSystem(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: theme.designSystem.size[65]
}))

export const TitleTypography = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body1,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  marginLeft: theme.designSystem.size[40]
}))

export const CardButton = styledSystem(Button)(({ theme }) => ({
  height: theme.designSystem.size[24],
  width: theme.designSystem.size[44],
  borderRadius: theme.designSystem.size[20],
  marginLeft: 'auto',
  backgroundColor: theme.designSystem.base[50],
  borderStyle: 'solid',
  borderWidth: theme.designSystem.size[2],
  borderColor: theme.designSystem.base[200],
  color: theme.designSystem.base[400],
  fontSize: theme.designSystem.typography.fontSize.caption,
  boxShadow: 'none'
}))

export const CostumProgress = styledSystem(Progress)(({ theme }) => ({
  height: theme.designSystem.size[4],
  width: '100%',
  marginBottom: theme.designSystem.size[45]
}))

export const CustomDialogContent = styledSystem(DialogContent)(({ theme }) => ({
  maxHeight: theme.designSystem.size[686],
  maxWidth: theme.designSystem.size[592],
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden'
}))
