import { Box } from 'shared/designSystem/components/Box'
import { TextField } from 'shared/designSystem/components/TextField'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const InputTitle = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[900],
  marginTop: theme.designSystem.size[40]
}))

export const InputBox = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const Flag = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[20],
  height: theme.designSystem.size[20],
  marginTop: theme.designSystem.size[12]
}))

export const Input = styledSystem(TextField)(({ theme }) => ({
  width: theme.designSystem.size[420],
  marginLeft: theme.designSystem.size[5],
  '& .MuiInputBase-root': {
    border: 'none',
    outline: 'none'
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none'
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none'
  },
  '& .MuiInput-underline:hover:before': {
    borderBottom: 'none'
  },
  '& .MuiInputLabel-root': {
    transition: 'opacity 0.2s ease-in-out'
  },
  '& .MuiInputLabel-root.MuiInputLabel-shrink': {
    opacity: 0
  }
}))

export const InputBottom = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[431],
  height: theme.designSystem.size[2],
  background: `linear-gradient(90deg, ${theme.designSystem.base[900]}, ${theme.designSystem.secondary[500]})`
}))

export const Error = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  marginTop: theme.designSystem.size[8],
  color: theme.designSystem.deepOrange[500]
}))
