import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconArrowDown } from 'shared/designSystem/Icons/IconArrowDown'
import { theme } from 'shared/theme'
import { Pulse } from './components/Pulse'
import { FlagContainer } from './styles'

interface Props {
  type: 'green' | 'yellow' | 'red2' | 'red1'
}

export const Flag = ({ type }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [colors, setColors] = useState<{
    bgColor: string
    color: string
  }>({ bgColor: '', color: '#FFF' })

  useEffect(() => {
    const colorMap: Record<string, { bgColor: string; color: string }> = {
      green: { bgColor: theme.designSystem.green[700], color: theme.designSystem.white },
      yellow: { bgColor: theme.designSystem.attention[500], color: theme.designSystem.brown[500] },
      red2: { bgColor: theme.designSystem.deepOrange[900], color: theme.designSystem.white },
      red1: { bgColor: theme.designSystem.deepOrange[600], color: theme.designSystem.white }
    }

    const colors = colorMap[type]
    if (!colors) return

    setColors(colors)
  }, [type])

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <FlagContainer isExpanded={isExpanded} color={colors.color} backgroundColor={colors.bgColor}>
      {!isExpanded ? (
        <Box sx={{ display: 'flex', width: '100%', position: 'relative' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, height: 48 }}>
            <Box ml={2}>
              <Pulse color={colors.color} />
            </Box>

            <Typography
              fontSize={theme.designSystem.typography.fontSize.body2}
              fontWeight={theme.designSystem.typography.fontWeight.medium}
              ml={2}
              mr={3}
              width={'100%'}
            >
              {type === 'green' && 'Bandeira verde'}
              {type === 'yellow' && 'Bandeira amarela'}
              {type === 'red2' && 'Bandeira vermelha'}
              {type === 'red1' && 'Bandeira vermelha'}
            </Typography>

            <Box
              sx={{
                width: 8,
                height: '100%',
                borderLeft: `1px solid #FAFBFF7A`
              }}
            />
          </Box>

          <IconButton onClick={toggleExpand} sx={{ ml: 0.3 }}>
            <IconArrowDown color={colors.color} />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography
                fontSize={theme.designSystem.typography.fontSize.body1}
                fontWeight={theme.designSystem.typography.fontWeight.medium}
              >
                {type === 'green' && 'Bandeira verde'}
                {type === 'yellow' && 'Bandeira amarela'}
                {type === 'red2' && 'Bandeira vermelha'}
                {type === 'red1' && 'Bandeira vermelha'}
              </Typography>
              {(type === 'red1' || type === 'red2') && (
                <Typography
                  fontSize={theme.designSystem.typography.fontSize.body1}
                  fontWeight={theme.designSystem.typography.fontWeight.regular}
                  color={colors.color}
                >
                  {type === 'red2' ? 'Patamar 2' : 'Patamar 1'}
                </Typography>
              )}
            </Box>

            <Box mt={1} mr={1}>
              <Pulse color={colors.color} />
            </Box>
          </Box>

          <Typography
            fontSize={theme.designSystem.typography.fontSize.button}
            fontWeight={theme.designSystem.typography.fontWeight.regular}
          >
            Condições ainda mais custosas de geração de energia. A tarifa atual é acrescida em{' '}
            <strong>R$ 0,078</strong> para cada quilowatt-hora (kWh)
          </Typography>

          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Typography
                fontSize={theme.designSystem.typography.fontSize.body2}
                fontWeight={theme.designSystem.typography.fontWeight.medium}
              >
                R$ 0.82
              </Typography>

              <Typography
                fontSize={theme.designSystem.typography.fontSize.caption}
                fontWeight={theme.designSystem.typography.fontWeight.regular}
              >
                Valor atual do quilowatt-hora (kWh)
              </Typography>
            </Box>
            <IconButton
              onClick={toggleExpand}
              sx={{ borderRadius: 2, border: `1px solid #FAFBFF29`, padding: 2 }}
            >
              <IconArrowDown color={colors.color} />
            </IconButton>
          </Box>
        </Box>
      )}
    </FlagContainer>
  )
}
