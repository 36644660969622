import {
  ArcElement,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend as LegendChart,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from 'react-chartjs-2'
import { Box } from 'shared/designSystem/components/Box'
import { theme } from 'shared/theme'
import { Legend } from '../Legend'
import { Header } from './Header'
import { dataReports } from 'shared/mocks/downloadPdf.json'
import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphProps } from 'pages/Reports/types/GraphProps'
import { memo } from 'react'
import { EmptyData } from '../ConsumeGraph/EmptyData'
import { formatCurrency } from 'shared/utils/formatCurrency'

ChartJS.register(ArcElement, Tooltip, LegendChart, ChartDataLabels)

const TotalGraph = ({ variant }: GraphProps) => {
  const hasData = true

  const percentages = [
    dataReports.dataTotal.dataGraphic.energy.percentage,
    dataReports.dataTotal.dataGraphic.distribution.percentage,
    dataReports.dataTotal.dataGraphic.energyManagement.percentage,
    dataReports.dataTotal.dataGraphic.devecIcms.percentage,
    dataReports.dataTotal.dataGraphic.ccee.percentage,
    dataReports.dataTotal.dataGraphic.extraReleases.percentage
  ]

  const data: ChartData<'doughnut'> = {
    labels: ['Energia', 'Distribuição', 'Gestão de Energia', 'DEVEC/ICMS', 'CCEE', 'Lançamentos Extras'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [
          dataReports.dataTotal.dataGraphic.energy.cost,
          dataReports.dataTotal.dataGraphic.distribution.cost,
          dataReports.dataTotal.dataGraphic.energyManagement.cost,
          dataReports.dataTotal.dataGraphic.devecIcms.cost,
          dataReports.dataTotal.dataGraphic.ccee.cost,
          dataReports.dataTotal.dataGraphic.extraReleases.cost
        ],
        backgroundColor: [
          theme.designSystem.primary[500],
          theme.designSystem.primary[300],
          theme.designSystem.primary[100],
          theme.designSystem.attention[500],
          theme.designSystem.deepOrange[400],
          theme.designSystem.deepOrange[500]
        ],
        hoverOffset: 4,
        borderWidth: 0
      }
    ]
  }

  const isModalVariant = variant === 'modal'

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        display: false
      }
    },
    cutout: '90%'
  }

  const textCenter = {
    id: 'texteCenter',
    beforeDatasetDraw(chart: any) {
      const { ctx, chartArea } = chart
      const { x, y } = chart.getDatasetMeta(0).data[0]
      ctx.save()
      ctx.clearRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      )
      ctx.font = 'bold 20px sans-serif'
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'

      const activeElements = chart.getActiveElements()
      if (activeElements.length > 0) {
        const activeIndex = activeElements[0].index
        const datasetIndex = activeElements[0].datasetIndex
        const value = chart.data.datasets[datasetIndex].data[activeIndex]
        const label = chart.data.labels?.[activeIndex]

        const { x, y } = chart.getDatasetMeta(0).data[activeIndex]

        ctx.font = `${theme.designSystem.typography.fontWeight['medium']} ${theme.designSystem.typography.fontSize['caption']}px sans-serif`
        ctx.fillStyle = theme.designSystem.base[400]
        ctx.fillText(`${label}`, x, y - 20)
        ctx.fillStyle = theme.designSystem.base[900]
        ctx.font = `${theme.designSystem.typography.fontSize['body1']}px sans-serif`
        ctx.fillText(`${formatCurrency(value)}`, x, y)
        ctx.font = `${theme.designSystem.typography.fontWeight['medium']} ${theme.designSystem.typography.fontSize['caption']}px sans-serif`
        ctx.fillStyle = theme.designSystem.base[400]
        ctx.fillText(`${percentages[activeIndex]}%`, x, y + 20)
        return
      }
      ctx.fillStyle = theme.designSystem.base[400]
      ctx.fillText('100%', x, y)
    }
  }

  return (
    <GraphBox isModalVariant={isModalVariant}>
      <Header hasData={dataReports.dataTotal.dataGraphic.costPerYear} />

      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant}>
            <Doughnut
              data={data}
              options={options}
              style={{ margin: 'auto', height: '100%', width: '50%' }}
              plugins={[textCenter]}
            />
          </GraphContainer>

          <Box display={'flex'} flexWrap={'wrap'} gap={1} width={'90%'} sx={{ mt: 2 }}>
            <Legend color={theme.designSystem.primary[500]} text="Energia" variant="square" />
            <Legend color={theme.designSystem.primary[300]} text="Distribuição" variant="square" />
            <Legend color={theme.designSystem.primary[100]} text="Gestão de Energia" variant="square" />
            <Legend color={theme.designSystem.attention[500]} text="DEVEC/ICMS" variant="square" />
            <Legend color={theme.designSystem.deepOrange[400]} text="CCEE" variant="square" />
            <Legend
              color={theme.designSystem.deepOrange[500]}
              text="Lançamentos extras"
              variant="square"
            />
          </Box>
        </>
      ) : (
        <>
          <EmptyData />
          <Box sx={{ mt: 3 }}>
            <Legend color={theme.designSystem.base[200]} text="Não disponível" variant="square" />
          </Box>
        </>
      )}
    </GraphBox>
  )
}

export default memo(TotalGraph)
