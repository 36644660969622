import { Box, Box as BoxMui } from 'shared/designSystem/components/Box'
import { Button } from 'shared/designSystem/components/Button'
import { TextField } from 'shared/designSystem/components/TextField'
import { Typography as TypographyMui } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainBox = styledSystem(BoxMui)({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'fixed'
})

export const FormboxContainer = styledSystem(BoxMui)(({ theme }) => ({
  height: theme.designSystem.size[550],
  width: theme.designSystem.size[484],
  borderWidth: theme.designSystem.size[5],
  borderStyle: 'solid',
  borderColor: theme.designSystem.base[100],
  marginBottom: theme.designSystem.size[80]
}))

export const TypographyBox = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  paddingTop: theme.designSystem.size[52],
  paddingLeft: theme.designSystem.size[44],
  paddingRight: theme.designSystem.size[44],
  paddingBottom: theme.designSystem.size[44]
}))

export const Title = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.h3,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[800]
}))

export const Description = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body2,
  color: theme.designSystem.base[500],
  marginTop: theme.designSystem.size[16]
}))

export const InputContainer = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.designSystem.size[50],
  marginBottom: theme.designSystem.size[25]
}))

export const CountDownContainer = styledSystem(BoxMui)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const InputBase = styledSystem(TextField)(({ theme }) => ({
  width: theme.designSystem.size[44],
  height: theme.designSystem.size[56],
  textAlign: 'center',
  marginRight: theme.designSystem.size[12],

  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    borderWidth: theme.designSystem.size[1.5],
    borderStyle: 'solid',
    borderColor: theme.designSystem.base[200],

    '& fieldset': {
      borderColor: theme.designSystem.base[200],
      borderWidth: theme.designSystem.size[1.5],
      borderRadius: 0
    },

    '&:hover fieldset': {
      borderColor: theme.designSystem.base[200]
    },

    '&.Mui-focused fieldset': {
      borderColor: theme.designSystem.base[900]
    }
  },

  '& input': {
    textAlign: 'center',
    color: theme.designSystem.base[900],
    fontSize: theme.designSystem.typography.fontSize.h3,
    fontWeight: theme.designSystem.typography.fontWeight.bold
  }
}))

export const StyledButton = styledSystem(Button)(({ theme, disabled }) => ({
  variant: 'contained',
  marginLeft: theme.designSystem.size[28],
  backgroundColor: 'transparent',
  color: disabled ? theme.designSystem.base[300] : theme.designSystem.base[900],
  textDecoration: 'underline',
  boxShadow: 'none',
  cursor: disabled ? 'not-allowed' : 'pointer',
  transition: 'none',

  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.designSystem.base[900],
    textDecoration: 'underline',
    boxShadow: 'none'
  },

  '&:focus': {
    outline: 'none',
    boxShadow: 'none'
  },

  '&:disabled': {
    backgroundColor: 'transparent',
    color: theme.designSystem.base[300],
    cursor: 'not-allowed',
    opacity: 1,
    pointerEvents: 'none'
  }
}))

export const StyledCountDown = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[32],
  height: theme.designSystem.size[32],
  paddingTop: theme.designSystem.size[3],
  backgroundColor: theme.designSystem.base[200],
  color: theme.designSystem.base[400],
  borderRadius: '100%',
  textAlign: 'center'
}))

export const ErrorMessage = styledSystem(Box)(({ theme }) => ({
  color: theme.designSystem.deepOrange[400],
  marginTop: theme.designSystem.size[8],
  fontSize: theme.designSystem.typography.fontSize.caption
}))
