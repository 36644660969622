import { StyledTypography } from 'pages/Home/styles'
import { StyledButton } from 'pages/Reports/styles'
import { Box } from 'shared/designSystem/components/Box'
import { InfoTooltip } from 'shared/designSystem/components/InfoTooltip'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconExpand } from 'shared/designSystem/Icons/IconExpand'
import IconInfo from 'shared/designSystem/Icons/IconInfo'
import { useTheme } from 'shared/hook/useTheme'
import { useReportStore } from 'shared/store/reports'
import { dataReports } from 'shared/mocks/downloadPdf.json'

interface Props {
  hasData: boolean
}

export const Header = ({ hasData }: Props) => {
  const {
    state: { isOpen },
    actions: { openModal }
  } = useReportStore()
  const theme = useTheme()
  const longText = `Atualizado conforme medições da distribuidora para todas as unidades`
  const averageConsumptionByYear = dataReports.dataCO2.dataGraphic.averageConsumptionByYear
  const treeEquivalence = dataReports.dataCO2.dataGraphic.treeEquivalence
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: `${isOpen ? 'start' : 'space-between'}`,
        alignItems: 'flex-start',
        marginBottom: theme.designSystem.size[2],
        gap: 8
      }}
    >
      <Box>
        <StyledTypography>CO₂ poupado no ano</StyledTypography>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.h3}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {hasData ? averageConsumptionByYear : '0'} kg
          </Typography>

          <InfoTooltip title={longText}>
            <Box display="inline-flex">
              <IconInfo />
            </Box>
          </InfoTooltip>
        </Box>
      </Box>

      <Box>
        <StyledTypography>Equivalência em árvores</StyledTypography>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.h3}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {hasData ? treeEquivalence : '0'}
          </Typography>

          <InfoTooltip title={longText}>
            <Box display="inline-flex">
              <IconInfo />
            </Box>
          </InfoTooltip>
        </Box>
      </Box>

      {!isOpen && (
        <StyledButton
          variant="outlined"
          size="small"
          onClick={() => openModal(5, 'Sustentabilidade', true)}
        >
          <IconExpand />
        </StyledButton>
      )}
    </Box>
  )
}
