import { Box } from 'shared/designSystem/components/Box'
import { IconLogoLarge } from 'shared/designSystem/Icons/IconLogoLarge'

export const BackgroundLogo = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <IconLogoLarge />
    </Box>
  )
}
