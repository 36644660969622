import { theme } from 'shared/theme'
import { StyledTooltip } from './styles'

interface GraphTooltipProps {
  title: React.ReactNode
  children: React.ReactNode
  width?: number | string
  padding?: string
  backgroundColor?: string
  value?: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

export const GraphTooltip = ({
  title,
  children,
  width,
  padding,
  backgroundColor,
  value,
  placement = 'top',
  ...props
}: GraphTooltipProps) => {
  return (
    <StyledTooltip
      {...props}
      title={title}
      arrow
      placement={placement}
      sx={{
        '& .MuiTooltip-tooltip': {
          width: width || 128,
          padding: padding || `${theme.designSystem.size[12]} ${theme.designSystem.size[16]}`,
          backgroundColor: backgroundColor || `${theme.designSystem.base[50]}`
        }
      }}
    >
      {children}
    </StyledTooltip>
  )
}
