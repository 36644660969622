import { UserApi } from 'pages/FirstAccess/services/User'
import React, { useEffect, useState } from 'react'
import { ErrorHandler } from 'shared/utils/ErrorHandler'
import { InputBox } from '../InputBox'
import {
  CountDownContainer,
  Description,
  FormboxContainer,
  MainBox,
  StyledButton,
  StyledCountDown,
  Title,
  TypographyBox
} from './styles'

interface Props {
  role?: string
  email: string
  onSuccess: (isValid: boolean) => void
}

export const SmsBox = ({ role, email, onSuccess }: Props) => {
  const [smsCode, setSmsCode] = useState('')
  const [error, setError] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [countdown, setCountdown] = useState(60)

  const handleSmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value

    inputValue = inputValue.replace(/\D/g, '')

    if (inputValue.length > 6) {
      inputValue = inputValue.slice(0, 6)
    }

    const formattedSmsCode = inputValue.replace(/(\d{3})(\d{1,3})/, '$1 $2')
    setSmsCode(formattedSmsCode)
    setError('')
  }

  const handleFailToken = (error: string) => {
    error == 'Expired Token.'
      ? setError('Token expirado, por favor entre em contato com nosso atendimento')
      : setError('Token inválido, por favor tente novamente')

    if (!isButtonDisabled) {
      setIsButtonDisabled(true)
      setCountdown(60)
    }
  }

  const verifySms = async () => {
    if (smsCode.length < 6) {
      setError('Informe um código válido')
    }
    const inputSms = smsCode.replace(/\s/g, '')

    try {
      await UserApi.validateSecurityCode({ email: email, code: inputSms })
      onSuccess(true)
    } catch (error) {
      const errorMessage = ErrorHandler.getErrorMessage(error)
      handleFailToken(errorMessage)
    }
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined

    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1)
      }, 1000)
    }
    if (countdown === 0) setIsButtonDisabled(false)

    return () => {
      if (timer) clearInterval(timer)
    }
  }, [isButtonDisabled, countdown])

  return (
    <MainBox role={role}>
      <FormboxContainer role="form-box">
        <TypographyBox role={'typography-box'}>
          <Title>Verificação de celular</Title>
          <Description>
            Certo, agora precisamos validar o celular que você informou no passo anterior. Para
            continuar, informe o código de segurança enviado por SMS.
          </Description>
        </TypographyBox>
        <InputBox
          role={'input-box'}
          title={'Código de segurança'}
          value={smsCode}
          onChange={handleSmsChange}
          label={'XXX XXX'}
          error={!!error}
          helperText={error}
        />
        <CountDownContainer>
          <StyledButton role="button" onClick={verifySms} disabled={isButtonDisabled}>
            {isButtonDisabled ? `Reenviar código` : 'Enviar código'}
          </StyledButton>
          {isButtonDisabled && <StyledCountDown role="countdown">{countdown}</StyledCountDown>}
        </CountDownContainer>
      </FormboxContainer>
    </MainBox>
  )
}
