import { Box as LoadingMui, styled } from '@mui/material';
import StyledRiveLoading from '@rive-app/react-canvas';

export const LoadingCustom = styled(LoadingMui)(() => ({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'fixed',
}));

export const RiveLoading = styled(StyledRiveLoading)(() => ({
  width: '32.14vw',
  height: '31.02vh',
}));