import { Box, Button, Typography, styled } from '@mui/material'

export const StyledErrorContainer = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'fixed'
}))

export const StyledContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  gap: theme.designSystem.size[24],
  height: theme.designSystem.size[610],
  width: theme.designSystem.size[1352]
}))

export const StyledIconContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  width: theme.designSystem.size[304],
  height: theme.designSystem.size[279],
  gap: theme.designSystem.size[32]
}))

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.body1,
  fontWeight: theme.designSystem.typography.fontWeight.medium,
  color: theme.designSystem.base[900],
  marginTop: theme.designSystem.size[24],
  marginBottom: theme.designSystem.size[12]
}))

export const StyledMessageTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.designSystem.typography.fontWeight.regular,
  fontSize: theme.designSystem.typography.fontSize.body2,
  color: theme.designSystem.base[400],
  marginBottom: theme.designSystem.size[32]
}))

export const StyledRetryButton = styled(Button)(({ theme }) => ({
  variant: 'contained',
  width: theme.designSystem.size[191],
  height: theme.designSystem.size[48],
  backgroundColor: theme.designSystem.base[900],
  color: theme.designSystem.base[50],
  borderRadius: theme.designSystem.size[24],
  paddingRight: theme.designSystem.size[32],
  paddingLeft: theme.designSystem.size[32],
  gap: theme.designSystem.size[16]
}))
