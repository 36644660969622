import { Box } from 'shared/designSystem/components/Box'
import { GraphTooltip } from 'shared/designSystem/components/GraphTooltip'
import { Typography } from 'shared/designSystem/components/Typography'
import { useConsumitionStore } from 'shared/store/consumition'
import { theme } from 'shared/theme'

type ThemeType = typeof theme

type DisplayColorProps = {
  isHighlighted: boolean
  highlightedIndex: number | null
  barColor: string
  theme: ThemeType
}

interface Props {
  highlightedIndex: number | null
  setHighlightedIndex: (index: number | null) => void
}

export const DataBars = ({ highlightedIndex, setHighlightedIndex }: Props) => {
  const {
    state: { unit, filteredConsumes }
  } = useConsumitionStore()

  const currentMonth = new Date().getMonth()
  const months = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']

  const getBarColor = (index: number, currentMonth: number, theme: ThemeType): string => {
    return index <= currentMonth ? theme.designSystem.primary[500] : theme.designSystem.primary[200]
  }

  const getDisplayColor = ({
    isHighlighted,
    highlightedIndex,
    barColor,
    theme
  }: DisplayColorProps): string => {
    if (isHighlighted) return theme.designSystem.primary[500]
    if (highlightedIndex !== null) return theme.designSystem.base[200]
    return barColor
  }

  const getBarHeight = (energy: number | null): number => {
    return energy ? Math.min(energy * 4, 143) : 5
  }

  return (
    <Box
      width="100%"
      sx={{
        height: '100%',
        overflowX: 'hidden'
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        gap={theme.designSystem.size[1]}
        height={theme.designSystem.size[200]}
        alignItems="flex-end"
        maxWidth="100%"
        key={unit?.installationNumber}
      >
        {filteredConsumes.map((data, index) => {
          const isHighlighted = highlightedIndex === index
          const barHeight = getBarHeight(data?.energy ?? null)
          const barColor = getBarColor(index, currentMonth, theme)
          const displayColor = getDisplayColor({
            isHighlighted,
            highlightedIndex,
            barColor,
            theme
          })
          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              key={data?.consumptionId}
              width={`${100 / 12}%`}
              maxWidth="calc(100% / 12)"
            >
              <GraphTooltip
                title={
                  data ? (
                    <>
                      <Typography
                        fontWeight={theme.designSystem.typography.fontWeight.regular}
                        fontSize={theme.designSystem.typography.fontSize.caption}
                        color={theme.designSystem.base[300]}
                      >
                        Consumo Total
                      </Typography>
                      <Typography
                        fontWeight={theme.designSystem.typography.fontWeight.medium}
                        color={theme.designSystem.base[900]}
                        fontSize={theme.designSystem.typography.fontSize.button}
                      >
                        {data.energy} KWh
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      fontWeight={theme.designSystem.typography.fontWeight.regular}
                      fontSize={theme.designSystem.typography.fontSize.caption}
                      color={theme.designSystem.base[300]}
                    >
                      Sem dados
                    </Typography>
                  )
                }
                placement="top"
              >
                <Box
                  bgcolor={displayColor}
                  width="100%"
                  height={`${barHeight}px`}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  onMouseLeave={() => setHighlightedIndex(null)}
                />
              </GraphTooltip>
              <Typography variant="caption" color="text.secondary" mt={1}>
                {months[index]}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
