import { createTheme } from '@mui/material'
import { ligth } from './ligth'

export const theme = createTheme({
  typography: {
    fontFamily: ligth.typography.fontFamily
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          border: `8px solid ${ligth.base[900]}`,
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
          height: '100vh',
          overflow: 'hidden'
        }
      }
    }
  },
  designSystem: ligth
})
