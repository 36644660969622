import { api } from 'shared/services/Api'
import { createQueryString } from 'shared/utils/createQueryString'

export type filters = {
  userId: string, group: string, unit: string, product: number
}

export class ConsumeApi {
  static async getConsumes(filters: filters) {
    const currentYear = new Date().getFullYear()
    const response = await api.get(
      `/consumption/get-comsumer-by-year-and-user-id?Year=${currentYear}&${createQueryString(filters)}`
    )
    return response.data.data
  }
}
