import { IconFacebook } from 'shared/designSystem/components/Icons/Social/FacebookIconButton';
import { IconInstagram } from 'shared/designSystem/components/Icons/Social/InstagramIconButton';
import { IconLinkedIn } from 'shared/designSystem/components/Icons/Social/LinkedinIconButton';
import { IconYouTube } from 'shared/designSystem/components/Icons/Social/YouTubeIconButton';

const socialIcons: { id: string; Component: React.ComponentType<any>; href: string }[] = [
  { id: 'facebook', Component: IconFacebook, href: 'https://www.facebook.com' },
  { id: 'instagram', Component: IconInstagram, href: 'https://www.instagram.com' },
  { id: 'youtube', Component: IconYouTube, href: 'https://www.youtube.com' },
  { id: 'linkedin', Component: IconLinkedIn, href: 'https://www.linkedin.com' }
];

export const SocialIconsLogin = () => {
  const handleClick = (href: string) => {
    window.open(href, '_blank');
  };

  return (
    <>
      {socialIcons.map(({ id, Component, href }) => (
        <Component
          key={id}
          href={href}
          onClick={() => handleClick(href)}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              handleClick(href);
            }
          }}
          tabIndex={0}
          aria-label={`Ir para ${id}`}
        />
      ))}
    </>
  );
};
