import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const EmptyBarsComponent = styledSystem(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingTop: theme.designSystem.size[32],
  backgroundColor: theme.designSystem.white
}))

export const BarContainer = styledSystem(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end'
}))

export const Bar = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[24],
  height: theme.designSystem.size[4],
  bgcolor: theme.designSystem.base[100]
}))

export const MonthsContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.designSystem.size[1]
}))

export const MonthText = styledSystem(Typography)(({ theme }) => ({
  color: theme.designSystem.base[200],
  width: theme.designSystem.size[24],
  textAlign: 'center'
}))

export const InfoContainer = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.designSystem.size[4],
  color: '#050922'
}))

export const InfoItem = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 1
}))

export const Icon = styledSystem(Box)(({ theme }) => ({
  width: theme.designSystem.size[12],
  height: theme.designSystem.size[12],
  bgcolor: theme.designSystem.base[100]
}))

export const LabelText = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.size[12],
  fontWeight: theme.designSystem.typography.fontWeight.medium
}))
