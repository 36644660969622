import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { theme } from 'shared/theme'

interface Props {
  backgroundColor?: string
  isExpanded: boolean
}

export const FlagContainer = styledSystem(Box, {
  shouldForwardProp: (prop) => prop !== 'isExpanded'
})<Props>(({ backgroundColor, isExpanded }) => ({
  position: 'fixed',
  bottom: theme.designSystem.size[18],
  right: theme.designSystem.size[22],
  backgroundColor: backgroundColor || theme.designSystem.deepOrange[900],
  borderRadius: theme.designSystem.size[4],
  transition: 'height 0.3s ease, width 0.3s ease',
  width: isExpanded ? theme.designSystem.size[369] : theme.designSystem.size[255],
  height: isExpanded ? theme.designSystem.size[271] : theme.designSystem.size[48],
  display: 'flex',
  alignItems: 'center',
  padding: isExpanded ? theme.designSystem.size[24] : theme.designSystem.size[8]
}))
