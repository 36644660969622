import { StyledTypography } from 'pages/Home/styles'
import { StyledButton } from 'pages/Reports/styles'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconAlert } from 'shared/designSystem/Icons/IconAlert'
import { IconExpand } from 'shared/designSystem/Icons/IconExpand'
import { useTheme } from 'shared/hook/useTheme'
import { useReportStore } from 'shared/store/reports'

export const Header = () => {
  const {
    state: { isOpen },
    actions: { openModal }
  } = useReportStore()

  const theme = useTheme()
  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
      <Box display="flex">
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mr={2}>
          <IconAlert />
        </Box>
        <Box sx={{ flexDirection: 'column', display: 'flex', gap: 0.5 }}>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.h5}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            Alerta de economia
          </Typography>

          <StyledTypography>
            Identificamos uma possível oportunidade de ajuste na sua demanda
          </StyledTypography>
        </Box>
      </Box>

      {!isOpen && (
        <StyledButton variant="outlined" size="small" onClick={() => openModal(4, 'Demanda', true)}>
          <IconExpand />
        </StyledButton>
      )}
    </Box>
  )
}
