import { Typography } from 'shared/designSystem/components/Typography'
import { IconCheck } from 'shared/designSystem/Icons/IconCheck'
import { useTheme } from 'shared/hook/useTheme'
import { BoxContent, BoxIcon } from './styles'
import { IconClose } from '../Icons/IconClose'

type Props = {
  type: string
  title: string
  message: string
  margin?: string
}

export const ContainerMessage = ({ type, title, message, margin }: Props) => {
  const theme = useTheme()
  return (
    <BoxContent sx={{ margin: margin }}>
      <BoxIcon sx={{ backgroundColor: type === 'error' ? '#D04B24' : '#8BC34A' }}>
        {type === 'error' ? <IconClose width={24} height={24} /> : <IconCheck width={24} height={24} />}
      </BoxIcon>

      <Typography
        fontWeight={theme.designSystem.typography.fontWeight.medium}
        fontSize={theme.designSystem.typography.fontSize.body1}
        color={theme.designSystem.base[900]}
      >
        {title}
      </Typography>
      <Typography
        fontWeight={theme.designSystem.typography.fontWeight.regular}
        fontSize={theme.designSystem.typography.fontSize.body2}
        color={theme.designSystem.base[500]}
        sx={{
          textAlign: 'center'
        }}
      >
        {message}
      </Typography>
    </BoxContent>
  )
}
