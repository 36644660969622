type Props = {
  role?: string
}

export const IconPrinter = ({ role }: Props) => {

  return (
    <svg
      role={role}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33464 9.99935V0.666016H12.668V9.99935M6.0013 3.99935H10.0013M6.0013 6.66602H10.0013M14.0013 15.3327H2.0013C1.2653 15.3327 0.667969 14.7353 0.667969 13.9993V11.3327C0.667969 10.5967 1.2653 9.99935 2.0013 9.99935H14.0013C14.7373 9.99935 15.3346 10.5967 15.3346 11.3327V13.9993C15.3346 14.7353 14.7373 15.3327 14.0013 15.3327Z"
        stroke="#050922"
        strokeOpacity={0.6}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
