export class ErrorHandler {
  static getErrorMessage(error: any): string {
    const responseErrors = error?.response?.data?.errors
    const errorTitle = error?.response?.data?.title
    const errorMessage = error?.response?.data?.message || 'Erro desconhecido'

    if (responseErrors && responseErrors.length > 0) {
      return responseErrors[0]?.message || errorMessage
    }
    return errorTitle ? `Unexpected error: ${errorTitle}` : errorMessage
  }
}
