import CO2Graph from 'pages/Reports/components/CO2Graph'
import ConsumeGraph from 'pages/Reports/components/ConsumeGraph'
import EconomyAlertGraph from 'pages/Reports/components/EconomyAlertGraph'
import EconomyGraph from 'pages/Reports/components/EconomyGraph'
import TotalGraph from 'pages/Reports/components/TotalGraph'
import { dataReports } from 'shared/mocks/downloadPdf.json'
import {
  columnsCO2Graph,
  columnsConsumeGraph,
  columnsEconomyAlertGraph,
  columnsEconomyGraph,
  columnsTotalGraph
} from './columnsPdf'

export const pdfElements = [
  {
    id: 1,
    Graph: ConsumeGraph,
    data: dataReports.dataConsumer.dataTable,
    columns: columnsConsumeGraph
  },
  {
    id: 2,
    Graph: EconomyGraph,
    data: dataReports.dataEconomy.dataTable,
    columns: columnsEconomyGraph
  },
  {
    id: 3,
    Graph: TotalGraph,
    data: dataReports.dataTotal.dataTable,
    columns: columnsTotalGraph
  },
  {
    id: 4,
    Graph: EconomyAlertGraph,
    data: dataReports.dataEconomyAlert.dataTable,
    columns: columnsEconomyAlertGraph
  },
  { id: 5, Graph: CO2Graph, data: dataReports.dataCO2.dataTable, columns: columnsCO2Graph }
]
