import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'
import { AccordionOption } from '../styles'
import { AccordionSummary } from 'shared/designSystem/components/AccordionSumary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionDetails } from '@mui/material'

interface Props {
  dataExpand: {
    label: string
    value: string
  }[]
  label: string
  value: string
}

export const TableWithExpand = ({ dataExpand, label, value }: Props) => {
  return (
    <AccordionOption disableGutters>
      <AccordionSummary
        sx={{
          borderBottom: `1px solid ${theme.designSystem.base[200]}`
        }}
        expandIcon={dataExpand?.length ? <ExpandMoreIcon /> : false}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            fontSize={theme.designSystem.typography.fontSize.p}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[400]}
          >
            {label}
          </Typography>
          <Typography
            fontSize={theme.designSystem.typography.fontSize.p}
            fontWeight={theme.designSystem.typography.fontWeight.medium}
            color={theme.designSystem.base[900]}
          >
            {value}
          </Typography>
        </Box>
      </AccordionSummary>
      {!!dataExpand?.length && (
        <AccordionDetails sx={{ borderBottom: `1px solid ${theme.designSystem.base[200]}` }}>
          {dataExpand.map(({ label, value }: any, index: number) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBlock: 2
              }}
            >
              <Typography
                fontSize={theme.designSystem.typography.fontSize.p}
                fontWeight={theme.designSystem.typography.fontWeight.medium}
                color={theme.designSystem.base[400]}
              >
                {label}
              </Typography>
              <Typography
                fontSize={theme.designSystem.typography.fontSize.p}
                fontWeight={theme.designSystem.typography.fontWeight.medium}
                color={theme.designSystem.base[400]}
              >
                {value}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      )}
    </AccordionOption>
  )
}
