import { ChangeEventHandler, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { IconVisibility, IconVisibilityOff } from 'shared/designSystem/Icons/IconVisibility'
import { Error, Flag, Input, InputBottom, InputBox, InputTitle } from './styles'

interface AdornmentInputProps {
  role?: string
  flag?: string
  showIconButton?: boolean
  helperText?: string
  inputTitle: string
  value: string | number
  label?: string
  error: boolean
  onChange: ChangeEventHandler
  onClick?: React.MouseEventHandler
}

export const AdornmentInput = ({
  role,
  flag,
  showIconButton = false,
  inputTitle,
  value,
  label,
  error,
  helperText,
  onChange
}: AdornmentInputProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    if (showIconButton) setShowPassword((prev) => !prev)
  }

  const inputType = showIconButton ? (showPassword ? 'text' : 'password') : 'text'

  return (
    <Box role={role}>
      <InputTitle role="title">{inputTitle}</InputTitle>
      <InputBox role="input-box">
        <Flag role="flag">{flag && <img src={flag} alt="Flag" />}</Flag>
        <Input
          role="input"
          type={inputType}
          variant="standard"
          value={value}
          onChange={onChange}
          label={label}
          error={error}
        />
        {showIconButton && (
          <IconButton role="icon-button" onClick={handleTogglePasswordVisibility} edge="end">
            {showPassword ? <IconVisibility /> : <IconVisibilityOff />}
          </IconButton>
        )}
      </InputBox>
      <InputBottom role="input-bottom" />
      {error && helperText && <Error role="error">{helperText}</Error>}
    </Box>
  )
}
