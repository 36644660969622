import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { Box } from 'shared/designSystem/components/Box'
import { DotsLoader } from 'shared/designSystem/components/DotsLoader'
import { LogoutTooltip } from 'shared/designSystem/components/LogoutTooltip'
import { Tab } from 'shared/designSystem/components/Tab'
import { Tabs } from 'shared/designSystem/components/Tabs'
import { IconChartPie } from 'shared/designSystem/Icons/IconChartPie'
import { IconExit } from 'shared/designSystem/Icons/IconExit'
import { IconHome } from 'shared/designSystem/Icons/IconHome'
import { Logo } from 'shared/designSystem/Icons/IconLogo'
import { IconMoney } from 'shared/designSystem/Icons/IconMoney'
import { IconPageAlt } from 'shared/designSystem/Icons/IconPageAlt'
import { IconPlay } from 'shared/designSystem/Icons/IconPlay'
import { useAuthStore } from 'shared/store/auth'
import { theme } from 'shared/theme'
import { SideContainer } from './SideContainer'

export const Sidebar = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { actions } = useAuthStore()
  const location = useLocation()
  const navigate = useNavigate()

  const tabs = [
    { label: <IconHome role="home" />, path: '/home' },
    { label: <IconMoney role="money" />, path: '/finances' },
    { label: <IconChartPie role="chart-pie" />, path: '/reports' },
    { label: <IconPageAlt role="page-alt" />, path: '/docs' },
    { label: <IconPlay role="play" />, path: '/tutorials' },
  ]

  const activeColor = theme.designSystem.base[50]
  const inactiveColor = theme.designSystem.base[400]

  const handleLogout = async () => {
    setLoading(true)
    try {
      await actions.loggout()
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SideContainer>
      <Box>
        <Logo role="img" />
      </Box>

      <Tabs
        value={tabs.findIndex((tab) => tab.path === location.pathname)}
        onChange={(_, newIndex) => navigate(tabs[newIndex].path)}
      >
        {tabs.map((tab, index) => {
          const iconColor = location.pathname === tab.path ? activeColor : inactiveColor

          return (
            <Tab
              key={index}
              label={
                <Box
                  style={{
                    color: iconColor,
                  }}
                >
                  {tab.label && React.cloneElement(tab.label, { strokeColor: iconColor })}
                </Box>
              }
            />
          )
        })}
      </Tabs>

      <LogoutTooltip title="Clique para desconectar">
        <Box
          color={theme.designSystem.deepOrange[400]}
          onClick={handleLogout}
          style={{ cursor: 'pointer' }}
        >
          {loading ? <DotsLoader /> : <IconExit role="exit" />}
        </Box>
      </LogoutTooltip>

      <AlertSnackbar
        title="Falha ao desconectar"
        message="Tente novamente"
        open={error}
        type="error"
        onClose={() => setError(false)}
      />
    </SideContainer>
  )
}
