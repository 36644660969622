import { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { theme } from 'shared/theme'
import { ErrorButton, ErrorCardContainer, ErrorCircle } from './styles'

interface Props {
  message: string
  height?: number | string
  onRetry: () => Promise<void>
}

export const ErrorCard = ({ message, onRetry }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleRetry = async () => {
    setIsLoading(true)
    await onRetry()
    setIsLoading(false)
  }

  return (
    <ErrorCardContainer>
      <ErrorCircle>
        <IconClose color="white" width={14.5} height={14.5} />
      </ErrorCircle>

      <Box>
        <Typography
          fontSize={theme.designSystem.typography.fontSize.body1}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
          color={theme.designSystem.base[900]}
        >
          Falha ao carregar dados <br /> {message}
        </Typography>
      </Box>

      <ErrorButton variant="outlined" onClick={handleRetry} disabled={isLoading}>
        <Typography
          fontSize={theme.designSystem.typography.fontSize.button}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
          color={theme.designSystem.base[900]}
        >
          {isLoading ? 'Loading...' : 'Tentar novamente'}
        </Typography>
      </ErrorButton>
    </ErrorCardContainer>
  )
}
