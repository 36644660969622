import React from 'react'

interface Props {
  href?: string
  onClick?: () => void
}

export const IconYouTube = ({ href, onClick }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick()
    }
    if (!href) {
      event.preventDefault()
    }
  }

  return (
    <a
      href={href ?? '#'}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'inline-block' }}
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#050922" fillOpacity="0.01" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#050922" strokeOpacity="0.08" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6657 12.2211C23.4817 11.5437 22.9395 11.0101 22.2511 10.829C21.0034 10.5 16 10.5 16 10.5C16 10.5 10.9967 10.5 9.74891 10.829C9.06052 11.0101 8.51835 11.5437 8.33434 12.2211C8 13.4491 8 16.0112 8 16.0112C8 16.0112 8 18.5731 8.33434 19.8011C8.51835 20.4786 9.06052 20.9899 9.74891 21.171C10.9967 21.5 16 21.5 16 21.5C16 21.5 21.0033 21.5 22.2511 21.171C22.9395 20.9899 23.4817 20.4786 23.6657 19.8011C24 18.5731 24 16.0112 24 16.0112C24 16.0112 24 13.4491 23.6657 12.2211ZM14.3636 18.3373V13.685L18.5454 16.0112L14.3636 18.3373Z"
          fill="#050922"
          fillOpacity="0.16"
        />
      </svg>
    </a>
  )
}
