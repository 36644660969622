import { Box } from 'shared/designSystem/components/Box'
import { Grid } from 'shared/designSystem/components/Grid'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const CustomBox = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.designSystem.size[20]
}))

export const CustomImg = styledSystem('img')(({ theme }) => ({
  width: theme.designSystem.size[100],
  height: theme.designSystem.size[100],
  marginTop: theme.designSystem.size[10],
  marginBottom: theme.designSystem.size[10],
  borderRadius: theme.designSystem.size[100]
}))

export const GridContainer = styledSystem(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  padding: theme.designSystem.size[25],

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr'
  }
}))

export const GridUnity = styledSystem(Box)(({ theme }) => ({
  padding: theme.designSystem.size[10]
}))

export const GridUnityTitle = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  color: theme.designSystem.base[400]
}))

export const GridUnityDescription = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  color: theme.designSystem.base[900]
}))
