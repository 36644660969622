type Props = {
  role?: string
}

export const IconVisibility = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5C7 4.5 2.73 8.11 1.1 12c1.63 3.89 5.9 7.5 10.9 7.5s9.27-3.61 10.9-7.5C21.27 8.11 17 4.5 12 4.5zM12 16.5c-2.48 0-4.5-2.02-4.5-4.5S9.52 7.5 12 7.5s4.5 2.02 4.5 4.5-2.02 4.5-4.5 4.5zm0-7a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        stroke="#24283E"
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconVisibilityOff = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5c-5 0-9.27 3.61-10.9 7.5 1.33 3.16 4.27 5.5 7.9 5.5M12 16.5c2.48 0 4.5-2.02 4.5-4.5S14.48 7.5 12 7.5c-.91 0-1.75.3-2.44.79M12 12a2.5 2.5 0 01-2.5-2.5m8.94 3.2C21.06 15.08 19.42 17 17.5 18M3 3l18 18"
        stroke="#24283E"
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
