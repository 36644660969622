type Props = {
  role?: string
  strokeColor?: string
}

export const IconPlay = ({ role, strokeColor }: Props) => {
  return (
    <svg
      role={role}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58398 4.00294V15.9971C4.58398 17.2329 5.97398 17.9388 6.94898 17.1971L14.8265 11.2004C15.614 10.5996 15.614 9.40044 14.8265 8.79961L6.94898 2.80294C5.97398 2.06127 4.58398 2.76711 4.58398 4.00294Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
