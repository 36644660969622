import ArrowLeftIcon from 'shared/designSystem/components/Icons/ArrowLeftIcon'
import { AppBarCustom, CustomDropDown, CustomIconButton, CustomTypography } from './styles'

interface Props {
  role?: string
  selectedGroup: string
  onGroupChange: (group: string) => void
}

export const AppBar = ({ role, onGroupChange }: Props) => {
  const dropDownOptions = ['Energy', 'Solar']

  return (
    <AppBarCustom role={role}>
      <CustomIconButton role="icon-button">
        <ArrowLeftIcon />
      </CustomIconButton>
      <CustomTypography role="typography">Ativação da conta</CustomTypography>
      <CustomDropDown label="Todos os grupos" options={dropDownOptions} onChange={onGroupChange} />
    </AppBarCustom>
  )
}
