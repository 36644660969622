type Props = {
  role?: string
}

export const IconShare = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1H13M13 1V5M13 1L6.33333 7.66667M11.6667 11V11.6667C11.6667 12.4027 11.0693 13 10.3333 13H2.33333C1.59733 13 1 12.4027 1 11.6667V3.66667C1 2.93067 1.59733 2.33333 2.33333 2.33333H3"
        stroke="#050922"
        strokeOpacity={0.6}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
