import { TabsProps } from '@mui/material'
import { CustomTabs } from './styles'

export const Tabs = ({ children, ...rest }: TabsProps) => {
  return (
    <CustomTabs orientation="vertical" {...rest}>
      {children}
    </CustomTabs>
  )
}
