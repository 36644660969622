import { ButtonProps } from 'shared/designSystem/types/ButtonProps'
import { Button } from 'shared/designSystem/components/Button'
import { useTheme } from 'shared/hook/useTheme'

export const PasswordRecoveryButton = ({ children, ...props }: ButtonProps) => {
  const theme = useTheme()

  return (
    <Button
      fullWidth
      variant="contained"
      sx={{
        backgroundColor: theme.designSystem.base[900],
        color: theme.designSystem.base[50],
        borderRadius: theme.designSystem.size[24],
        height: theme.designSystem.size[48],
        boxShadow: 'none',
        boxSizing: 'border-box',
        '&.Mui-disabled': {
          backgroundColor: theme.designSystem.base[700],
          color: theme.designSystem.base[400]
        }
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
