import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { Box } from 'shared/designSystem/components/Box'
import { Accordion } from 'shared/designSystem/components/Accordion'

export const ModalContainer = styledSystem(Box)(({ theme }) => ({
  position: 'fixed',
  inset: 7,
  zIndex: 10,
  backgroundColor: theme.designSystem.white
}))

export const ButtonClose = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: theme.designSystem.size[30],
  minHeight: theme.designSystem.size[30],
  cursor: 'pointer'
}))

export const DetailsContainer = styledSystem(Box)(({ theme }) => ({
  position: 'relative',
  borderLeft: `1px solid ${theme.designSystem.base[200]}`,
  overflowY: 'auto',
  maxHeight: '90vh'
}))

export const InformationsContainer = styledSystem(Box)(({ theme }) => ({
  padding: theme.designSystem.size[40]
}))

export const AccordionOption = styledSystem(Accordion)(() => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none'
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0'
  }
}))

export const ButtonExpand = styledSystem(Box)(({ theme }) => ({
  height: '50px',
  width: '50px',
  border: `1px solid ${theme.designSystem.base[200]}`,
  borderRadius: '100%',
  position: 'fixed',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.designSystem.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}))
