import { ConsumitionData } from 'pages/Home/models/ConsumitionData'
import { EconomyData } from 'pages/Home/models/EconomyData'
import { Group, Unit } from 'pages/Home/models/Group'
import { create } from 'zustand'

type StateProducts = {
  value: number
  name: string
}
type StateProps = {
  consumes: ConsumitionData[]
  filteredConsumes: ConsumitionData[]
  filteredEconomy?: EconomyData
  filteredPayment: any
  unit: Unit | null
  units: Unit[]
  consume: number
  groups: Group[]
  selectedGroup: Group | null
  products: StateProducts[]
  selectedProduct: string
  filteredGroups: Group[]
  error: boolean
}

type ActionsProps = {
  selectUnit: (unit: Unit) => void
  setSelectedGroup: (group: Group | null) => void
  selectConsume: (consume: ConsumitionData) => void
  setGroups: (groups: Group[]) => void
  setConsumes: (consumes: ConsumitionData[]) => void
  setSelectedProduct: (product: string) => void
  setError: (error: boolean) => void
  setFilteredConsumes: (consume: ConsumitionData[]) => void
  setFilteredEconomy: (consume: EconomyData) => void
  setFilteredPayment: (consume: any[]) => void
}

type StoreProps = {
  state: StateProps
  actions: ActionsProps
}

export const useConsumitionStore = create<StoreProps>((set) => ({
  state: {
    unit: null,
    units: [],
    consume: 0,
    consumes: [],
    filteredConsumes: [],
    filteredEconomy: {
      totalTraditionalCost: 0,
      totalGenialCost: 0,
      totalEconomyValue: 0
    },
    filteredPayment: [],
    groups: [],
    selectedGroup: null,
    products: [
      { value: 0, name: 'Todos os produtos' },
      { value: 1, name: 'Energy' }
    ],
    selectedProduct: '',
    filteredGroups: [],
    error: false
  },
  actions: {
    setGroups: (groups: Group[]) => {
      set((store) => ({
        state: { ...store.state, groups }
      }))
    },
    selectUnit: (unit: Unit) => {
      set((store) => {
        const consume = store.state.consumes.find(
          (consumition) => consumition.unit[0].installationNumber === unit.installationNumber
        )?.energy

        return {
          ...store,
          state: { ...store.state, unit, consume: consume ?? 0 }
        }
      })
    },
    setSelectedGroup: (group: Group | null) => {
      set((store) => ({
        state: { ...store.state, selectedGroup: group }
      }))
    },
    selectConsume: (consume: ConsumitionData) => {
      set((store) => ({
        state: { ...store.state, consume: consume.energy }
      }))
    },
    setConsumes: (consumes: ConsumitionData[]) => {
      set((store) => ({
        state: { ...store.state, consumes }
      }))
    },
    setSelectedProduct: (product: string) => {
      set((store) => {
        const filteredGroups = store.state.groups.filter(
          (group) => group.units[0].unitType.unitTypeName === product
        )
        return {
          state: { ...store.state, filteredGroups, selectedProduct: product }
        }
      })
    },
    setError: (error: boolean) => {
      set((store) => {
        return {
          state: { ...store.state, error }
        }
      })
    },
    setFilteredConsumes: (filteredConsumes: ConsumitionData[]) => {
      set((store) => ({
        state: { ...store.state, filteredConsumes }
      }))
    },
    setFilteredEconomy: (filteredEconomy: EconomyData) => {
      set((store) => ({
        state: { ...store.state, filteredEconomy }
      }))
    },
    setFilteredPayment: (filteredPayment: any[]) => {
      set((store) => ({
        state: { ...store.state, filteredPayment }
      }))
    }
  }
}))
