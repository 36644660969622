import { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { useConsumitionStore } from 'shared/store/consumition'
import { ErrorCard } from '../Error/ErrorCard'
import { DataBars } from './DataBars'
import { Header } from './Header'
import { Legend } from './Legend'
import { ConsumeGraphBox } from './styles'
import { ConsumitionDataPageProps } from 'pages/Home/models/ConsumitionData'
import EmptyBars from './EmptyBars'

const ConsumeGraph = ({ fetchDataConsumes, error }: ConsumitionDataPageProps) => {
  const {
    state: { filteredConsumes }
  } = useConsumitionStore()
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)
  const showEmptyState = filteredConsumes.length === 0

  return (
    <>
      {error ? (
        <Box height={384} display={'flex'} justifyContent={'center'} bgcolor={'white'}>
          <ErrorCard message="de consumo" onRetry={fetchDataConsumes} />
        </Box>
      ) : (
        <ConsumeGraphBox>
          <Header />
          {showEmptyState ? (
            <EmptyBars />
          ) : (
            <DataBars highlightedIndex={highlightedIndex} setHighlightedIndex={setHighlightedIndex} />
          )}
          {!showEmptyState && <Legend />}
        </ConsumeGraphBox>
      )}
    </>
  )
}

export default ConsumeGraph
