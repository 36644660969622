import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { useTheme } from 'shared/hook/useTheme'

export const Legend = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        pt: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Box
          sx={{
            width: 12,
            height: 12,
            bgcolor: `${theme.designSystem.primary[500]}`
          }}
        />
        <Typography
          fontSize={theme.designSystem.size[12]}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
        >
          Consumo confirmado
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Typography
          fontSize={theme.designSystem.size[12]}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
        >
          Aguardando medição
        </Typography>
        <Box
          sx={{
            width: 12,
            height: 12,
            bgcolor: `${theme.designSystem.primary[200]}`
          }}
        />
      </Box>
    </Box>
  )
}
