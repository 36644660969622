import { useState, useEffect } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Menu, MenuItem, TextField } from '@mui/material'
import { data } from 'shared/mocks/filters.json'
import { TypeComboGroup } from 'shared/models/filters'
import useFilters from 'shared/hook/useFilters'
import type { TypePropsPageHeader } from 'shared/layout/Header/interface'

const MultiLevelSelectProduct = ({ fetchData }: TypePropsPageHeader) => {
  const { filters, setBulkFilterData } = useFilters('filtesAll')
  const [anchorEl, setAnchorEl] = useState(null)
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null)
  const [comboGroups, setComboGroups] = useState<TypeComboGroup[]>([])

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event?.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSubmenuAnchorEl(null)
  }

  const handleCloseSubmenu = () => {
    setSubmenuAnchorEl(null)
  }

  const handleProductClick = (product: number, event: any) => {
    setBulkFilterData({ ...filters, product: product, group: '', unit: '' })
    setSubmenuAnchorEl(event?.currentTarget)
  }

  const handleGroupClick = (group: number | string, unit: number | string | null) => {
    setBulkFilterData({ ...filters, group: group, unit: unit })
    handleCloseMenu()
    fetchData()
  }

  const handleInitialParams = () => {
    if (filters?.product) {
      const datagroup = data.products.find((item: any) => item.value == filters?.product)
      if (datagroup) setComboGroups(datagroup?.groups)
    }
  }

  const handleTitle = () => {
    if (filters?.unit) {
      const dataGroup = comboGroups.find((item: any) => item.value == filters?.group)
      const dataUnit = dataGroup?.units?.find((item: any) => item.value == filters?.unit)
      return dataUnit?.name
    }

    if (filters?.group) {
      const dataGroup = comboGroups.find((item: any) => item.value == filters?.group)
      return dataGroup?.name
    }

    return 'Selecione um produto'
  }

  useEffect(() => {
    handleInitialParams()
  }, [filters])

  return (
    <>
      <TextField size="small" onClick={handleOpenMenu} variant="outlined" value={handleTitle()} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {data.products.map((item, index) => (
          <MenuItem
            key={index}
            sx={{ fontWeight: filters?.product == item.value ? 'bold' : '' }}
            onClick={(event) => handleProductClick(item.value, event)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleCloseSubmenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box sx={{ height: '224px' }}>
          {comboGroups.map((itemSub) => (
            <Box key={itemSub.value}>
              <MenuItem onClick={() => handleGroupClick(itemSub.value, '')}>
                <b>{itemSub.name}</b>
              </MenuItem>
              {itemSub.units.map((unit) => (
                <MenuItem key={unit.value} onClick={() => handleGroupClick(itemSub.value, unit.value)}>
                  {unit.name}
                </MenuItem>
              ))}
            </Box>
          ))}
        </Box>
      </Menu>
    </>
  )
}

export default MultiLevelSelectProduct
