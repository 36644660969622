import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import useFilters from 'shared/hook/useFilters'
import { Typography } from 'shared/designSystem/components/Typography'
import { Button, FilterDefaultContainer, ProductBadgeContainer, QuestionContainer } from './styles'
import { DotsLoader } from '../../../../shared/designSystem/components/DotsLoader'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { TypeComboGroup, TypeComboUnit, TypeComboProduct } from 'shared/models/filters'
import { data } from 'shared/mocks/filters.json'
import { useReportStore } from 'shared/store/reports'

export const FilterDefault = () => {
  const { filters, setBulkFilterData } = useFilters('filtesAll')
  const {
    actions: { setFilterDefault }
  } = useReportStore()
  const [comboUnits, setComboUnits] = useState<TypeComboUnit[]>([])
  const [comboGroups, setComboGroups] = useState<TypeComboGroup[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<{ status: boolean; message: string }>({
    status: false,
    message: ''
  })

  const handleSubmit = () => {
    try {
      setLoading(true)
      setError({
        status: false,
        message: ''
      })
      setTimeout(() => {
        if (filters.product && filters.group) return setFilterDefault(false)
        setLoading(false)
        setError({
          status: true,
          message: 'Tentar novamente'
        })
      }, 3000)
    } catch (error) {
      setError({
        status: true,
        message: 'Falha ao gerar relatórios'
      })
    }
  }

  const handleCloseError = () => {
    setError({
      status: false,
      message: ''
    })
  }

  const handleChange = (field: string, value: string | number) => {
    if (field === 'product') {
      return setBulkFilterData({ ...filters, [field]: value, group: '', unit: '' })
    }

    if (field === 'group') {
      return setBulkFilterData({ ...filters, [field]: value, unit: '' })
    }

    setBulkFilterData({ ...filters, [field]: value })
  }

  const handleInitialParams = () => {
    let datagroup
    if (filters?.product) {
      datagroup = data.products.find((item: any) => item.value == filters?.product)
      if (datagroup) setComboGroups(datagroup?.groups)
    }
    if (filters?.group) {
      const dataUnit = datagroup?.groups?.find((item: any) => item.value == filters?.group)
      if (dataUnit) setComboUnits(dataUnit?.units)
    }
  }

  useEffect(() => {
    handleInitialParams()
  }, [filters])

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <AlertSnackbar
        type="error"
        open={error.status}
        onClose={handleCloseError}
        title="Falha ao gerar relatórios"
        message={error.message}
      />
      <FilterDefaultContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '80%',
            height: '80%',
            marginInline: 'auto'
          }}
        >
          <Box>
            <QuestionContainer>
              <Typography>Para começar, escolha um produto:</Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {data?.products?.map((element: TypeComboProduct) => (
                  <ProductBadgeContainer
                    key={element.value}
                    selected={filters?.product == element.value}
                    onClick={() => handleChange('product', element.value)}
                  >
                    {element.name}
                  </ProductBadgeContainer>
                ))}
              </Box>
            </QuestionContainer>

            {filters?.product && (
              <QuestionContainer>
                <Typography>Tem preferência por algum grupo?</Typography>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {comboGroups?.map((element: TypeComboGroup) => (
                    <ProductBadgeContainer
                      key={element.value}
                      selected={filters?.group == element.value}
                      onClick={() => handleChange('group', element.value)}
                    >
                      {element.name}
                    </ProductBadgeContainer>
                  ))}
                </Box>
              </QuestionContainer>
            )}
            {filters?.group && filters?.group != 0 && (
              <QuestionContainer>
                <Typography>E a unidade consumidora?</Typography>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {comboUnits.map((element: TypeComboUnit) => (
                    <ProductBadgeContainer
                      key={element.value}
                      selected={filters?.unit == element.value}
                      onClick={() => handleChange('unit', element.value)}
                    >
                      {element.name}
                    </ProductBadgeContainer>
                  ))}
                </Box>
              </QuestionContainer>
            )}
          </Box>
          <Button onClick={handleSubmit}>
            {loading && <DotsLoader />}
            {error.status && 'Tentar novamente'.toUpperCase()}
            {!loading && !error.status && 'Gerar relatórios'}
          </Button>
        </Box>
      </FilterDefaultContainer>
    </Box>
  )
}
