import { useState, useEffect } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Menu, MenuItem, TextField } from '@mui/material'
import { data } from 'shared/mocks/filters.json'
import { TypeComboMonths } from 'shared/models/filters'
import useFilters from 'shared/hook/useFilters'
import type { TypePropsPageHeader } from 'shared/layout/Header/interface'

const MultiLevelSelectYear = ({ fetchData }: TypePropsPageHeader) => {
  const { filters, setBulkFilterData } = useFilters('filtesAll')
  const [anchorEl, setAnchorEl] = useState(null)
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null)
  const [comboMonths, setComboMonths] = useState<TypeComboMonths[]>([])

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event?.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSubmenuAnchorEl(null)
  }

  const handleCloseSubmenu = () => {
    setSubmenuAnchorEl(null)
  }

  const handleYear = (year: number, event: any) => {
    setBulkFilterData({ ...filters, year: year })
    setSubmenuAnchorEl(event?.currentTarget)
  }

  const handleMonth = (month: number | string) => {
    setBulkFilterData({ ...filters, month: month })
    handleCloseMenu()
    fetchData()
  }

  const handleInitialParams = () => {
    if (filters?.year) {
      const dataMonth = data.filterDate.find((item: any) => item.year == filters?.year)
      if (dataMonth) setComboMonths(dataMonth?.months)
    }
  }

  const handleTitle = () => {
    if (filters?.month) {
      const dataYear = data.filterDate.find((item: any) => item.year == filters?.year)
      const dataMonth = dataYear?.months?.find((item: any) => item.value == filters?.month)
      return `${filters?.year} - ${dataMonth?.name}`
    }

    if (filters?.year) return filters?.year

    return 'Selecione uma data'
  }

  useEffect(() => {
    handleInitialParams()
  }, [filters])

  return (
    <>
      <TextField size="small" onClick={handleOpenMenu} variant="outlined" value={handleTitle()} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {data.filterDate.map((item) => (
          <MenuItem
            key={item.year}
            sx={{ fontWeight: filters?.year == item.year ? 'bold' : '' }}
            onClick={(event) => handleYear(item.year, event)}
          >
            {item.year}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleCloseSubmenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box sx={{ height: '224px' }}>
          {comboMonths.map((month) => (
            <MenuItem
              sx={{ fontWeight: filters?.month == month.value ? 'bold' : '' }}
              key={month.value}
              onClick={() => handleMonth(month.value)}
            >
              {month.name}
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </>
  )
}

export default MultiLevelSelectYear
