import { ErrorIcon } from '../Icons/ErrorIcon'
import {
  StyledContentBox,
  StyledErrorContainer,
  StyledIconContainer,
  StyledMessageTypography,
  StyledRetryButton,
  StyledTitleTypography
} from './styles'

export const Error = () => {
  return (
    <StyledErrorContainer>
      <StyledContentBox>
        <StyledIconContainer>
          <ErrorIcon />
          <StyledTitleTypography role="title-message">Falha ao carregar</StyledTitleTypography>
          <StyledMessageTypography role="error-message">
            Um problema aconteceu e não foi possível carregar dados da página inicial. Por favor, tente
            novamente.
          </StyledMessageTypography>
          <StyledRetryButton role="reload-button" onClick={() => {}}>
            TENTAR NOVAMENTE
          </StyledRetryButton>
        </StyledIconContainer>
      </StyledContentBox>
    </StyledErrorContainer>
  )
}
