import { ChangeEvent, useState } from 'react'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { Box } from 'shared/designSystem/components/Box'
import { DotsLoader } from 'shared/designSystem/components/DotsLoader'
import { IconButton } from 'shared/designSystem/components/IconButton'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconVisibilityOff } from 'shared/designSystem/Icons/IconVisibilityOff'
import { IconVisibilityOn } from 'shared/designSystem/Icons/IconVisibilityOn'
import { useTheme } from 'shared/hook/useTheme'
import { getErrorMessage } from 'shared/utils/errorMessages'
import { PasswordRecoveryButton } from '../components/PasswordRecoveryButton'
import { RecoveryTitle } from '../components/PasswordRecoveryTitle'
import { ResetPasswordApi } from '../services/ResetPasswordApi'
import { DividerContainer, TextFieldInput } from '../styles'
import { BoxContainer } from './styles'

type Props = {
  handleClose: () => void
  handleSwitchToSuccess: () => void
  email: string
}

export const ResetPassword = ({ handleClose, handleSwitchToSuccess, email }: Props) => {
  const theme = useTheme()

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [securityCode, setSecurityCode] = useState('')
  const [rawSecurityCode, setRawSecurityCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [securityCodeError, setSecurityCodeError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const [alertOpen, setAlertOpen] = useState(false)
  const [tryAgain, setTryAgain] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const validateSecurityCode = () => {
    if (!rawSecurityCode) return 'Este campo é obrigatório'
    if (rawSecurityCode.length !== 6) return 'O código de segurança deve ter 6 dígitos'
    return ''
  }

  const validatePassword = () => {
    if (!password) return 'Este campo é obrigatório'
    return ''
  }

  const validateConfirmPassword = () => {
    if (!confirmPassword) return 'Este campo é obrigatório'
    if (confirmPassword !== password) return 'As senhas devem ser iguais'
    if (
      password.length < 8 ||
      !/[A-Za-z]/.test(password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(password) ||
      !/[0-9]/.test(password) ||
      /\s/.test(password)
    )
      return 'Deve ter no mínimo 8 caracteres com letras, números e símbolos'
    return ''
  }

  const handleApiError = (error: any) => {
    const errorMessage = getErrorMessage(error.response?.data?.message || '')
    setSecurityCodeError(errorMessage.includes('código') ? errorMessage : '')

    if (!errorMessage.includes('código')) {
      setAlertOpen(true)
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    setSecurityCodeError('')
    setPasswordError('')
    setConfirmPasswordError('')

    const securityCodeError = validateSecurityCode()
    const passwordError = validatePassword()
    const confirmPasswordError = validateConfirmPassword()

    if (securityCodeError || passwordError || confirmPasswordError) {
      setSecurityCodeError(securityCodeError)
      setPasswordError(passwordError)
      setConfirmPasswordError(confirmPasswordError)
      setLoading(false)
      return
    }

    try {
      const payload = { email, code: rawSecurityCode, password }
      await ResetPasswordApi.resetPassword(payload)
      handleSwitchToSuccess()
    } catch (error: any) {
      handleApiError(error)
    } finally {
      setLoading(false)
    }
  }

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\s/g, '')
    setPassword(input)
  }

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\s/g, '')
    setConfirmPassword(input)
  }

  const handleSecurityCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawCode = e.target.value.replace(/\D/g, '')
    const formattedCode = rawCode.replace(/(\d{3})(\d+)/, '$1 $2')
    setSecurityCode(formattedCode)
    setRawSecurityCode(rawCode)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
    setTryAgain(false)
  }

  return (
    <Box height="100vh" mt={20}>
      <BoxContainer>
        <RecoveryTitle text="Recuperação de senha" handleClose={handleClose} />

        <DividerContainer />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            mt: 5
          }}
        >
          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            fontSize={theme.designSystem.typography.fontSize.body2}
            color={theme.designSystem.base[500]}
          >
            Para finalizar, informe o código de segurança que foi enviado para o e-mail de cadastro. Na
            sequência, digite sua nova senha.
          </Typography>

          <Box>
            <TextFieldInput
              variant="standard"
              label="Código de segurança"
              fullWidth
              value={securityCode}
              onChange={handleSecurityCodeChange}
              error={!!securityCodeError}
              slotProps={{
                htmlInput: {
                  minLength: 6,
                  maxLength: 7
                }
              }}
            />

            {securityCodeError && (
              <Typography color={theme.designSystem.deepOrange[500]} variant="caption">
                {securityCodeError}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              position: 'relative'
            }}
          >
            <TextFieldInput
              variant="standard"
              label="Nova senha"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              error={!!passwordError || !!confirmPasswordError}
              slotProps={{
                htmlInput: {
                  minLength: 8,
                  maxLength: 64
                }
              }}
            />

            {passwordError && (
              <Typography color={theme.designSystem.deepOrange[500]} variant="caption">
                {passwordError}
              </Typography>
            )}

            <IconButton
              onClick={handleTogglePasswordVisibility}
              sx={{
                position: 'absolute',
                right: 0,
                top: passwordError ? '25%' : '50%',
                transform: 'translateY(-50%)'
              }}
            >
              {showPassword ? (
                <IconVisibilityOn role="visibility-icon" />
              ) : (
                <IconVisibilityOff role="visibility-off-icon" />
              )}
            </IconButton>
          </Box>

          <Box
            sx={{
              position: 'relative',
              mb: 6
            }}
          >
            <TextFieldInput
              variant="standard"
              label="Confirme a senha"
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!!confirmPasswordError}
              slotProps={{
                htmlInput: {
                  minLength: 8,
                  maxLength: 64
                }
              }}
            />

            {confirmPasswordError && (
              <Typography color={theme.designSystem.deepOrange[500]} variant="caption">
                {confirmPasswordError}
              </Typography>
            )}

            <IconButton
              onClick={handleToggleConfirmPasswordVisibility}
              sx={{
                position: 'absolute',
                right: 0,
                top: confirmPasswordError ? '25%' : '50%',
                transform: 'translateY(-50%)'
              }}
            >
              {showConfirmPassword ? (
                <IconVisibilityOn role="visibility-icon" />
              ) : (
                <IconVisibilityOff role="visibility-off-icon" />
              )}
            </IconButton>
          </Box>
        </Box>

        <PasswordRecoveryButton onClick={handleSubmit}>
          {loading ? <DotsLoader /> : tryAgain ? 'TENTAR NOVAMENTE' : 'CONFIRMAR'}
        </PasswordRecoveryButton>
      </BoxContainer>

      <AlertSnackbar
        type="error"
        open={alertOpen}
        onClose={handleAlertClose}
        title="Falha ao alterar senha"
        message="Tente novamente"
      />
    </Box>
  )
}
