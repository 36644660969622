import React from 'react'

interface Props {
  href?: string
  onClick?: () => void
}

export const IconInstagram = ({ href, onClick }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick()
    }
    if (!href) {
      event.preventDefault()
    }
  }

  return (
    <a
      href={href ?? '#'}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'inline-block' }}
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#050922" fillOpacity="0.01" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#050922" strokeOpacity="0.08" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9978 9.00391C14.0973 9.00391 13.859 9.01196 13.1126 9.04602C12.3678 9.07999 11.8591 9.1983 11.414 9.3713C10.9538 9.5501 10.5636 9.78938 10.1745 10.1784C9.78547 10.5675 9.54619 10.9577 9.36739 11.4179C9.19439 11.863 9.07608 12.3717 9.04211 13.1165C9.00806 13.8629 9 14.1012 9 16.0017C9 17.9022 9.00806 18.1405 9.04211 18.8869C9.07608 19.6317 9.19439 20.1404 9.36739 20.5855C9.54619 21.0457 9.78547 21.436 10.1745 21.825C10.5636 22.214 10.9538 22.4533 11.414 22.6321C11.8591 22.8051 12.3678 22.9234 13.1126 22.9574C13.859 22.9914 14.0973 22.9995 15.9978 22.9995C17.8983 22.9995 18.1366 22.9914 18.8829 22.9574C19.6278 22.9234 20.1365 22.8051 20.5816 22.6321C21.0417 22.4533 21.432 22.214 21.8211 21.825C22.2101 21.436 22.4494 21.0457 22.6282 20.5855C22.8012 20.1404 22.9195 19.6317 22.9534 18.8869C22.9875 18.1405 22.9956 17.9022 22.9956 16.0017C22.9956 14.1012 22.9875 13.8629 22.9534 13.1165C22.9195 12.3717 22.8012 11.863 22.6282 11.4179C22.4494 10.9577 22.2101 10.5675 21.8211 10.1784C21.432 9.78938 21.0417 9.5501 20.5816 9.3713C20.1365 9.1983 19.6278 9.07999 18.8829 9.04602C18.1366 9.01196 17.8983 9.00391 15.9978 9.00391ZM13.6652 16.0017C13.6652 17.29 14.7095 18.3343 15.9978 18.3343C17.2861 18.3343 18.3304 17.29 18.3304 16.0017C18.3304 14.7134 17.2861 13.6691 15.9978 13.6691C14.7095 13.6691 13.6652 14.7134 13.6652 16.0017ZM12.4043 16.0017C12.4043 14.0171 14.0132 12.4082 15.9978 12.4082C17.9824 12.4082 19.5912 14.0171 19.5912 16.0017C19.5912 17.9863 17.9824 19.5951 15.9978 19.5951C14.0132 19.5951 12.4043 17.9863 12.4043 16.0017ZM19.7332 13.106C20.197 13.106 20.573 12.73 20.573 12.2663C20.573 11.8025 20.197 11.4265 19.7332 11.4265C19.2695 11.4265 18.8935 11.8025 18.8935 12.2663C18.8935 12.73 19.2695 13.106 19.7332 13.106Z"
          fill="#050922"
          fillOpacity="0.16"
        />
      </svg>
    </a>
  )
}
