type Props = {
  role?: string
  strokeColor?: string
}

export const IconHome = ({ role, strokeColor }: Props) => {
  return (
    <svg
      role={role}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66658 19.1666V14.1666C6.66658 12.3258 8.15908 10.8333 9.99992 10.8333C11.8408 10.8333 13.3333 12.3258 13.3333 14.1666V19.1666M0.833252 17.4999V8.33325L9.99992 0.833252L19.1666 8.33325V17.4999C19.1666 18.4199 18.4199 19.1666 17.4999 19.1666H2.49992C1.57992 19.1666 0.833252 18.4199 0.833252 17.4999Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
