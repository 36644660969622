import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const CustomBox = styledSystem(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}))

export const UnityBox = styledSystem(Box)(({ theme }) => ({
  height: theme.designSystem.size[56],
  width: theme.designSystem.size[484],
  display: 'flex',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: theme.designSystem.size[1.5],
  borderColor: theme.designSystem.base[200]
}))

export const CustomIconCircle = styledSystem(Box)(({ theme }) => ({
  height: theme.designSystem.size[20],
  width: theme.designSystem.size[20],
  marginLeft: theme.designSystem.size[16],
  marginRight: theme.designSystem.size[20],
  paddingLeft: theme.designSystem.size[1.5],
  borderRadius: theme.designSystem.size[100],
  backgroundColor: theme.designSystem.success
}))

export const CustomEmptyCircle = styledSystem(Box)(({ theme }) => ({
  height: theme.designSystem.size[20],
  width: theme.designSystem.size[20],
  marginLeft: theme.designSystem.size[16],
  marginRight: theme.designSystem.size[20],
  paddingLeft: theme.designSystem.size[1.5],
  borderRadius: theme.designSystem.size[100],
  borderStyle: 'solid',
  borderWidth: theme.designSystem.size[2],
  borderColor: theme.designSystem.base[200]
}))

export const CustomTitle = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[900]
}))

export const CustomEmptyTitle = styledSystem(Typography)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.button,
  color: theme.designSystem.base[500]
}))

export const CustomDate = styledSystem(Typography)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.designSystem.size[16],
  fontSize: theme.designSystem.typography.fontSize.button,
  color: theme.designSystem.base[400]
}))
