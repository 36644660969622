import { memo, useRef, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Header } from './Header'
import { Button } from './styles'

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'

import { GraphBox, GraphContainer } from 'pages/Reports/styles'
import { GraphProps } from 'pages/Reports/types/GraphProps'
import { Bar } from 'react-chartjs-2'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { DotsLoader } from 'shared/designSystem/components/DotsLoader'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'
import { getDesaturatedColor } from 'shared/utils/getDesaturatedColor'
import { getMonthAbbreviations, getMonthFirstLetter } from 'shared/utils/getMonths'
import { EmptyData } from '../ConsumeGraph/EmptyData'
import { Legend } from '../Legend'
import { CustomTooltip } from '../Tooltip'
import { RequestAnalysisModal } from './RequestAnalysisModal'
import { dataReports } from 'shared/mocks/downloadPdf.json'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  annotationPlugin
)

const EconomyAlertGraph = ({ variant }: GraphProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({
    type: 'error',
    open: false,
    message: '',
    title: ''
  })

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [tooltipData, setTooltipData] = useState<{
    x: number
    y: number
    visible: boolean
  }>({ x: 0, y: 0, visible: false })

  const isModalVariant = variant === 'modal'

  const barraA = dataReports.dataEconomyAlert.dataGraphic.demandMeasuredAtPeak
  const barraB = dataReports.dataEconomyAlert.dataGraphic.demandMeasuredOffPeak

  const hasData = barraA.length > 0 && barraB.length > 0

  const data = {
    labels: isModalVariant ? getMonthAbbreviations() : getMonthFirstLetter(),
    datasets: [
      {
        label: 'Bar Dataset',
        data: barraA,
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? '#ccedfd'
            : getDesaturatedColor('#ccedfd'),
        barPercentage: 1,
        barThickness: 18,
        categoryPercentage: 1
      },
      {
        label: 'Bar Dataset',
        data: barraB,
        backgroundColor: ({ dataIndex }: { dataIndex: number }) =>
          hoveredIndex === null || hoveredIndex === dataIndex
            ? '#37a9fb'
            : getDesaturatedColor('#37a9fb'),
        barPercentage: 1,
        barThickness: 18,
        categoryPercentage: 1
      }
    ]
  }

  const prevTooltipData = useRef(tooltipData)

  const options: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    animation: false,
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      annotation: {
        annotations: {
          line1: {
            type: 'line' as const,
            yMin: dataReports.dataEconomyAlert.dataGraphic.contractedMeasuredAtPeak,
            yMax: dataReports.dataEconomyAlert.dataGraphic.contractedMeasuredAtPeak,
            borderColor: '#fb591f',
            borderWidth: 2
          },
          line2: {
            type: 'line' as const,
            yMin: dataReports.dataEconomyAlert.dataGraphic.contractedMeasuredOffPeak,
            yMax: dataReports.dataEconomyAlert.dataGraphic.contractedMeasuredOffPeak,
            borderColor: '#e0dfbd',
            borderWidth: 2
          }
        }
      },

      tooltip: {
        enabled: false,
        external: (context) => {
          const { chart, tooltip } = context

          if (tooltip.opacity === 0) {
            if (tooltipData.visible) {
              setTooltipData((prev) => ({ ...prev, visible: false }))
            }
            return
          }

          const { offsetLeft, offsetTop } = chart.canvas

          if (
            prevTooltipData.current.x !== offsetLeft + tooltip.caretX ||
            prevTooltipData.current.y !== offsetTop + tooltip.caretY ||
            !tooltipData.visible
          ) {
            const newTooltipData = {
              x: offsetLeft + tooltip.caretX,
              y: offsetTop + tooltip.caretY,
              visible: true
            }
            prevTooltipData.current = newTooltipData
            setTooltipData(newTooltipData)
          }
        }
      }
    },
    onHover: (_, chartElement) => {
      const newIndex = chartElement.length ? chartElement[0].index : null
      newIndex !== hoveredIndex && setHoveredIndex(newIndex)
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: isModalVariant
        },
        ticks: {
          display: isModalVariant
        },
        border: {
          display: false
        }
      }
    }
  }

  const handleRequestAnalysis = async () => {
    setLoading(true)
    try {
      setModalOpen(true)
    } catch (error) {
      setAlert({
        open: true,
        type: 'error',
        title: 'Falha ao solicitar estudo',
        message: 'Tente novamente'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <GraphBox isModalVariant={isModalVariant}>
      <Header />
      {hasData ? (
        <>
          <GraphContainer isModalVariant={isModalVariant} onMouseLeave={() => setHoveredIndex(null)}>
            <Bar data={data} options={options} />
            {tooltipData.visible && (
              <CustomTooltip
                x={tooltipData!.x}
                y={tooltipData!.y}
                visible={tooltipData.visible}
                datas={[
                  {
                    label: 'Demanda medida em ponta',
                    value: barraA[hoveredIndex!]
                  },
                  {
                    label: 'Demanda medida fora ponta',
                    value: barraB[hoveredIndex!]
                  },
                  {
                    label: 'Demanda contratada em ponta',
                    value: barraB[hoveredIndex!]
                  },
                  {
                    label: 'Demanda contratada fora ponta',
                    value: barraB[hoveredIndex!]
                  }
                ]}
              />
            )}
          </GraphContainer>

          <Box display={'flex'} flexWrap={'wrap'} gap={1} width={'90%'}>
            <Legend
              color={theme.designSystem.primary[100]}
              text="Demanda medida em ponta"
              variant="square"
            />
            <Legend
              color={theme.designSystem.primary[500]}
              text="Demanda medida fora ponta"
              variant="square"
            />
            <Legend
              color={theme.designSystem.deepOrange[500]}
              text="Demanda contratada em ponta"
              variant="square"
            />
            <Legend
              color={theme.designSystem.attention[500]}
              text="Demanda contratada fora ponta"
              variant="square"
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Typography sx={{ color: theme.designSystem.base[300] }}>
              Solicite uma análise para o nosso time e tenha acesso à{' '}
              <span
                style={{
                  color: theme.designSystem.green[500],
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
                onClick={() => {}}
              >
                oportunidades exclusivas de economia.
              </span>
            </Typography>

            <Button onClick={handleRequestAnalysis} disabled={loading} isModalVariant={isModalVariant}>
              {loading ? (
                <DotsLoader
                  color={`${
                    isModalVariant ? theme.designSystem.base[50] : theme.designSystem.base[900]
                  }`}
                />
              ) : (
                <Typography
                  sx={{
                    color: `${
                      isModalVariant ? theme.designSystem.base[50] : theme.designSystem.base[900]
                    }`,
                    fontSize: theme.designSystem.typography.fontSize.button,
                    fontWeight: theme.designSystem.typography.fontWeight.medium
                  }}
                >
                  Solicitar análise
                </Typography>
              )}
            </Button>

            <RequestAnalysisModal open={isModalOpen} onClose={() => setModalOpen(false)} />

            <AlertSnackbar
              type={alert.type}
              open={alert.open}
              onClose={() => setAlert({ ...alert, open: false })}
              title={alert.title}
              message={alert.message}
            />
          </Box>
        </>
      ) : (
        <>
          <EmptyData />
          <Box sx={{ mt: 3 }}>
            <Legend color={theme.designSystem.base[200]} text="Não disponível" variant="square" />
          </Box>
        </>
      )}
    </GraphBox>
  )
}

export default memo(EconomyAlertGraph)
