import { useSearchParams } from 'react-router-dom'
import { Box } from 'shared/designSystem/components/Box'
import { IconGenial } from 'shared/designSystem/Icons/IconGenial'
import { ProductBadge } from 'shared/layout/Header/HeaderContainer/ProductBadge'
import { dataReports } from 'shared/mocks/downloadPdf.json'
import { data } from 'shared/mocks/filters.json'
import { theme } from 'shared/theme'
import {
  BoxIcon,
  HeaderContainer,
  InfoContainer,
  InfoDynamic,
  InfoItem,
  LogoContainer,
  Title
} from './styles'
import { getMonths } from 'shared/utils/getMonths'

interface Props {
  page: number
  numberOfPages: number
}

export const Header = ({ page, numberOfPages }: Props) => {
  const title = dataReports.dataTableHeader.title
  const product = dataReports.dataTableHeader.product

  const [searchParams] = useSearchParams()

  const unitSelected = searchParams.has('unit')
  const groupSelected = searchParams.has('group')
  const monthSelected = searchParams.get('month')

  const getUnitName = () => {
    return data.products.flatMap((product) =>
      product.groups.flatMap((group) =>
        group.units.filter((unit) => unit.value === parseInt(searchParams.get('unit')!, 10))
      )
    )[0]?.name
  }
  const getGroupName = () => {
    return data.products.flatMap((product) =>
      product.groups.filter((group) => group.value === parseInt(searchParams.get('group')!, 10))
    )[0]?.name
  }
  const getProducName = () => {
    return data.products.filter(
      (product) => product.value === parseInt(searchParams.get('product')!, 10)
    )[0]?.name
  }

  const months = getMonths()

  const selection = unitSelected ? getUnitName() : groupSelected ? getGroupName() : getProducName()
  const yearSelection =
    monthSelected !== '0'
      ? `${months[parseInt(monthSelected!) - 1]} - ${searchParams.get('year')}`
      : searchParams.get('year')
  return (
    <HeaderContainer>
      <LogoContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <BoxIcon hasBackground>
            <IconGenial />
          </BoxIcon>
        </Box>
        <ProductBadge name={product} color={theme.designSystem.base[500]} />
      </LogoContainer>

      <InfoContainer>
        <Box>
          <Title>{title}</Title>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <InfoItem>Seleção</InfoItem>
            <InfoDynamic>{selection}</InfoDynamic>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <InfoItem>Período</InfoItem>
            <InfoDynamic>{yearSelection}</InfoDynamic>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <InfoItem>Página</InfoItem>
            <InfoDynamic>{page}</InfoDynamic>
            <InfoItem>de</InfoItem>
            <InfoDynamic>{numberOfPages}</InfoDynamic>
          </Box>
        </Box>
      </InfoContainer>
    </HeaderContainer>
  )
}
