import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { Box } from '../Box'

export const DotsLoaderCustom = styledSystem(Box)<{ color?: string }>(({ theme, color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  '& > div': {
    width: 4,
    height: 4,
    margin: '0 4px',
    backgroundColor: color || theme.designSystem.base[50],
    borderRadius: '50%',
    animation: 'loadingDots 0.8s infinite'
  },
  '& > div:nth-of-type(2)': {
    animationDelay: '0.2s'
  },
  '& > div:nth-of-type(3)': {
    animationDelay: '0.4s'
  },
  '@keyframes loadingDots': {
    '0%, 80%, 100%': {
      opacity: 0
    },
    '40%': {
      opacity: 1
    }
  }
}))
