export const ArrowLeftIcon = () => (
  <svg
    role="img"
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="64" height="64" rx="32" fill="#FAFBFF" />
    <path
      d="M40 32.5H24M32 24.5L24 32.5L32 40.5"
      stroke="#050922"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLeftIcon
