export const Liststatus = {
  energyVarejoStatus: [
    'Início',
    'Carta Denúncia',
    'Envio de formulário à distribuidora',
    'Assinatura de contrato',
    'Contratação de energia',
    'Adequação SMF',
    'Assinatura de CUSD',
    'Cadastro do ponto de medição',
    'Migração concluída'
  ],
  energyAtacadoStatus: [
    'Início',
    'Carta Denúncia',
    'Envio de formulário à distribuidora',
    'Assinatura de contrato',
    'Boleto e adesão CCEE',
    'Contratação de energia',
    'Abertura de conta',
    'Adequação SMF',
    'Assinatura do CUSD',
    'Cadastro do ponto de medição',
    'Migração concluída'
  ],
  solarStatus: [
    'Assinatura do termo de adesão',
    'Cadastro de unidades consumidoras',
    'Definição da usina geradora de energia',
    'Conexão com a usina geradora de energia',
    'Aguardando primeira fatura com desconto'
  ]
}
