import { LegendContainer } from 'pages/Reports/styles'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { theme } from 'shared/theme'

interface Props {
  text: string
  variant: 'square' | 'line-thin' | 'line-thick'
  color: string
  secondaryColor?: string
}

export const Legend = ({ text, variant, color, secondaryColor }: Props) => {
  const getBoxStyle = () => {
    switch (variant) {
      case 'line-thin':
        return {
          width: 12,
          height: `${theme.designSystem.size[1]}px`,
          background: color
        }
      case 'line-thick':
        return {
          width: 12,
          height: 3,
          background: secondaryColor
            ? `linear-gradient(to right, ${color} 50%, ${secondaryColor} 50%)`
            : color
        }
      case 'square':
      default:
        return {
          width: 12,
          height: 12,
          background: secondaryColor
            ? `linear-gradient(to right, ${color} 50%, ${secondaryColor} 50%)`
            : color
        }
    }
  }

  return (
    <LegendContainer>
      <Box
        sx={{
          ...getBoxStyle()
        }}
      />
      <Typography
        fontWeight={theme.designSystem.typography.fontWeight.medium}
        fontSize={theme.designSystem.typography.fontSize.caption}
        color={theme.designSystem.base[900]}
      >
        {text}
      </Typography>
    </LegendContainer>
  )
}
