type Props = {
  role?: string
  strokeColor?: string
}

export const IconMoney = ({ role, strokeColor }: Props) => {
  return (
    <svg
      role={role}
      width="68"
      height="56"
      viewBox="0 0 68 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.3333 21.3333H32.3333C30.4933 21.3333 29 22.8267 29 24.6667C29 26.5067 30.4933 28 32.3333 28H35.6667C37.5067 28 39 29.4933 39 31.3333C39 33.1733 37.5067 34.6667 35.6667 34.6667H30.6667M34 21.3333V18M34 38V34.6667"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
