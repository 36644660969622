import { Box } from 'shared/designSystem/components/Box'
import { Grid } from 'shared/designSystem/components/Grid'
import { Typography } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainContainer = styledSystem(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '79vh',
  width: 'auto'
}))

export const GridContainer = styledSystem(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  padding: theme.designSystem.size[25],
  borderStyle: 'solid',
  borderWidth: theme.designSystem.size[5],
  borderColor: theme.designSystem.base[100],

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr'
  }
}))

export const ColumnHeader = styledSystem(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const Column = styledSystem(Box)(({ theme }) => ({
  padding: theme.designSystem.size[10],
  borderRadius: theme.designSystem.size[2]
}))

export const ColumnTitle = styledSystem(Typography)(({ theme }) => ({
  marginLeft: theme.designSystem.size[18],
  marginBottom: theme.designSystem.size[32],
  fontSize: theme.designSystem.typography.fontSize.body2,
  color: theme.designSystem.base[400]
}))

export const CustomCounter = styledSystem(Typography)(({ theme }) => ({
  height: theme.designSystem.size[24],
  width: theme.designSystem.size[24],
  textAlign: 'center',
  marginRight: theme.designSystem.size[32],
  marginBottom: theme.designSystem.size[32],
  borderRadius: theme.designSystem.size[5],
  backgroundColor: theme.designSystem.base[100],
  fontSize: theme.designSystem.typography.fontSize.body2,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[400]
}))
