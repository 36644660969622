type Props = {
  role?: string
}

export const IconChat = ({ role }: Props) => {
  return (
    <svg
      role={role}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0835 1.25H7.91683C4.00516 1.25 0.833496 4.42167 0.833496 8.33333C0.833496 11.8183 3.35183 14.7083 6.66683 15.3V18.75L11.6668 15.4167H12.0835C15.9952 15.4167 19.1668 12.245 19.1668 8.33333C19.1668 4.42167 15.9952 1.25 12.0835 1.25Z"
        stroke="#050922"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
