import { Select as MuiSelect, SelectChangeEvent } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'

interface Props {
  role?: string
  label: string
  options: string[]
  onChange: (value: string) => void
  className?: string
}

export const DropDown = ({ role, label, options, onChange, className }: Props) => {
  const [value, setValue] = useState('')

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <Box role={role} className={className}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{value || label}</InputLabel>
        <MuiSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Box>
  )
}
