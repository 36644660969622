export default function IconInfo() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 7.33341V12.0001M8.00033 4.66675V4.66008M15.3337 8.00008C15.3337 12.0501 12.0503 15.3334 8.00033 15.3334C3.95033 15.3334 0.666992 12.0501 0.666992 8.00008C0.666992 3.95008 3.95033 0.666748 8.00033 0.666748C12.0503 0.666748 15.3337 3.95008 15.3337 8.00008Z"
        stroke="#050922"
        strokeOpacity={0.24}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  )
}
