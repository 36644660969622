import { useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { IconPdf } from 'shared/designSystem/Icons/IconPdf'
import { IconPrinter } from 'shared/designSystem/Icons/IconPrinter'
import { IconShare } from 'shared/designSystem/Icons/IconShare'
import { EmailShare } from './modalEmail/EmailShare'
import { ReportOptionButton } from './styles'

interface Props {
  handlePrint: () => void
  handleDownload: () => void
}

export const ReportOptions = ({ handlePrint, handleDownload }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const handeSendEmail = (data: any) => {
    console.log(data)
  }

  return (
    <>
      <Box display={'flex'}>
        <ReportOptionButton title="Download PDF" onClick={handleDownload}>
          <IconPdf />
        </ReportOptionButton>

        <ReportOptionButton title="Impressão" onClick={handlePrint}>
          <IconPrinter />
        </ReportOptionButton>

        <ReportOptionButton title="Compartilhar" onClick={() => setModalOpen(true)}>
          <IconShare />
        </ReportOptionButton>

        <EmailShare
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          handeSendEmail={handeSendEmail}
        />
      </Box>
    </>
  )
}
