import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconClose } from 'shared/designSystem/Icons/IconClose'
import { theme } from 'shared/theme'
import { ErrorButton, ErrorCardContainer, ErrorCircle } from './styles'

interface Props {
  message: string
  buttonVariant?: 'outlined' | 'contained'
  onRetry?: () => void
}

export const HomeError = ({ message, onRetry }: Props) => {
  return (
    <ErrorCardContainer height={'100%'} bgcolor={'white'} width={'100%'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={theme.designSystem.size[3]}
      >
        <ErrorCircle
          sx={{
            backgroundColor: theme.designSystem.deepOrange[500],
            width: theme.designSystem.size[64],
            height: theme.designSystem.size[64]
          }}
        >
          <IconClose color="white" width={17} height={17} />
        </ErrorCircle>
        <Typography
          sx={{
            fontSize: theme.designSystem.typography.fontSize.body1,
            fontWeight: theme.designSystem.typography.fontWeight.medium,
            color: theme.designSystem.base[900]
          }}
        >
          Falha ao carregar
        </Typography>
        <Box width={theme.designSystem.size[296]}>
          <Typography
            sx={{
              fontSize: theme.designSystem.typography.fontSize.body2,
              fontWeight: theme.designSystem.typography.fontWeight.regular,
              color: theme.designSystem.base[500]
            }}
          >
            {message}
          </Typography>
        </Box>
        <ErrorButton sx={{ backgroundColor: theme.designSystem.base[900] }} onClick={onRetry}>
          <Typography
            sx={{
              fontSize: theme.designSystem.typography.fontSize.button,
              fontWeight: theme.designSystem.typography.fontWeight.medium,
              color: theme.designSystem.base[100]
            }}
          >
            Tentar novamente
          </Typography>
        </ErrorButton>
      </Box>
    </ErrorCardContainer>
  )
}
