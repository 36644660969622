export const ErrorIcon = () => (
  <svg
    role="img"
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="64" height="64" rx="32" fill="#FF5722" />
    <path
      d="M24 24.5L40 40.5M24 40.5L40 24.5"
      stroke="#FAFBFF"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
