import { useEffect, useState } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { GraphTooltip } from 'shared/designSystem/components/GraphTooltip'
import { Typography } from 'shared/designSystem/components/Typography'
import { useConsumitionStore } from 'shared/store/consumition'
import { theme } from 'shared/theme'
import { formatCurrency } from 'shared/utils/formatCurrency'
import { ConsumeGraphBox } from '../ConsumeGraph/styles'
import { ErrorCard } from '../Error/ErrorCard'
import { Header } from './Header'
import { Legend } from './Legend'
import { EconomyDataPageProps } from 'pages/Home/models/EconomyData'

const EconomyGraph = ({ fetchDataEconomy, economy, error }: EconomyDataPageProps) => {
  const {
    state: { filteredConsumes, unit }
  } = useConsumitionStore()

  const [hoveredBar, setHoveredBar] = useState<null | number>(null)
  const [consumptionValue, setConsumptionValue] = useState(0)

  const getBarColor = (index: number): string => {
    const { primary, base } = theme.designSystem

    if (hoveredBar === null) {
      const defaultColors = [primary[500], primary[50], primary[200]]
      return defaultColors[index] || primary[200]
    }

    return hoveredBar === index ? primary[500] : base[200]
  }

  const totalCost = (economy?.totalGenialCost || 0) + (economy?.totalEconomyValue || 0)

  const getFlexValue = (cost: number) => (totalCost ? cost / totalCost : 0)

  useEffect(() => {
    if (!unit) return
    const selectedConsumption = filteredConsumes.find(
      (consumition: any) => consumition.unit[0].installationNumber === unit.installationNumber
    )
    setConsumptionValue(selectedConsumption?.value ?? 0)
  }, [unit, filteredConsumes])

  return (
    <>
      {error ? (
        <Box height={384} display={'flex'} justifyContent={'center'} bgcolor={'white'}>
          <ErrorCard message="de economia" onRetry={fetchDataEconomy} />
        </Box>
      ) : (
        <ConsumeGraphBox>
          <Header totalEconomy={consumptionValue ? consumptionValue : 0} />

          <Box mt={4} sx={{ height: '100%', alignContent: 'flex-end' }}>
            <Box width={200}>
              <Box display="flex" mb={1}>
                <GraphTooltip
                  title={
                    <>
                      <Typography
                        fontWeight={theme.designSystem.typography.fontWeight.regular}
                        fontSize={theme.designSystem.typography.fontSize.caption}
                        color={theme.designSystem.base[300]}
                      >
                        Custo na Genial
                      </Typography>
                      <Typography
                        fontWeight={theme.designSystem.typography.fontWeight.medium}
                        color={theme.designSystem.base[900]}
                        fontSize={theme.designSystem.typography.fontSize.button}
                      >
                        {formatCurrency(economy?.totalGenialCost!)}
                      </Typography>
                    </>
                  }
                  aria-modal
                  placement="top"
                >
                  <Box
                    flex={getFlexValue(economy?.totalGenialCost || 0)}
                    bgcolor={getBarColor(0)}
                    height={16}
                    onMouseEnter={() => setHoveredBar(0)}
                    onMouseLeave={() => setHoveredBar(null)}
                  />
                </GraphTooltip>

                <GraphTooltip
                  title={
                    <>
                      <Typography
                        fontWeight={theme.designSystem.typography.fontWeight.regular}
                        fontSize={theme.designSystem.typography.fontSize.caption}
                        color={theme.designSystem.base[300]}
                      >
                        Sua Economia
                      </Typography>
                      <Typography
                        fontWeight={theme.designSystem.typography.fontWeight.medium}
                        color={theme.designSystem.base[900]}
                        fontSize={theme.designSystem.typography.fontSize.button}
                      >
                        {formatCurrency(economy?.totalEconomyValue!)}
                      </Typography>
                    </>
                  }
                  aria-modal
                  placement="top"
                >
                  <Box
                    flex={getFlexValue(economy?.totalEconomyValue || 0)}
                    bgcolor={getBarColor(1)}
                    height={16}
                    onMouseEnter={() => setHoveredBar(1)}
                    onMouseLeave={() => setHoveredBar(null)}
                  />
                </GraphTooltip>
              </Box>

              <GraphTooltip
                title={
                  <>
                    <Typography
                      fontWeight={theme.designSystem.typography.fontWeight.regular}
                      fontSize={theme.designSystem.typography.fontSize.caption}
                      color={theme.designSystem.base[300]}
                    >
                      Mercado Cativo
                    </Typography>
                    <Typography
                      fontWeight={theme.designSystem.typography.fontWeight.medium}
                      fontSize={theme.designSystem.typography.fontSize.button}
                      color={theme.designSystem.base[900]}
                    >
                      {formatCurrency(economy?.totalTraditionalCost!)}
                    </Typography>
                  </>
                }
                aria-modal
                placement="top"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  onMouseEnter={() => setHoveredBar(2)}
                  onMouseLeave={() => setHoveredBar(null)}
                >
                  <Box flex={economy?.totalTraditionalCost || 0} bgcolor={getBarColor(2)} height={16} />
                </Box>
              </GraphTooltip>
            </Box>
          </Box>

          <Legend />
        </ConsumeGraphBox>
      )}
    </>
  )
}

export default EconomyGraph
