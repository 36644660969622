import { GridColDef } from '@mui/x-data-grid'
import { ReactNode } from 'react'
import { Box } from 'shared/designSystem/components/Box'
import { Header } from './components/Header'
import { Table } from './components/Table'
import {
  CostCenterReport,
  DataConsumer,
  DataEconomy,
  EconomyAlertReport,
  SustainabilityReport
} from './interface'

interface Props {
  children: ReactNode
  data:
    | DataConsumer[]
    | DataEconomy[]
    | CostCenterReport[]
    | EconomyAlertReport[]
    | SustainabilityReport[]
  page?: number
  numberOfPages?: number
  columns: GridColDef[]
}

export const PDFDocument = ({ children, data, page, columns, numberOfPages }: Props) => {
  return (
    <Box>
      <Header page={page || 1} numberOfPages={numberOfPages || 1} />
      {children}
      <Table data={data} columns={columns} />
    </Box>
  )
}
