import { Box, styled } from '@mui/material'

export const SideContainerStyled = styled(Box)(({ theme }) => ({
  component: 'aside',
  width: theme.designSystem.size[80],
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.designSystem.base[800],
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4)
}))
