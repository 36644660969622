type Props = {
  role?: string
  color?: string
  width?: number
  height?: number
}

export const IconCheck = ({ role, color, width, height }: Props) => {
  const defaultColor = color || '#FAFBFF'
  const defaultWidth = width || '12'
  const defaultHeight = height || '12'
  return (
    <svg
      role={role}
      width={defaultWidth}
      height={defaultHeight}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33301 12.6667L9.33301 18L22.6663 2"
        stroke={defaultColor}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
