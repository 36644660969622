import { Footer } from 'pages/Home/components/Footer'
import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Error } from 'shared/designSystem/components/Error'
import { Grid } from 'shared/designSystem/components/Grid'
import { Loading } from 'shared/designSystem/components/Loading'
import { User } from 'shared/models/User'
import { ErrorHandler } from 'shared/utils/ErrorHandler'
import { DefinedPasswordBox } from './components/DefinedPasswordBox'
import { FormBox } from './components/FormBox'
import { CpfBox } from './components/RegisterBoxes/CpfBox'
import { PasswordBox } from './components/RegisterBoxes/PasswordBox'
import { PhoneBox } from './components/RegisterBoxes/PhoneBox'
import { SmsBox } from './components/SmsBox'
import { TokenBox } from './components/TokenBox'
import { UserApi } from './services/User'
import { CustomProgress, FooterCustom, MainContainer } from './styles'

const FirstAccessPage = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const totalSteps = 6
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [searchParams] = useSearchParams()

  const [userData, setUserData] = useState<User>({
    email: searchParams.get('email') ?? '',
    accepted: false,
    cpf: '',
    phoneNumber: '',
    password: ''
  })

  const nextStep = async () => {
    setLoading(true)
    setError(null)

    try {
      setStep((prevStep) => prevStep + 1)
    } catch (err) {
      setError('Erro ao carregar o próximo passo')
    } finally {
      setLoading(false)
    }
  }

  const handleTokenVerification = () => {
    nextStep()
  }

  const handleAcceptTerms = (accepted: boolean) => {
    setUserData((prevState) => {
      const updatedUserData = { ...prevState, accepted }
      return updatedUserData
    })
    nextStep()
  }

  const handleCpfSubmission = (cpf: string) => {
    setUserData((prevState) => {
      const formattedCpf = cpf.replace(/\D/g, '')
      const updatedUserData = { ...prevState, cpf: formattedCpf }
      return updatedUserData
    })
    nextStep()
  }

  const handlePhoneSubmission = async (phoneNumber: string) => {
    setUserData((prevState) => {
      const formattedPhoneNumber = phoneNumber.replace(/\D/g, '')
      const updatedUserData = { ...prevState, phoneNumber: formattedPhoneNumber }
      return updatedUserData
    })

    nextStep()
  }

  const handlePasswordSubmission = async (password: string) => {
    setUserData((prevState) => {
      const updatedUserData = { ...prevState, password }

      submitUserData(updatedUserData)

      return updatedUserData
    })
  }

  const submitUserData = async (userData: User) => {
    try {
      await UserApi.submitFirstAccess(userData)

      nextStep()
    } catch (error) {
      ErrorHandler.getErrorMessage(error)
    }
  }

  useEffect(() => {
    const email = queryParams.get('email')

    UserApi.generateCode({ email: email! })
  }, [])

  const progressPercentage = Math.round((step / totalSteps) * 100)

  return (
    <MainContainer role={'main-container'}>
      <CustomProgress value={progressPercentage} role={'progress'} />

      {error && <Error />}
      {loading && <Loading />}

      {!loading && step === 0 && <TokenBox onSuccess={handleTokenVerification} email={userData.email} />}
      {!loading && step === 1 && <FormBox onAccept={handleAcceptTerms} />}
      {!loading && step === 2 && <CpfBox onCpfChange={handleCpfSubmission} />}
      {!loading && step === 3 && (
        <PhoneBox onPhoneChange={handlePhoneSubmission} email={userData.email} />
      )}
      {!loading && step === 4 && <SmsBox onSuccess={() => nextStep()} email={userData.email} />}
      {!loading && step === 5 && <PasswordBox onPasswordChange={handlePasswordSubmission} />}
      {!loading && step === 6 && <DefinedPasswordBox cpf={userData.cpf} password={userData.password} />}

      <FooterCustom>
        <Grid width={'100vw'} mt={0.5}>
          <Footer />
        </Grid>
      </FooterCustom>
    </MainContainer>
  )
}

export default FirstAccessPage
