import { Box } from 'shared/designSystem/components/Box'
import { DotsLoaderCustom } from './styles'

interface Props {
  color?: string
}

export const DotsLoader = ({ color }: Props) => (
  <DotsLoaderCustom color={color}>
    <Box />
    <Box />
    <Box />
  </DotsLoaderCustom>
)
