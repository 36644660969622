import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const MainContainer = styledSystem(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '97.8vh',
  width: 'auto',
  boxSizing: 'border-box'
}))

export const FooterCustom = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  height: 'auto',
  width: '98.5vw',
  marginBottom: theme.designSystem.size[2]
}))
