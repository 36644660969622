import { ProductBadgeContainer } from "./styles"

interface Props {
  name: string
  color?: string
}
export const ProductBadge = ({ name, color }: Props) => {
  return (
    <ProductBadgeContainer color={color}>
      {name}
    </ProductBadgeContainer>
  )
}
