import { CssBaseline, ThemeProvider } from '@mui/material'
import AppRoutes from 'routes'
import { theme } from 'shared/theme'

export const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AppRoutes />
  </ThemeProvider>
)
