import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const BoxContent = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  width: 248,
  gap: theme.spacing(2.5),
}))

export const BoxIcon = styledSystem(Box)(({ theme }) => ({
  height: '66px',
  width: '66px',
  background: '#8BC34A',
  padding: theme.spacing(2.6),
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
