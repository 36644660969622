import { LoadingCustom, RiveLoading } from './styles'

interface Props {
  role?: string
}

export const BoardLoading = ({ role }: Props) => {
  return (
    <LoadingCustom role={role}>
      <RiveLoading src="/g_loading.riv" role="rive-loading" />
    </LoadingCustom>
  )
}
