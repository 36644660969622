import { Box as BoxMui } from 'shared/designSystem/components/Box'
import { TextField } from 'shared/designSystem/components/TextField'
import { Typography as TypographyMui } from 'shared/designSystem/components/Typography'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const StyledInputBox = styledSystem(BoxMui)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  marginLeft: theme.designSystem.size[44]
}))

export const InputTitle = styledSystem(TypographyMui)(({ theme }) => ({
  fontSize: theme.designSystem.typography.fontSize.caption,
  fontWeight: theme.designSystem.typography.fontWeight.bold,
  color: theme.designSystem.base[900],
  marginTop: theme.designSystem.size[40]
}))

export const Input = styledSystem(TextField)(({ theme }) => ({
  width: theme.designSystem.size[432],
  '& .MuiInput-underline:before': {
    content: '""',
    backgroundImage: `linear-gradient(to right, ${theme.designSystem.base[900]}, ${theme.designSystem.secondary[500]})`,
    height: theme.designSystem.size[2]
  },
  '& .MuiInput-underline:after': {
    backgroundImage: `linear-gradient(to right, ${theme.designSystem.base[900]}, ${theme.designSystem.secondary[500]})`,
    height: theme.designSystem.size[2]
  }
}))
