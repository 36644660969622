import butter from 'shared/services/ButterClient'
import { Tutorial } from '../models/Tutorial'
import { Highlight } from '../models/WeekHighlight'

export class ButterApi {
  static async getTutorials(): Promise<Tutorial[]> {
    const response = (await butter.page.retrieve('*', 'tutorials')) as {
      data: { data: { fields: { title: string; description: string; image: string } } }
    }
    return [
      {
        title: response.data?.data?.fields.title,
        description: response.data?.data?.fields.description,
        image: response.data?.data?.fields.image
      }
    ]
  }

  static async getWeekHighlights(): Promise<Highlight[]> {
    const response = (await butter.page.retrieve('*', 'week-highlight')) as {
      data: { data: { fields: { title: string; image: string } } }
    }
    return [
      {
        title: response.data?.data?.fields.title,
        image: response.data?.data?.fields.image
      }
    ]
  }
}
