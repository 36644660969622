import { Box } from 'shared/designSystem/components/Box'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { theme } from 'shared/theme'

export const TooltipContainer = styledSystem(Box, {
  shouldForwardProp: (prop) => typeof prop === 'string' && !['x', 'y', 'visible'].includes(prop)
})<{ x: number; y: number; visible: boolean }>(({ x, y, visible }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  minWidth: theme.designSystem.size[256],
  transform: 'translateX(-50%)',
  background: theme.designSystem.white,
  border: `1px solid ${theme.designSystem.white}`,
  paddingInline: theme.designSystem.size[16],
  paddingBlock: theme.designSystem.size[12],
  pointerEvents: 'none',
  fontFamily: 'Arial, sans-serif',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'opacity 0.3s ease',
  opacity: visible ? 1 : 0,
  left: x,
  top: y
}))
