import { StyledTypography } from 'pages/Home/styles'
import { StyledButton } from 'pages/Reports/styles'
import { Box } from 'shared/designSystem/components/Box'
import { InfoTooltip } from 'shared/designSystem/components/InfoTooltip'
import { Typography } from 'shared/designSystem/components/Typography'
import { IconExpand } from 'shared/designSystem/Icons/IconExpand'
import IconInfo from 'shared/designSystem/Icons/IconInfo'
import { useTheme } from 'shared/hook/useTheme'
import { useReportStore } from 'shared/store/reports'

export type Props = {
  captiveCost: string
  freeCost: string
  savingsPerYear: string
}
export const Header = ({ captiveCost, freeCost, savingsPerYear }: Props) => {
  const {
    state: { isOpen },
    actions: { openModal }
  } = useReportStore()
  const theme = useTheme()
  const longText = `Atualizado conforme medições da distribuidora para todas as unidades`

  const renderTitle = (title: string, value: string) => (
    <Box>
      <StyledTypography>{title}</StyledTypography>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography
          fontSize={theme.designSystem.typography.fontSize.h3}
          fontWeight={theme.designSystem.typography.fontWeight.medium}
          color={theme.designSystem.base[900]}
        >
          {value}
        </Typography>
        <InfoTooltip title={longText}>
          <Box display="inline-flex">
            <IconInfo />
          </Box>
        </InfoTooltip>
      </Box>
    </Box>
  )

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
      <Box display="flex" gap={8}>
        {!isOpen && renderTitle('Economia acumulada no ano', savingsPerYear)}

        {isOpen && (
          <>
            {renderTitle('Custo médio cativo', captiveCost)}
            {renderTitle('Custo médio livre', freeCost)}
            {renderTitle('Economia acumulada no ano', savingsPerYear)}
          </>
        )}
      </Box>

      {!isOpen && (
        <StyledButton variant="outlined" size="small" onClick={() => openModal(2, 'Economia', true)}>
          <IconExpand />
        </StyledButton>
      )}
    </Box>
  )
}
