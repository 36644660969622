import { styledSystem } from 'shared/designSystem/styles/StyledSystem'
import { Box } from 'shared/designSystem/components/Box'

interface Props {
  color?: string
}

export const PulseStyled = styledSystem(Box)<Props>(({ color }) => ({
  position: 'relative',
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: color || '#FAFBFF7A',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 12,
    height: 12,
    borderRadius: '50%',
    border: `3px solid ${color || '#FAFBFF7A'}`,
    animation: 'pulse 1.5s infinite ease-in-out'
  },
  '@keyframes pulse': {
    '0%': { transform: 'translate(-50%, -50%) scale(1)', opacity: 1 },
    '100%': { transform: 'translate(-50%, -50%) scale(2)', opacity: 0 }
  }
}))
