import { FooterContainer, FooterTypography } from './styles'

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <FooterContainer>
      <FooterTypography>© {currentYear} Genial Energia</FooterTypography>
    </FooterContainer>
  )
}
