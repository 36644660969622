import { Button as ButtonMui } from 'shared/designSystem/components/Button'
import { styledSystem } from 'shared/designSystem/styles/StyledSystem'

export const Button = styledSystem(ButtonMui, {
  shouldForwardProp: (prop) => prop !== 'isModalVariant'
})<{ isModalVariant: boolean }>(({ theme, isModalVariant }) => ({
  variant: 'contained',
  height: theme.designSystem.size[48],
  backgroundColor: `${isModalVariant ? theme.designSystem.base[900] : theme.designSystem.base[50]}`,
  border: 'solid 1px',
  borderColor: theme.designSystem.base[900],
  borderRadius: theme.designSystem.size[24],
  fontSize: theme.designSystem.typography.fontSize['button'],
  display: 'flex',
  marginLeft: theme.designSystem.size[44],
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  minWidth: theme.designSystem.size[184],
  paddingInline: theme.designSystem.size[6]
}))
