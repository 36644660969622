import { Box } from "shared/designSystem/components/Box";
import { styledSystem } from "shared/designSystem/styles/StyledSystem";

export const PaymentContainerBox = styledSystem(Box)(({ theme }) => ({
  backgroundColor: theme.designSystem.white,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  paddingTop: theme.designSystem.size[24],
  paddingBottom: theme.designSystem.size[24],
  gap: theme.designSystem.size[40]
}))

export const NoDataBox = styledSystem(Box)(({ theme }) => ({
  margin: 'auto',
  width: '95%',
  height: theme.designSystem.size[250],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: `1px solid ${theme.designSystem.base[200]}`,
  padding: theme.designSystem.size[16],
}))

export const CardContainerBox = styledSystem(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  height: theme.designSystem.size[250],
  marginLeft: theme.designSystem.size[16]
}))

export const CardBox = styledSystem(Box, {
  shouldForwardProp: (prop) => prop !== 'cardDataLength' && prop !== 'currentIndex' && prop !== 'cardWidth',
})<{
  cardDataLength: number;
  currentIndex: number;
  cardWidth: number;
}>(({ cardDataLength, currentIndex, cardWidth, theme }) => ({
  display: 'flex',
  transition: 'transform 0.3s ease-in-out',
  transform: `translateX(-${currentIndex * (cardWidth + 16)}px)`,
  gap: theme.designSystem.size[16],
  width: `${cardDataLength * (cardWidth + 16)}px`,
}));

export const ButtonsContainerBox = styledSystem(Box)(({ theme }) => ({
  marginRight: theme.designSystem.size[24],
  marginLeft: theme.designSystem.size[24],
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.designSystem.size[8]
}))
