import { LoginApi, TOKEN_KEY } from 'pages/SignIn/services/LoginApi'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertSnackbar } from 'shared/designSystem/components/AlertSnackbar'
import { Box } from 'shared/designSystem/components/Box'
import { Typography } from 'shared/designSystem/components/Typography'
import { useTheme } from 'shared/hook/useTheme'
import { useAuthStore } from 'shared/store/auth'
import { getErrorMessage } from 'shared/utils/errorMessages'
import { DotsLoader } from '../../../../shared/designSystem/components/DotsLoader'
import { ButtonUnderline, DividerContainer, TextFieldInput } from '../../styles'
import { PasswordRecoveryButton } from '../PasswordRecoveryButton'
import { RecoveryTitle } from '../PasswordRecoveryTitle'
import { BoxChildren, BoxCircleTimer, BoxParent, BoxResendCode } from '../styles'

type Props = {
  handleClose: () => void
  cpf: string
  password: string
}

export const SecurityValidation = ({ handleClose, cpf, password }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { actions } = useAuthStore()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [securityCode, setSecurityCode] = useState('')
  const [rawSecurityCode, setRawSecurityCode] = useState('')
  const [securityCodeError, setSecurityCodeError] = useState('')
  const [timer, setTimer] = useState<number>(0)
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false)
  const [submitErrorSnackbarOpen, setSubmitErrorSnackbarOpen] = useState(false)

  const handleSecurityCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawCode = e.target.value.replace(/\D/g, '')
    const formattedCode = rawCode.replace(/(\d{3})(\d+)/, '$1 $2')
    setSecurityCode(formattedCode)
    setRawSecurityCode(rawCode)
  }

  const handleResendCode = async () => {
    setLoading(true)
    setError('')
    setSecurityCodeError('')

    try {
      const payload = { cpf, password }
      await LoginApi.sendCode(payload)
      setTimer(60)
      setAlertOpen(true)
    } catch (error) {
      handleResendError(error)
    } finally {
      setLoading(false)
    }
  }

  const validateSecurityCode = () => {
    if (!rawSecurityCode) return 'Este campo é obrigatório'
    if (rawSecurityCode.length !== 6) return 'Código deve ter 6 números'
    return ''
  }

  const handleResendError = (error: unknown) => {
    if (error instanceof Error) {
      setErrorSnackbarOpen(true)
    }
  }

  const handleApiError = (error: any) => {
    const apiErrorMessage = error.response?.data?.message

    if (apiErrorMessage === 'Invalid Token.' || apiErrorMessage === 'Expired Token.') {
      setSecurityCodeError(getErrorMessage(apiErrorMessage))
      return true
    }

    setSubmitErrorSnackbarOpen(true)
    return false
  }

  const handleSubmit = async () => {
    setLoading(true)
    setSecurityCodeError('')

    const validationError = validateSecurityCode()
    if (validationError) {
      setSecurityCodeError(validationError)
      setLoading(false)
      return
    }

    try {
      const payload = { cpf, code: rawSecurityCode }
      const response = await LoginApi.codeVerify(payload)
      sessionStorage.setItem(TOKEN_KEY, response.data.data.accessToken)
      actions.signIn()
      navigate('/home')
    } catch (error: any) {
      const errorHandled = handleApiError(error)
      if (errorHandled) return
    } finally {
      setLoading(false)
    }
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [timer])

  return (
    <Box height="100vh" mt={20}>
      <BoxParent>
        <RecoveryTitle text="Validação de segurança" handleClose={handleClose} />
        <DividerContainer />

        <BoxChildren>
          <Typography
            fontWeight={theme.designSystem.typography.fontWeight.regular}
            fontSize={theme.designSystem.typography.fontSize.body2}
            color={theme.designSystem.base[500]}
          >
            Para continuar, informe o código de segurança que foi enviado para o e-mail de cadastro.
          </Typography>

          <Box>
            <TextFieldInput
              variant="standard"
              label="Código de segurança"
              fullWidth
              value={securityCode}
              onChange={handleSecurityCodeChange}
              error={!!securityCodeError}
              slotProps={{
                htmlInput: {
                  minLength: 6,
                  maxLength: 7
                }
              }}
            />
            {securityCodeError && (
              <Typography color={theme.designSystem.deepOrange[500]} variant="caption">
                {securityCodeError}
              </Typography>
            )}
          </Box>

          <BoxResendCode>
            <ButtonUnderline disabled={timer > 0} onClick={handleResendCode} sx={{ mr: 3 }}>
              Reenviar código
            </ButtonUnderline>

            <BoxCircleTimer display={timer > 0 ? 'flex' : 'none'}>
              <Typography color={theme.designSystem.base[400]}>{timer > 0 ? timer : ''}</Typography>
            </BoxCircleTimer>
          </BoxResendCode>

          <PasswordRecoveryButton onClick={handleSubmit} role="button">
            {loading ? (
              <DotsLoader />
            ) : error || alertOpen ? (
              'Tentar novamente'.toUpperCase()
            ) : (
              'Confirmar'.toUpperCase()
            )}
          </PasswordRecoveryButton>
        </BoxChildren>
      </BoxParent>

      <AlertSnackbar
        type="success"
        open={alertOpen}
        onClose={handleAlertClose}
        title="Código enviado"
        message="Verifique o celular cadastrado"
      />
      <AlertSnackbar
        type="error"
        open={errorSnackbarOpen}
        onClose={() => setErrorSnackbarOpen(false)}
        title="Falha ao reenviar código"
        message="Tente novamente"
      />
      <AlertSnackbar
        type="error"
        open={submitErrorSnackbarOpen}
        onClose={() => setSubmitErrorSnackbarOpen(false)}
        title="Erro ao continuar"
        message="Tente novamente"
      />
    </Box>
  )
}
